
@import "variables.less";
@import "bootstrap.less";
.split-css-rule{display:none;color:#123456}
@import "../../common/xvxn_common";
.split-css-rule{display:none;color:#123456}
@import "form.less";
@import "elements.less";
@import "page.less";
.split-css-rule{display:none;color:#123456}
@import "modules.less";
@import "responsive.less";
