
.mobile-show {
	display: block;
}
@media @media-ratio-vertical {
	.mobile-portrait-show {
		display: block;
	}
	.mobile-portrait-hide {
		display: none;
	}
}
@media @media-ratio-horizontal {
	.mobile-landscape-show {
		display: block;
	}
	.mobile-landscape-hide {
		display: none;
	}
}
.mobile-show-inline {
	display: inline;
}
.mobile-show-inline-block {
	display: inline-block;
}
.mobile-show-list-item {
	display: list-item;
}
.mobile-hide,
.mobile-hide-inline {
	display: none;
}

.thumb-block {
	#video-playlist #playlist-content .mozaique & {
		width: 25%;
	}
	
	&.thumb-cat {
		.qtt {
			font-size: 12px;
			height: 16px;
			line-height: 16px;
			padding: 0 4px;
			
			& + .title {
				max-width: 60%;
			}
		}
		
		.title {
			font-size: 13px;
			height: 20px;
			line-height: 21px;
			padding-right: 6px;
			
			.icon-f {
				margin-right: 1px;
			}
		}
	}
}

.pagination {
	ul {
		li a {
			line-height: 1.5;
			font-size: 18px;
			padding: 0 8px;
		}
	}
	
	&.centered {
		text-align: left;
		
		ul {
			display: block;
		}
	}
}

.copy-link {
	.copy-btn {
		width: 25%;
	}
	
	input {
		width: 75%;
	}
}