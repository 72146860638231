
.x-popup {
	position: absolute;
	z-index: @zindex-popup;
	padding: @tooltip-arrow-width 0;
	.x-popup-content {
		background: @tooltip-bg;
		padding: 15px 20px;
		overflow-x: hidden;
		overflow-y: auto;
		zoom: 1;
		a {
			color: #fff;
		}
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				padding: 10px;
				&:hover {
					background: @blue-theme-darker;
				}
				a {
					text-decoration: none;
					display:block;
				}
			}
		}
		.popup-confirm {
			padding: 10px;
			form {
				margin: 10px 0;
				line-height: 1;
				label {
					margin-bottom: 0;
					input[type="checkbox"] {
						margin-right: 5px;
					}
				}
			}
			.btn {
				& ~ .btn {
					margin-left: 5px;
				}
			}
		}
	}
	.x-popup-arrow {
		position: absolute;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
	}
	.x-popup-close {
		cursor: pointer;
		position: absolute;
		color: @tooltip-color;
		font-size: 13px;
		right: 5px;
		top: 8px;
		span span {
			display: none;
		}
	}
	&.above {
		margin-bottom: -@tooltip-arrow-width;
		.x-popup-arrow {
			bottom: 0;
			left: 10px;
			margin-left: -@tooltip-arrow-width;
			border-width: @tooltip-arrow-width @tooltip-arrow-width 0;
			border-top-color: @tooltip-bg;
		}
		&.right .x-popup-arrow {
			left: auto;
			right: 10px;
		}
	}
	&.below {
		margin-top: -@tooltip-arrow-width;
		.x-popup-arrow {
			top: 0;
			left: 10px;
			margin-left: -@tooltip-arrow-width;
			border-width: 0 @tooltip-arrow-width @tooltip-arrow-width;
			border-bottom-color: @tooltip-bg;
		}
		&.right .x-popup-arrow {
			left: auto;
			right: 10px;
		}
	}
	&.no-arrow {
		.x-popup-arrow {
			display: none;
		}
	}
}