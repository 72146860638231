/* ==== TYPE ==== */

::selection {
    background: #fff;
    color: @blue-theme;
    text-shadow: none;
}

a {
	//text-decoration: underline;
	.transition;
}

.text-highlight {
	color: @yellow-theme;
}

.btn {
	.transition;
}

.btn-primary {
	a& {
		text-decoration: none;
	}
}

.t-table {
	display: table;
}
.t-row {
	display: table-row;
}
.t-cell {
	display: table-cell;
	vertical-align: middle;
}