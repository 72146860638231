@import 'form/purchase_multiform.less';


.signin-popup-form.x-overlay {
	.x-body {
		background: @blue-theme-darker;
		max-width: 800px;
		
		#signup-form-have-account{
			margin-bottom: 15px;
			text-align: center;
		}
		.btn{
			white-space: normal;
		}
		
		form,
		legend {
			color: @text-color;
		}
		legend{
			font-size: 22px;
		}
		.text-danger{
			color: @theme-color;
		}
		.form-control {
			padding: 5px;
			background: @blue-theme-light;
			color: @text-color;
			border: 1px solid @text-color;
			height: auto;
			&.visible-password {
				height: auto;
				padding: 0;
				position: relative;
				button {
					background: @blue-theme-light;
					border: 0;
					border-radius: 0;
					bottom: 0;
					color: lighten(@blue-theme-light,40%);
					float: right;
					margin: 0;
					padding: 0;
					position: absolute;
					right: 0;
					top: 0;
					width: 40px;
					&:focus,
					&:active {
						outline: none;
					}
					.icon-f {
						font-size: 16px;
						vertical-align: middle;
					}
					.icf-eye-blocked {
						display: none;
					}
				}
				&.visible {
					button {
						.icf-eye-blocked {
							display: inline;
						}
						.icf-eye {
							display: none;
						}
					}
				}
				input {
					border: 0;
					height: 30px;
					margin: 0;
					padding-right: 40px;
					width: 100%;
				}
				.progress-pwd-check{
					height: 4px;
					margin: 0 -1px -4px;
				}
			}
		}
		.form-popup-error .x-popup-content {
			padding: 4px 12px;
		}
		.btn-danger {
			.button-variant(@text-color, @blue-theme, @blue-theme-light);
		}
		
		.social-login-tos-accept {
			a {
				text-decoration: underline;
				
				&:hover {
					text-decoration: none;
				}
			}
		}
		
		.social-login-mobile-block {
			margin-bottom: 20px;
		}
	}
}


#signup-form-block #signup-form-iframe ,
#signin-popup-form #signup-form-iframe {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 1px;
	height: 1px;
	opacity: 0;
	border: 0;
}

#content #signin-popup-form {
	margin: 20px 0 40px;
}