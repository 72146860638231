body{
	position: relative;
	&.header-mobile-search-opened{
		overflow: hidden;
		position: fixed;
		.height-100vh();
	}
}

@media @media-ratio-horizontal {
	body{
		&.header-mobile-search-opened{
			top: -61px;
			
			&.no-btn-search-close{
				top: 0;
			}
		}
	}
	#header {
		padding-top: 0;
		.topbar {
			position: static;
		}
	}
}

#header {
	padding-top: @mobile-header-height;
	position: relative;
	
	.topbar {
		//background: url('../../../v3/img/skins/xnxx/top-stripe.png') fade(@blue-theme-dark, 90%);
		background-size: auto 63px;
		height: @mobile-header-height;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
		width: 100%;
		z-index: @zindex-mobile-header;
		.logo-xnxx {
			float: none;
			text-align: center;
			img {
				height: 35px;
			}
			.gold-plate {
				display: none;
			}
		}
		.slogan {
			text-align: center;
			font-size: 11px;
		}
	}
	
	#site-nav {
		background: fade(@blue-theme-dark, 90%);
		display: flex;
		font-size: 16px;
		padding-left: 20px;
		text-align: left;
		
		& > * {
			flex-grow: 1;
			white-space: nowrap;
		}
		
		.header-icons {
			//flex-grow: 999;
			height: @mobile-nav-height;
			order: 99;
			text-align: right;
			
			.icon-sitenav-bg {
				margin-top: 10px;
			}
			
			.btn {
				&, .icon-f {
					height: 46px;
					line-height: 46px;
				}
			}
		}
		/*.country-switch,
		#language-switcher-mobile,*/
		#main-cat-switcher-mobile,
		.header-mobile-link {
			line-height: @mobile-nav-height;
			text-decoration: none;
			text-align: center;
			span{
				text-transform: uppercase;
				vertical-align: middle;
			}
		}
		//.country-switch,
		#main-cat-switcher-mobile {
			margin-left: 6px;
			padding-left: 10px;
		}
		#main-cat-switcher-mobile {
			.icf-woman {
				font-size: 26px;
			}
			.icf-sexe-trans {
				font-size: 20px;
			}
		}
		.header-mobile-link{
			height: @mobile-nav-height;
			overflow: hidden;
			white-space: normal;
			margin-left: 6px;
			padding: 0 10px;
			
			.label {
				white-space: nowrap;
			}
			.icon-f {
				font-size: 24px;
				padding-right: 4px;
			}
		}
		#header-mobile-live-cam {
			.icon-f {
				font-size: 28px;
				padding-right: 0;
			}
		}
		#header-mobile-app-dl {
			.icf-device-mobile {
				font-size: 20px;
				padding: 0 4px 0 6px;
			}
		}
		#header-mobile-gold {
			flex-grow: 0;
			flex-shrink: 0;
			display: inline-block;
			line-height: @mobile-nav-height - 2px;//2px du border bottom dans gold_gradient
			padding: 0 10px;
			text-decoration: none;
			vertical-align: top;
		}
		.header-icons + #header-mobile-gold {
			margin-left: -20px;
		}
	}
}

#header-mobile-menu-toggle {
	float: left;
}
#header-mobile-search-toggle {
	float: right;
	.icon.search{
		margin: 0;
	}
}


#mobile-categories {
	clear: both;
	float: none;
	font-size: 18px;
	width: auto;
	#mobile-cat-order-select {
		margin-bottom: 10px;
		li {
			a {
				font-size: 20px;
				padding: 0 12px 0 4px;
				&.current_order {
					color: @yellow-theme
				}
				span{
					width: 14px;
				}
			}
		}
	}
	#mobile-cat-history-legend{
		float: right;
	}
	#mobile-cat-list{
		clear: both;
		margin: 0;
		max-height: 380px;
		overflow: auto;
		overflow-x: hidden;
		overflow-y: scroll;
		text-align: left;
		li{
			display: inline-block;
			margin: 0 10px 8px 0;
			max-width: 100%;
			a {
				background: @blue-theme-light;
				max-width: 100%;
				padding: 3px 6px;
				text-decoration: none;
				span {
					margin-top: 2px;
					text-align: left;
				}
				.icon-f,
				.icf-suggest-square,
				.icf-images {
					font-size: 14px;
					margin: 3px 4px 0 0;
				}
				.gold-plate {
					&:last-child {
						margin-right: -6px;
					}
				}
				.icf-home {
					font-size: 17px;
					margin: 1px 4px 0 0;
				}
			}
		}
	}
	.cat-history-legend {
		div {
			left: auto;
			right: 0;
			box-shadow: -1px 1px 3px 2px rgba(0,0,0,0.5);
			&:after {
				left: auto;
				right: 4px;
			}
		}
	}
}

#xnxx-search-bar {
	background: fade(@blue-theme-darker, 90%);
	float: none;
	margin: 0;
	padding: @content-padding;
	text-align: center;
	.form-group {
		float: left;
		margin-right: 4px;
		margin-bottom: @content-padding;
		text-align: left;
		width: 50%;
		width: calc(~"100% - 140px");
	}
	.search-input {
		font-size: 18px;
		height: 28px;
		width: 100%;
	}
	.btn-primary{
		float: left;
		height: 28px;
		line-height: 28px;
		overflow: hidden;
		padding: 0 5px;
		width: 90px;
	}
	#xnxx-search-bar-close{
		border: 0;
		float: right;
		font-size: 14px;
		height: 30px;
		line-height: 30px;
		padding: 0;
		text-align: center;
		width: 40px;
	}
}

.search-autocomplete {
	z-index: @zindex-autocomplete;
	.title {
		padding: 5px 10px;
		font-size: 16px;
	}
	li {
		padding: 2px 10px;
		font-size: 14px;
		a{
			padding-bottom: 8px;
			padding-top: 8px;
		}
	}
}

#content {
	#content-thumbs {
		.infobar {
			align-items: center;
			display: flex;
			flex-wrap: wrap;
			gap: 10px;
			justify-content: space-evenly;
			padding-right: 2px;
			
			.infobar-list {
				display: none;
			}
			
			.infobar-mobile-line {
				align-items: center;
				display: flex;
				float: none;
				gap: 10px;
				
				.infobar-title {
					padding-right: 0;
					
					& + .simple-dropdown {
						margin-top: 0;
						flex-shrink: 0;
					}
				}
			}
			
			.infobar-jumper {
				display: block;
				float: right;
				margin: -5px 0;
				
				select.jumper {
					padding: 3px 5px;
					width: auto;
				}
			}
		}
	}
}

#multi-footer-link{
	font-size: 20px;
}