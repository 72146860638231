@import "../../../common/premium_feed";
@import "../../../common/sub_suggestions/suggestList";

#page.premium_feed {
	#feed-videos-filters {
		padding: 0 7px;
		
		.simple-dropdown {
			margin-top: 0;
		}
	}

	#main {
		#user-videos-page-videos #feed-videos-filters {
			p {
				margin: 0 0 17px 0;
			}
		}
		&.with-active-suggests {
			.suggest-list-header {
				margin: 15px 0 12px 0;
			}
			#feed-videos {
				& ~ .suggest-list-block {
					.suggest-list-header {
						margin-top: 0;
					}
				}
			}
			#user-videos-page-videos #feed-videos-filters {
				padding-top: 0;
				p {
					margin: 0 0 27px 4px;
				}
			}
		}
		
		.videos-by-profile {
			.activity-event {
				&:first-child {
					&, .clear-infobar {
						margin-top: 0;
					}
				}
			}
		}
		
		.profile-video-list-block {
			margin-left: 14px;
			margin-right: 14px;
			
			.activity-event {
				max-width: 100%;
				
				&, .clear-infobar {
					margin-top: 0;
				}
			}
			
			.clear-infobar {
				margin: 0 -14px;
				
				a {
					color: @gold-theme;
					font-weight: bold;
					text-decoration: underline;
					
					&:hover {
						text-decoration: none;
					}
				}
				
				.icon-f {
					color: @gold-theme;
					font-size: inherit;
					line-height: 1;
					padding-right: 4px;
					vertical-align: baseline;
				}
			}
			
			.mozaique {
				.thumb-block {
					float: none;
				}
			}
		}
	}
	
	.suggest-list {
		background: @blue-theme-darker;
		background-image: linear-gradient(0deg, @blue-theme 0%,  @blue-theme-darker 20%,  @blue-theme-darker 80%, @blue-theme 100%);
		padding: 14px 14px 0;
		
		.thumb-block-premium-feed-account {
			.thumb-under {
				margin-bottom: 0;
				min-height: 0;
			}
		}
		.suggest-list-error {
			color: #fff;
			letter-spacing: 0;
			padding-bottom: 24px;
		}
	}
	.simple-dropdown {
		.library {
			.library-btn {
				&.active {
					background-color: @premium-feed-library-filter-btn-active-bg;
					color: @premium-feed-library-filter-btn-active-color;
					outline: 2px solid @premium-feed-library-filter-btn-active-outline;
					outline-offset: -2px;
				}
			}
		}
		
		.membership-dropdown.show-niv2 {
			align-items: center;
			background-color: @premium-feed-membership-filter-btn-bg;
			border: 0;
			border-radius: 3px;
			color: @premium-feed-membership-filter-btn-color;
			cursor: pointer;
			display: flex;
			float: left;
			font-weight: bold;
			line-height: 11px;
			margin: 0 4px 0 0;
			padding: 0;
			text-decoration: none;
			text-align: center;
			white-space: nowrap;
			
			.membership-profile-picture {
				display: flex;
				background-color: #000;
				height: 32px;
				align-items: center;
				border-radius: 3px 0 0 3px;
				border: 1px solid @premium-feed-membership-filter-btn-bg;
				
				.membership-letter {
					padding: 0 10px;
				}
				
				img {
					border-radius: 3px 0 0 3px;
					height: 32px;
					margin-left: -1px;
					object-fit: cover;
					width: auto;
				}
			}
			
			.membership-title {
				display: flex;
				flex-direction: column;
				line-height: 11px;
				margin: 5px 0 5px 6px;
				overflow: hidden;
				width: stretch;
				
				.membership-name {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
			
			small {
				font-size: 70%;
				font-weight: initial;
				line-height: 8px;
			}
			
			&.active {
				background-color: @premium-feed-membership-filter-btn-active-bg;
				color: @premium-feed-membership-filter-btn-active-color;
				outline: 2px solid @premium-feed-membership-filter-btn-active-outline;
				outline-offset: -2px;
				
				.membership-profile-picture {
					border: 0;
				}
				
				img {
					margin-left: 0;
				}
			}
			
			.icon-f.icf-caret-down {
				margin-right: 10px;
			}
		}
	}
}

#page.premium_feed .suggest-list .thumb-block-premium-feed-account .thumb-under,
.suggest-account-preview-pop .account-preview-header {
	.account-actions {
		.btn {
			background: transparent;
			border: 0;
			color: #fff;
			float: left;
			font-weight: bold;
			line-height: 30px;
			padding: 0;
			width: 45%;
			
			&:hover, &:active {
				background: rgba(255, 255, 255, 0.2);
			}
			
			&.sub {
				margin-left: 5%;
				
				.icon-f {
					opacity: 0.8;
				}
			}
			&.reject {
				margin-left: 0;
			}
		}
	}
}

.suggest-account-preview-pop {
	.account-preview-header {
		h2 {
			.label {
				.gold-plate,
				.free-plate {
					font-size: 13px;
					padding-left: 4px;
					padding-right: 4px;
					vertical-align: bottom;
				}
			}
		}
		.page-link {
			.button-variant(#fff, @blue-theme-light, @blue-theme-light);
			font-weight: bold;
		}
	}
}

@media (max-width: @screen-xs-max) {
	.suggest-account-preview-pop {
		.account-preview-header {
			.page-link {
				margin-right: 15px;
			}
		}
	}

	#main {
		&.with-active-suggests {
			.suggest-list-header {
				margin: 20px 0 15px 0;
			}
		}
		#user-videos-page-videos #feed-videos-filters {
			padding-top: 0;
			p {
				margin: 0 0 25px 0;
			}
		}
	}
}

#content-thumbs {
	.page-title.channel {
		margin: 30px 0 10px;
		text-align: center;
		line-height: 1.5;
		
		img {
			width: auto;
			height: 36px;
			vertical-align: text-bottom;
		}
		
		.gold-plate.big-plate {
			font-size: 25px;
			vertical-align: text-bottom;
		}
	}
	
	.tour-pushs {
		display: grid;
		grid-template-columns: repeat(5, minmax(0, 1fr));
		justify-content: space-around;
		align-items: start;
		margin: 0 -40px;
		padding: 0 40px;
		text-align: center;
		font-size: 0;
		background-image: linear-gradient(0deg, @premium-feed-account-profile-name-bg 0%, transparent 100%);
		.inter {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			width: 100%;
			max-width: 330px;
			padding: 10px;
			text-align: center;
			img {
				max-width: 100px;
			}
			p {
				font-size: 14px;
			}
		}
		.cta {
			grid-column-start: 1;
  			grid-column-end: 6;
			.btn {
				margin-bottom: 15px;
				padding: 10px 12px;
				text-transform: capitalize;
				white-space: normal;
			}
		}
	}
	
	//valentine-23 offer in xnxx/marketing/offers/valentine-23.less
	
	.thumb-block-premium-feed-account {
		.thumb a {
			border: 0;
		}
		
		.profile-name {
			overflow: visible;
		}
		.gold-plate {
			padding: 3px 6px 2px 6px;
			font-size: 14px;
			transform: translateY(10%);
		}
	}
}

#content {
}