.navbadge {
	display: inline-block;
	min-width: 10px;
	padding: 3px 4px;
	font-size: 10px;
	font-weight: 700;
	color: #fff;
	line-height: 1;
	vertical-align: middle;
	white-space: nowrap;
	text-align: center;
	background: @blue-theme-darker;
	border-radius: 8px;
}

html {
	font-size: 1rem;
}

@import "elements/nav.less";
@import "elements/type.less";
@import "elements/lists.less";
@import "elements/pagination.less";
@import "elements/popup.less";
@import "../../common/x-messages.less";
@import "elements/messages.less";
@import "elements/messages-bottom.less";
@import "elements/overlay.less";
@import "elements/forms.less";
@import "elements/thumb-ad-gold.less";
@import "elements/icons.less";
@import "elements/iconfont.less";
@import "../../common/x-messages.less";
@import "../../common/emoji";
@import "../../common/threads";
@import "elements/threads";
@import "../../common/scrollbar";

@import "../../common/download_app";
@import "elements/download_app";
@import "elements/banner_sheer";

#x-x-messages-btn {
	background: @x-messages-bg;
	border: 1px solid @x-messages-border;
	height: 33px;
	
	.ic {
		color: @x-messages-color;
	}
}
#x-messages-menu {
	background: @x-messages-bg;
	border: 1px solid @x-messages-border;
	border-right: 0;
	height: 33px;
	
	.btn,
	button {
		background: fade(@x-messages-color, 20%);
		color: @x-messages-color;
		
		&.opened {
			background: @x-messages-color;
			color: @x-messages-bg;
		}
	}
}

.transition {
	transition: all .1s ease;	
}

#x-fda-messages,
#x-home-messages {
	margin: 8px;
}

.highlight {
	color: @yellow-theme;
}