@ratio-txt: 0.8;

.thumb-block.thumb-ad-gold {
	margin-bottom: 8px;
	.thumb {
		a {
			height: 100%;
			background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #FEDB37 0%, #FDB931 8%, #9f7928 25%, #5d4a1f 62.5%, #5d4a1f 100%);
			
			&:hover {
				color: white;
			}
		}
	}
	.thumb-ad-content {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		height: auto;
		font-weight: bold;
		text-shadow: 0 0 5px rgba(0,0,0,0.5);
		transform : translateY(-50%);
		p {
			margin: 0;
			line-height: 1.3;
		}
		.thumb-ad-logo {
			margin-bottom: 10px;
			line-height: 1;
		}
		.nb-uploads {
			margin-top: 5px;
		}
	}
	
	&.v1 {
		.thumb-ad-content {
			.thumb-ad-logo {
				color: #ffda00;
			}
		}
	}
	&.v2 {
		.thumb-ad-content {
			.thumb-ad-logo {
				color: #ffda00;
			}
			.slogan,
			.nb-uploads {
				color: black;
				text-shadow: 1px 1px 0 rgba(255,255,255,0.2), -1px -1px 0 rgba(255,255,255,.2), 1px -1px 0 rgba(255,255,255,.2), -1px 1px 0 rgba(255,255,255,.2);
			}
		}
	}
}

@media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
	.thumb-block.thumb-ad-gold,
	.nb-thumbs-cols-sm-3 .mozaique.cust-nb-cols .thumb-block.thumb-ad-gold {
		.thumb-ad-content {
			.thumb-ad-logo {
				font-size: 4vw;
			}
			.slogan {
				padding: 0 1vw;
				font-size: 1.9vw;
			}
			.nb-uploads {
				padding: 0 1vw;
				font-size: 2vw * 0.9;
			}
		}
	}
	
	.nb-thumbs-cols-sm-2 .mozaique.cust-nb-cols .thumb-block.thumb-ad-gold {
		.thumb-ad-content {
			.thumb-ad-logo {
				font-size: 6.2vw;
			}
			.slogan {
				padding: 0 1.5vw;
				font-size: 3vw;
			}
			.nb-uploads {
				padding: 0 1.5vw;
				font-size: 3vw * @ratio-txt;
			}
		}
	}
	
	.nb-thumbs-cols-sm-4 .mozaique.cust-nb-cols .thumb-block.thumb-ad-gold {
		.thumb-ad-content {
			.thumb-ad-logo {
				margin-bottom: 3px;
				font-size: 3.2vw;
			}
			.slogan {
				padding: 0 0.5vw;
				font-size: 1.4vw;
			}
			.nb-uploads {
				margin-top : 3px;
				padding: 0 0.5vw;
				font-size: 1.5vw * 0.9;
			}
		}
	}
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
	.thumb-block.thumb-ad-gold,
	.nb-thumbs-cols-md-3 .mozaique.cust-nb-cols .thumb-block.thumb-ad-gold {
		.thumb-ad-content {
			.thumb-ad-logo {
				margin-bottom: 7px;
				font-size: 4vw;
			}
			.slogan {
				padding: 0 1vw;
				font-size: 2vw;
			}
			.nb-uploads {
				padding: 0 1vw;
				font-size: 2vw * @ratio-txt;
			}
		}
	}
	
	.nb-thumbs-cols-md-2 .mozaique.cust-nb-cols .thumb-block.thumb-ad-gold {
		.thumb-ad-content {
			.thumb-ad-logo {
				font-size: 6.5vw;
			}
			.slogan {
				padding: 0 1.5vw;
				font-size: 3.1vw;
			}
			.nb-uploads {
				padding: 0 1.5vw;
				font-size: 3.1vw * @ratio-txt;
			}
		}
	}
	
	.nb-thumbs-cols-md-4 .mozaique.cust-nb-cols .thumb-block.thumb-ad-gold {
		.thumb-ad-content {
			.thumb-ad-logo {
				margin-bottom: 3px;
				font-size: 3vw;
			}
			.slogan {
				padding: 0 0.5vw;
				font-size: 1.5vw;
			}
			.nb-uploads {
				margin-top: 2px;
				padding: 0 0.5vw;
				font-size: 1.5vw * 0.9;
			}
		}
	}
}

@media (min-width: @screen-md-min) {
	.thumb-block.thumb-ad-gold,
	.nb-thumbs-cols-lg-4 .mozaique.cust-nb-cols .thumb-block.thumb-ad-gold {
		.thumb-ad-content {
			.thumb-ad-logo {
				font-size: 42px;
			}
			.slogan {
				padding: 0 10px;
				font-size: 20px;
			}
			.nb-uploads {
				padding: 0 10px;
				font-size: 16px;
			}
		}
	}
	
	.nb-thumbs-cols-lg-3 .mozaique.cust-nb-cols .thumb-block.thumb-ad-gold {
		.thumb-ad-content {
			.thumb-ad-logo {
				font-size: 52px;
			}
			.slogan {
				font-size: 26px;
			}
			.nb-uploads {
				font-size: 20px;
			}
		}
	}
	
	.nb-thumbs-cols-lg-5 .mozaique.cust-nb-cols .thumb-block.thumb-ad-gold {
		.thumb-ad-content {
			.thumb-ad-logo {
				font-size: 32px;
			}
			.slogan {
				font-size: 16px;
			}
			.nb-uploads {
				font-size: 13px;
			}
		}
	}
}

@media (max-width: @screen-xxs-max) {
	.thumb-block.thumb-ad-gold,
	.nb-thumbs-cols-xs-1 .mozaique.cust-nb-cols .thumb-block.thumb-ad-gold {
		.thumb-ad-logo {
			font-size: 11vw;
		}
		.slogan {
			padding: 0 5vw;
			font-size: 5.5vw;
		}
		.nb-uploads {
			padding: 0 5vw;
			font-size: 5.5vw * @ratio-txt;
		}
	}
	
	.nb-thumbs-cols-xs-2 .mozaique.cust-nb-cols .thumb-block.thumb-ad-gold {
		.thumb-ad-logo {
			font-size: 5.8vw;
		}
		.slogan {
			padding: 0 2vw;
			font-size: 2.9vw;
		}
		.nb-uploads {
			padding: 0 2.9vw;
			font-size: 2.9vw * @ratio-txt;
		}
	}
	
	.nb-thumbs-cols-xs-3 .mozaique.cust-nb-cols .thumb-block.thumb-ad-gold {
		.thumb-ad-logo {
			margin-bottom: 3px;
			font-size: 4vw;
		}
		.slogan {
			padding: 0 1vw;
			font-size: 2vw;
		}
		.nb-uploads {
			margin-top: 2px;
			padding: 0 1vw;
			font-size: 2vw * 0.9;
		}
	}
}