#premium-popup-signin {
	.purchase-form-contents {
		.multiform-header {
			.choose-offer {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 10px;

				& > span {
					line-height: 1.15em;
				}
			}
		}
	}
}

/* ==== Purchase v2023 style ==== */
@theme-purchase-color: @blue-theme-pastel;
body .x-overlay.x-overlay-box.premium-popup-form-v1 .x-body .x-content {
	.red-color,
	h1.red-color,
	h2.red-color,
	h3.red-color {
		color: @theme-purchase-color;
	}
	
	
	.selectable-element {
		&:hover {
			outline-color: @theme-purchase-color;
		}
		&.selected {
			outline-color: @theme-purchase-color;
		}
	}
	
	
	.option-crypto {
		&.selected {
			outline-color: @theme-purchase-color;
		}
	}
	
	button.btn-danger[type=submit] {
		background-color: @theme-purchase-color !important;
	}
}
/* ==== Purchase v2023 style ==== */
/*
body .x-overlay.x-overlay-box.premium-form-layout-v2 .x-body .x-content {
	.selectable-element {
		&:hover,
		&.selected {
			outline-color: @theme-purchase-color;
		}
	}
	
	.option-crypto {
		&.selected {
			outline-color: @theme-purchase-color;
		}
	}
	
	button.btn-danger[type=submit] {
		background-color: @theme-purchase-color;
	}
}*/