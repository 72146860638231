#tags {
	li {
		width: 33.333%;
		padding: 5px;
	}
	a {
		font-size: 15px;
	}
}

#content {
	.videos-by-profile {
		.activity-event {
			display: block;
		}
	}
}
.tab-form {
	.create-account {
		border-left: 0;
		border-top: 2px solid @gray-lighter;
		padding-top: 10px;
		margin-top: 10px;
		.text-center {
			text-align: right;
		}
	}
}

.profiles-page {
	#content-thumbs {
		> p {
			margin: @content-padding;
		}
	}
}

.simple-dropdown {
	.niv1 {
		a {
			padding-left: 4px;
			padding-right: 4px;
		}
	}
}

#page.premium_feed {
	.sub-channels {
		padding-top: 4px;
	}
	
	.sub-channels-title {
		.added-removed {
			float: none;
			display: block;
			padding: 6px 0 0;
		}
	}
}

.mozaique {
	.thumb-block {
		.thumb-inside {
			.action-menu {
				height: 40px;
				line-height: 40px;
				font-size: 20px;
				width: 40px;
			}
		}
	}
}
