@import "../../../common/form/search-report";
@import "../../../common/form/toggle";
@import "../../../common/form/custom_checkbox";

@keyframes input_error_focus {
	0% {
		box-shadow: 0 0 0 0 fadeout(@state-danger-text, 20%),  0 0 0 1000px fadeout(@state-danger-text, 100%) inset;
	}
	5% {
		box-shadow: 0 0 0 0 fadeout(@state-danger-text, 20%),  0 0 0 1000px fadeout(@state-danger-text, 40%) inset;
	}
	40% {
		box-shadow: 0 0 10px 25px fadeout(@state-danger-text, 100%), 0 0 0 1000px fadeout(@state-danger-text, 20%) inset;
	}
	80% {
		box-shadow: 0 0 0 0 fadeout(@state-danger-text, 100%), 0 0 0 1000px fadeout(@state-danger-text, 20%) inset;
	}
	100% {
		box-shadow: 0 0 0 0 fadeout(@state-danger-text, 100%), 0 0 0 1000px fadeout(@state-danger-text, 100%) inset;
	}
}

.alert {
	padding-top: 5px;
	padding-bottom: 5px;
	border-width: 0 4px;
	margin-bottom: 1px;
	& + :not(.alert) {
		margin-top: 10px;
	}
	.icon-f {
		vertical-align: text-bottom;
	}
	.form-error-list {
		&:first-child {
			&:last-child {
				margin: 0;
			}
		}
	}
}

.form-error-list {
	padding: 0;
}

.form-error-list-contener {
	font-size: 16px;
	overflow: hidden;
	padding-left: 40px;
	position: relative;
	
	.form-error-list {
		position: relative;
		z-index: 2;
	}
	& > .icon-f {
		font-size: 40px;
		left: 0;
		opacity: 0.2;
		top: 5px;
		position: absolute;
	}
}

.icon-bg {
}
form {
	&, #content & {
		.validator-err-sc {
			border: 1px solid @warning-block-border;
			background: @warning-block-bg;
			color: @warning-block-color;
			margin-bottom: 8px;
			overflow: hidden;
			padding: 12px 30px 14px 18px;
			position: relative;
			text-align: left;
			& > * {
				position: relative;
				z-index: 2;
			}
			.icon-bg {
				font-size: 22px;
				color: darken(@warning-block-color, 5%);
				left: 10px;
				position: absolute;
				top: 12px;
				z-index: 1;
			}
			.close-btn {
				background: @warning-block-bg;
				display: block;
				height: 28px;
				line-height: 28px;
				margin: 0;
				position: absolute;
				right: 0;
				text-decoration: none;
				text-align: center;
				top: 0;
				width: 28px;
				&:hover {
					background: darken(@warning-block-bg, 5%);
				}
				.icon-f {
					color: @warning-block-color;
					vertical-align: baseline;
				}
			}
			h3 {
				color: @warning-block-color!important;
				font-size: 16px!important;
				margin: 0 0 10px 22px!important;
				line-height: 22px!important;
			}
			p.form-error-shortcut {
				line-height: 20px;
				&:last-child {
					margin-bottom: 0;
				}
				& > .icon-f {
					left: 0;
					line-height: 20px;
					position: absolute;
				}
				a {
					color: @warning-block-color!important;
					display: block;
					padding-left : 16px;
					&, span:hover {
						text-decoration: none;
					}
					span {
						text-decoration: underline;
					}
				}
			}
		}
	}
}

.form-control {
	.box-shadow(none);
	.highlight-errors & {
		&.validator-error {
			&.hl-focus {
				&, & + div .emojionearea-editor {
					animation: input_error_focus 1.5s ease-out;
				}
			}
		}
	}
}

input[type="checkbox"],
input[type="radio"] {
	margin-top: 0;
}

.checkbox .checkbox-error-box {
	position: absolute;
	border: 2px solid @theme-color;
}

.help-block {
	&.text-danger {
		color: #f00;
	}
}


.copy-link {
	overflow: hidden;
	.copy-btn{
		cursor: default;
		float: left;
		height: 32px;
		margin: 0;
		opacity: 0.8;
		width: 15%;
		&.init-ok{
			cursor: pointer;
			opacity: 1
		}
	}
	input{
		border-left-width: 0;
		height: 32px;
		float: left;
		padding: 7px 12px;
		width: 85%;
	}
}

.link-copied-popup {
	.x-popup-content {
		padding: 6px 10px 8px;
		p {
			margin: 0;
		}
	}
}

.form-range {
	width: 100%;
	
	.min,
	.max {
		line-height: 14px;
		margin: 0 0 15px;
		max-width: 50%;
		min-width: 30px;
		padding: 0;
		position: relative;
		text-align: center;
		z-index: 1;
	}
	
	.min:before,
	.max:before,
	input[type="range"]:before,
	input[type="range"]:after {
		background-color: #fff;
		content: ' ';
		display: block;
		height: 6px;
		left: 14px;
		opacity: 0.3;
		position: absolute;
		top: 18px;
		width: 2px;
		z-index: 1;
	}
	
	.min {
		float: left;
	}
	
	.max {
		float: right;
		
		&:before {
			left: auto;
			right: 14px;
		}
	}
	
	input[type="range"] {
		clear: both;
		position: relative;
		z-index: 2;
		
		&:before {
			left: calc(~"50% - 1px");
			top: -11px;
		}
		
		&:after {
			background-color: transparent;
			border-left: 2px solid #fff;
			border-right: 2px solid #fff;
			left: calc(~"(100% - 30px) / 4 + 15px - 1px");
			width: calc(~"50% - 13px");
			top: -11px;
		}
		
		&::-webkit-slider-thumb {
			position: relative;
			z-index: 2;
		}
		
		&::-moz-range-thumb {
			position: relative;
			z-index: 2;
		}
	}
}

input[type="range"] {
	-webkit-appearance: none;
	appearance: none;
	background: @blue-theme-light;
	border-radius: 3px;
	height: 6px;
	margin: 15px 0;
	outline: none;
	transition: opacity .2s;
	width: 100%;
	
	&:hover {
		background: @blue-theme-lighter;
	}
	
	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		background: @yellow-theme;
		border-radius: 50%;
		cursor: pointer;
		height: 30px;
		width: 30px;
	}
	
	&::-moz-range-thumb {
		background: @yellow-theme;
		border-radius: 50%;
		cursor: pointer;
		height: 30px;
		width: 30px;
	}
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
select:focus,
textarea {
	&, &.form-control {
		font-size: 17px;
	}
}
