
#header-mobile-menu-toggle {
	display: none;
}

#header {
	#site-nav {
		background: @blue-theme-light;
		clear: both;
		direction: ltr;
		min-height: 34px;
		font-size: 14px;
		
		.header-icons {
			float: right;
			padding: 0 4px 0 0;
			overflow: hidden;
			height: 34px;
			.country-switch{
				background: @blue-theme-light-border;
				background: rgba(255,255,255,.4);
				color: #fff;
				display: inline-block;
				line-height: 25px;
				margin: 5px;
				padding: 0 10px;
				text-decoration: none;
				&:hover{
					background: rgba(0,0,0,.4);
					color: #fff;
				}
			}
			.email{
				line-height: 34px;
				padding: 0 4px;
			}
			
			.icon-sitenav-bg {
				height: 26px;
				line-height: 26px;
				
				.icon-f {
					line-height: 26px;
				}
			}
			
			.btn,
			.icon-sitenav-bg {
				padding: 0 5px;
				text-decoration: none;
				
				&:hover,
				&:focus {
					color: @yellow-theme;
				}
				
				.icon {
					vertical-align: middle;
				}
				
			}
			
			.btn {
				font-size: 14px;
				height: 34px;
				line-height: 32px;
				
				.icon-f {
					font-size: 20px;
					line-height: 32px;
				}
			}
		}
		
		.mobile-site-nav-title {
			color: @yellow-theme;
			font-size: 16px;
			font-weight: bold;
			line-height: 20px;
			padding: 7px 10px 7px 20px;
			.sub{
				color: white;
				font-size: 11px;
				font-weight: normal;
			}
		}
		
		ul {
			display: flex;
			line-height: 30px;
			list-style: none;
			margin: 0;
			overflow: hidden;
			padding: 0 15px;
			
			li {
				display: inline-block;
				margin: 0 10px 0 0;
				overflow: hidden;
				padding: 0;
				vertical-align: top;
				
				&.mobile-show {
					display: none;
				}
				
				&.gold:first-child,
				&.hamburger {
					margin-left: -15px;
				}
				
				&.hamburger {
					& + .gold {
						margin-left: -10px;
					}
				}
				
				&.hide-if-not-premium {
					display: none;
				}
				
				a {
					border-bottom: 2px solid @blue-theme-light;
					display: inline-block;
					margin: 0;
					padding: 1px 10px;
					text-decoration: none;
					text-transform: uppercase;
					.transition;
					white-space: nowrap;
					
					&.icon-header-bg {
						border-bottom-color: transparent;
					}
					
					&:hover,
					&.current,
					&.active,
					&.icon-header-bg:hover {
						border-bottom-color: #fff;
					}
					
					.icon-f {
						margin-right: 4px;
					}
					
					&#header-desktop-cat-menu-toggle {
						padding: 4px 10px 28px;
					}
					&.gold-plate {
						border-bottom-color: @gold-plate-border;
						vertical-align: top;
					}
					&.free-plate {
						border-bottom-color: @free-plate-border;
						vertical-align: top;
					}
				}
				
				img {
					height: 16px;
					vertical-align: text-top;
				}
			}
		}
		
		#header-mobile-gold,
		#account-menu {
			display: none;
		}
	}
}

.account-menu-pop.x-popup {
	#account-menu {
		padding: 6px 4px;
	}
	
	.x-popup-content {
		a {
			display: block;
			font-size: 16px;
			text-align: left;
			[dir="rtl"] & {
				text-align: right;
			}
			
			&.gold-plate {
				color: @gold-plate-color;
				margin-top: 10px;
				text-align: center;
			}
		}
	}
}

.xnxx_mobile_nav(@mobile-header-height) {
	/*@media @media-ratio-horizontal {
		#header {
			#site-nav {
				.mobile-menu {
					top: @mobile-header-height;
				}
			}
		}
	}*/
	#header {
		.mobile-menu {
			overflow: visible;
			position: absolute;
			top: @mobile-header-height;
			left: 0;
			right: 0;
			width: 100%;
			z-index: @zindex-mobile-menu;
			box-shadow: 0 6px 22px -1px fade(#000, 75%);
			&.mobile-hide {
				display: none;
			}
		}
		
		#site-nav {
			ul {
				background: fade(@blue-theme-light, 90%);
				display: block;
				padding: 0;
				
				li {
					display: block;
					margin: 0;
					
					&.mobile-show {
						display: block;
					}
					
					&.mobile-hide {
						display: none;
					}
					
					&:first-child {
						a {
							border-top: 0;
						}
					}
					
					&.gold:first-child a,
					&.hamburger + .gold a {
						margin-left: 0;
					}
					
					a {
						display: block;
						margin: 0;
						padding: 12px 18px;
						border-right: 0;
						border-top: 1px solid @blue-theme;
						
						&.current {
							border-bottom: 0;
							background: #0840b9;
							font-weight: bold;
						}
					}
					
					&.premium-link {
						a {
							background-image: none;
						}
					}
				}
			}
			
		}
	}
	#header-mobile-menu-toggle {
		display: block;
		float: left;
	}
}