


// GAY Flag
.icon-flag-gay {
	display: inline-block;
	height: 12px;
	position: relative;
	vertical-align: middle;
	width: 22px;
	* {
		display: block;
		left: 2px;
		height: 2px;
		position: absolute;
		width: 18px;
		z-index: 1;
	}
	.r {
		background: #e40203;
		top: 0;
	}
	.o {
		background: #f98c00;
		top: 2px;
	}
	.y {
		background: #fced00;
		top: 4px;
	}
	.g {
		background: #1b8026;
		top: 6px;
	}
	.b {
		background: #294dfe;
		top: 8px;
	}
	.v {
		background: #740787;
		top: 10px;
	}
}

.icon-header-bg {
	float: left;
	height: 34px;
	padding: 5px @content-padding;
	background-color: fade(#fff, 20%);
	.transition;
	&:hover {
		background-color: fade(#000, 20%);
	}
}

.icon-sitenav-bg {
	display: inline-block;
	padding: 6px 5px;
	height: 26px;
    background: fade(#fff, 40%);
    margin: 4px 2px 0 0;
    border-radius: 3px;
	vertical-align: top;
    .transition;
	&:hover {
		background: fade(#000, 40%);
	}
}

.icon {
	display: inline-block;
	background-position: left top;
	background-repeat: no-repeat;
	background-image: url('../../../../v-00000000005/v3/img/skins/xnxx/icons-sprite.png');
	/* autoprefixer: off */
	background-image: linear-gradient(transparent, transparent), url('../../../../v-00000000005/v3/img/skins/xnxx/icons-sprite.svg');
	/* autoprefixer: on */
	&.twitter {
		width: 19px;
		height: 16px;
	}
	&.rss {
		width: 14px;
		height: 16px;
		background-position: -19px 0;
	}
	&.change-layout {
		width: 18px;
		height: 16px;
		background-position: -33px 0;
		&:hover {
			background-position: -51px 0;
		}
	}
	/*&.messages {
		width: 18px;
		height: 16px;
		background-position: -69px 0;
		&:hover {
			background-position: -87px 0;
		}
	}*/
	&.bookmark {
		width: 15px;
		height: 16px;
		background-position: -105px 0;
		&:hover {
			background-position: -120px 0;
		}
	}
	&.clock {
		width: 16px;
		height: 16px;
		background-position: -135px 0;
	}
	&.eye {
		width: 22px;
		height: 16px;
		background-position: -151px 0;
	}
	&.star {
		width: 16px;
		height: 16px;
		background-position: -173px 0;
	}
	&.star-half {
		width: 16px;
		height: 16px;
		background-position: -189px 0;
	}
	&.star-full {
		width: 16px;
		height: 16px;
		background-position: -205px 0;
	}
	&.thumb-up {
		width: 14px;
		height: 16px;
		background-position: -221px 0;
	}
	&.thumb-down {
		width: 14px;
		height: 16px;
		background-position: -235px 0;
	}
	&.comments {
		width: 24px;
		height: 16px;
		background-position: -249px 0;
	}
	&.download {
		width: 20px;
		height: 16px;
		background-position: -272px 0;
	}
	&.flag {
		width: 18px;
		height: 16px;
		background-position: -291px 0;
	}
	&.video-preview-on {
		width: 16px;
		height: 16px;
		background-position: -309px 0;
	}
	&.video-preview-off {
		width: 16px;
		height: 16px;
		background-position: -325px 0;
	}
	&.cog {
		width: 16px;
		height: 16px;
		background-position: -341px 0;
		&.black {
			background-position: -357px 0;
		}
	}
	&.playlist {
		width: 16px;
		height: 16px;
		background-position: -373px 0;
		vertical-align: text-bottom;
		&.blue {
			background-position: -389px 0;
		}
	}
	&.signout {
		width: 16px;
		height: 16px;
		background-position: -404px 0;
		&:hover {
			background-position: -420px 0;
		}
	}
	&.check {
		width: 16px;
		height: 16px;
		background-position: -240px -16px;
		&.blue {
			background-position: -256px -16px;
		}
	}
	&.search-small {
		width: 16px;
		height: 16px;
		background-position: -224px -16px;
	}
	&.edit {
		width: 16px;
		height: 16px;
		background-position: -272px -16px;
		&.blue {
			background-position: -288px -16px;
		}
	}
	&.save {
		width: 16px;
		height: 16px;
		background-position: -304px -16px;
		&.blue {
			background-position: -320px -16px;
		}
	}
	&.hamburger {
		width: 24px;
		height: 24px;
		background-position: 0 -16px;
		&.vertical,
		.vertical & {
			background-position: -24px -16px;
		}
	}
	&.search {
		width: 24px;
		height: 24px;
		background-position: 0 -40px;
		&.close,
		.close & {
			background-position: -24px -40px;
		}
	}
}

