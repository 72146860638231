
#header {
	.topbar {
		background: url('../../../v3/img/skins/xnxx/top-stripe.png') fade(@blue-theme-dark, 90%);
		background-size: auto 100%;
		display: block;
		height: 72px;
		padding: @header-padding;
		position: relative;
		.logo-xnxx {
			float: left;
			margin-right: 20px;
			a {
				display: inline-block;
				text-decoration: none;
				&:hover {
					opacity: 0.7;
					text-decoration: none;
				}
			}
			img {
				height: 58px;
			}
			.gold-plate {
				display: inline-block;
				vertical-align: middle;
				margin-top: -2px;
				font-size: 38px;
			}
		}
		.slogan {
			font-size: 12px;
			margin: 4px 0 0;
			overflow: hidden;
			text-align: left;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		#language-and-version{
			float: right;
			ul{
				font-size: 14px;
				list-style: none;
				margin: 0 7px 0 0;
				text-align: right;
				li {
					display: inline-block;
					height: 22px;
					line-height: 26px;
					margin: 0 0 0 10px;
					text-decoration: none;
					a {
						&, &:hover, &:focus, &:active {
							text-decoration: none;
						}
					}
					.icon-flag-gay {
						margin-top: -2px;
					}
				}
			}
		}
	}
}


#account-menu-btn {
	.init-ok-btn();
}

#listing-settings {
	display: inline-block;
	.init-ok-btn();
	vertical-align: top;
	#content & {
		display: block;
		text-align: right;
		.pagination + & {
			float: right;
			margin-top: -33px;
			margin-right: @content-padding;
		}
		.btn {
			height: 29px;
			padding: 5px 2px 5px 7px;
			background: #000;
			color: #fff;
			border-radius: 0;
			border-color: #000;
			box-shadow: none;
			&:hover {
				background: @blue-theme-light;
			}
			.icon {
				vertical-align: bottom;
				display: none;
			}
		}
	}
}

.listing-settings-popup {
	z-index: @zindex-listing-settings;
	
	.x-popup-content {
		padding: 8px;
		
		ul {
			margin: 0;
			padding: 0;
			
			li {
				padding: 0;
				
				&.title {
					font-size: 16px;
					font-weight: bold;
					line-height: 24px;
					opacity: 0.5;
					padding: 6px 20px;
					text-align: center;
				}
			}
		}
	}
}
.site-settings {
	.language-switcher,
	.currency-switcher,
	.dev-menu {
		display: none;
	}
	.show-lang &,
	.show-currency & ,
	.show-dev & {
		& > ul {
			display: none;
		}
	}
	.show-lang & .language-switcher,
	.show-currency & .currency-switcher,
	.show-dev & .dev-menu {
		display: block;
	}
	
	button,
	.language-switcher a {
		background: none;
		border: 0;
		display: block;
		font-size: 16px;
		padding: 7px 12px;
		text-align: left;
		text-decoration: none;
		width: 100%;
		&:focus, &:active, &:focus:active {
			outline: 0;
		}
		&:hover {
			background: @tooltip-bg-lighten;
			color: #fff;
			text-decoration: none;
		}
		.icon {
			margin-left: 4px;
			vertical-align: middle;
			&.listing-less-thumbs,
			&.listing-more-thumbs {
				margin: 0;
			}
		}
		.emoji {
			display: inline-block;
			margin: 0 5px 0 0;
			width: 18px;
		}
		.icon-f{
			display: inline-block;
			text-align: right;
			width: 20px;
			&.icf-suggest-square {
				font-size: 13px;
				padding-right: 1px;
			}
		}
		span:first-child{
			margin-right: 5px;
			opacity: 1;
			transition:  opacity 0.5s ease;
			&.disabled {
				opacity: 0.4;
			}
		}
		
		[dir="rtl"] & {
			text-align: right;
		}
	}
	
	.language-switcher {
		ul {
			margin: 0;
			padding: 0;
		}
		a {
			font-weight: bold;
		}
	}
	
	.currency-switcher {
		ul {
			column-width: 86px;
			max-width: 95vw;
			width: 900px;
		}
		
		.btn {
			font-size: 20px;
			font-weight: normal;
			padding: 6px 0;
			text-align: center;
			
			&:active {
				box-shadow: none;
			}
			
			&:focus {
				color: #fff;
			}
			
			&.is-current {
				color: @yellow-theme;
				font-weight: bold;
			}
		}
	}
	
	.currency-switch-btn {
		strong {
			color: @yellow-theme;
		}
	}
	
	.sep {
		border-bottom: 1px solid rgba(255, 255, 255, 0.3);
		height: 1px;
		margin: 2px 20px 3px;
	}
}

.currency-error-pop {
	&.x-popup {
		&.below .x-popup-arrow {
			border-bottom-color: @body-bg;
		}
		
		.x-popup-content {
			background-color: @body-bg;
			color: @red-color;
			font-size: 15px;
			padding: 5px 15px;
		}
	}
}

#xnxx-search-bar {
	margin-top: 5px;
	text-align: left;
	input {
		width: 260px;
		border: 0px;
		padding: 5px @content-padding;
		&::selection {
			background: @blue-theme;
			color: #fff;
		}
	}
}

.x-popup .x-popup-content {
	background: @tooltip-bg;
}

.search-autocomplete {
	background: @blue-theme-darker;
	border: 1px solid @blue-theme-light-border;
	color: #fff;
	display: none;
	font-size: 14px;
	left: 0;
	position: absolute;
	top: 0;
	z-index: @zindex-autocomplete;
	.title {
		padding: 4px 0;
		background: @blue-theme-light;
		color: #fff;
		font-weight: bold;
	}
	ul {
		margin: 0;
		padding: 0;
		max-height: 160px;
		overflow-y: auto;
		position: relative;
	}
	li {
		cursor: pointer;
		list-style: none;
		padding: 2px 0;
		a {
			text-decoration: none;
			display: block;
			overflow: hidden;
			padding: 4px 0;
			white-space: nowrap;
			color: #fff;
			.nbres {
				float: right;
				margin-left: 6px;
				background-color: @blue-theme-light;
				color: #fff;
				font-size: 12px;
				font-weight: bold;
				line-height: 16px;
				padding: 0 5px;
				border-radius: 8px;
			}
			.keywords {
				display: block;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.highlighted {
				background: @blue-theme;
			}
		}
		&:hover {
			background: @blue-theme-pastel;
		}
		&.selected {
			background: @blue-theme-lighter;
		}
		&.more {
			margin-top: 20px;
			position: relative;
			
			&::before {
				background: @blue-theme-darker;
				content: @icf-plus-square;
				color: @blue-theme;
				display: block;
				.font-family-iconfont;
				font-size: 18px;
				left: 50%;
				line-height: 18px;
				margin: 0 -15px;
				position: absolute;
				text-align: center;
				top: -18px;
				width: 30px;
				z-index: 2;
			}
			
			&::after {
				background: @blue-theme-dark;
				content: ' ';
				height: 2px;
				left: 0;
				position: absolute;
				top: -9px;
				width: 100%;
				z-index: 1;
			}
		}
	}
	.pornstars {
		li {
			line-height: 16px;
			a {
				display: flex;
			}
			.thumb {
				display: block;
				float: left;
				min-width: 40px;
				width: 40px;
				min-height: 22.5px;
				height: 22.5px;
				margin-right: 5px;
				text-align: center;
				background-repeat: no-repeat;
				background-position: center center;
				background-size: auto 100%;
				[dir="rtl"] & {
					margin-left: 5px;
					margin-right: 0;
				}
			}

			.sub {
				padding: 2px 4px;
				background-color: #28291b;
				color: #fff;
				@media (max-width: @screen-xs-max) {
					background: initial;
				}
				border-radius: 4px;
				font-size: 12px;
				line-height: 18px;
				vertical-align: middle;
				margin-left: auto;
			}

			.name {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.name,
			.rank {
				padding-top: 3px;
				vertical-align: middle;
			}
			.name,
			.rank-pos,
			.rank-type,
			.sub {
				font-weight: bold;
			}
			.rank-pos,
			.rank-type {
				color: @yellow-theme;
			}
		}
	}
}

#language-switcher,
#main-cat-switcher {
	display: inline-block;
	margin: 0 2px 0 0;
	vertical-align: top;
	height: 32px;
	cursor: pointer;
}