.fan-video {
	display: inline-block;
	font-size: 12px;
	line-height: 21px;
	width: 20px;
	border-radius: 10px;
	height: 20px;
	background: #000;
	color: #fff;
	font-weight: bold;
	text-align: center;
	.thumb-inside & {
		position: absolute;
		bottom: 6px;
		right: 4px;
		text-decoration: none;
	}
}

.profiles-page {
	#content-thumbs {
		> p {
			margin: (@content-padding * 1.5) @content-padding;
		}
		.btn-primary {
			text-decoration: none;
		}
		.infobar {
			margin: 14px 7px 26px;
			padding: 8px 0;
			& + .infobar {
				margin-top: -20px;
			}
		}
	}
	.mozaique {
		.thumb-block {
			.thumb-under {
				min-height: 0;
				.profile-name {
					font-weight: bold;
					padding: 2px 0 5px;
				}
			}
		}
	}
}

#content {
	.page-title {
		&.channel {
			a {
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}