// Offer button style
.signin-offer-ribbon-christmas-23 {
	.submit-txt-product {
		.discount-logo {
			background-color: #4a668d;
		}
	}
}

/* ==== max 767px = MAINSTREAM OF MOBILE WIDE SCREEN AND TABLETS ==== */
@media (max-width: @screen-all-mobile) {
	@media(min-width: 480px) {
		#premium-popup-form.signin-offer-ribbon-christmas-23 {
			.offer-ribbon-christmas-23 {
				&.offer-ribbon-christmas-23-premium.offer-ribbon-christmas-23-bigger {
					top: -132px;
					width: calc(100% + 90px);
				}
			}
			.offer-ribbon-christmas-23-part.offer-ribbon-christmas-23-premium {
				top: -72px;
			}

			@media(max-width: 641px) {
				.offer-ribbon-christmas-23 {
					&.offer-ribbon-christmas-23-premium.offer-ribbon-christmas-23-bigger {
						top: -244px;
					}
				}
				.offer-ribbon-christmas-23-part.offer-ribbon-christmas-23-premium {
					top: -184px;
					&.offer-ribbon-christmas-23-left {
						left: -45px;
					}
					&.offer-ribbon-christmas-23-right {
						right: -45px;
					}
				}
			}
			@media(max-width: 561px) {
				.offer-ribbon-christmas-23 {
					&.offer-ribbon-christmas-23-premium.offer-ribbon-christmas-23-bigger {
						top: -234px;
					}
				}
			}
		}
	}
}

.christmas-23-banner {
	position: relative;
	background-image: url(@offer-christmas-banner);
	width: 100%;
    background-position: center;
    background-size: auto 100%;
    background-repeat: no-repeat;

	&:hover {
		cursor: pointer;
	}

	.christmas-23-banner-bounds {
		position: absolute;
		top: 0;
		height: 50%;

		left: 49%;
		width: 43%;
		font-size: 41px;

		transform: translate(-80%, 28%);
		text-align: right;
		.christmas-23-banner-text {
			text-transform: uppercase;
			font-weight: bold;
			text-shadow: 0 0 7px #2665e3;
		}
	}
	.christmas-23-banner-button {
		display: flex;
   		align-items: center;
		position: absolute;
		bottom: 0;
		left: 50%;
		height: 25%;
		transform: translate(-10%, -50%);

		&:hover {
			opacity: 0.9;
		}
	}

    height: 170px;
	@media(max-width: 1920px) {
		height: 121px;
		.christmas-23-banner-bounds {
			left: 49%;
			width: 39%;
		}
	}
	@media(max-width: 1800px) {
		.christmas-23-banner-bounds {
			font-size: 35px;
		}
	}
	@media(max-width: 1200px) {
		.christmas-23-banner-bounds {
			left: 46%;
    		width: 49%;
			font-size: 32px;
		}
	}
	@media(max-width: 970px) {
		height: 100px;
		.christmas-23-banner-bounds {
			left: 50%;
			width: 59%;
			font-size: 32px;
		}
	}
	@media(max-width: 700px) {
		height: 85px;
		.christmas-23-banner-bounds {
			font-size: 23px;
		}
	}
	@media(max-width: 600px) {
		height: 70px;
		.christmas-23-banner-bounds {
			font-size: 19px;
		}
	}
	@media(max-width: 500px) {
		height: 60px;
		.christmas-23-banner-bounds {
			font-size: 16px;
		}
	}
	@media(max-width: 400px) {
		height: 50px;
		.christmas-23-banner-bounds {
			font-size: 13px;
		}
	}
}