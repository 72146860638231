
//.icon.comments,
//.icon.rss,
//.icon.search,
.icon.search-small//,
//.icon.thumb-down,
//.icon.thumb-up
{
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'iconfont' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
	background: none;
	text-align: center;
}
//
//.icon.comments{
//	&:before {
//		content: @icf-comment-o;
//	}
//}
//
//.icon.rss {
//	&:before {
//		content: @icf-feed;
//	}
//}

//.icon.search,
.icon.search-small {
	&:before {
		content: @icf-search;
	}
}
//
//.icon.thumb-down {
//	&:before {
//		content: @icf-thumb-down;
//	}
//}
//
//.icon.thumb-up {
//	&:before {
//		content: @icf-thumb-up;
//	}
//}
//
//.icon.comments {
//	color: white;
//	font-size: 23px;
//	line-height: 22px;
//	height: 24px;
//	width: 24px;
//	.active &,
//	.desktop-active &,
//	a:hover &,
//	&:hover {
//		color: black;
//	}
//}
//.icon.rss {
//	color: white;
//	font-size: 17px;
//	height: 16px;
//	width: 16px;
//	&.red,
//	&:hover {
//		color: red;
//	}
//	&.no-hover:hover {
//		color: white;
//	}
//	&.black,
//	&.black-hover:hover,
//	.btn:hover &.black-hover,
//	.active &.black-hover {
//		color: black;
//	}
//}
//
//.icon.search{
//	color: white;
//	font-size: 22px;
//	margin: 8px;
//	height: 24px;
//	width: 24px;
//	&:hover {
//		color: red;
//	}
//	&.close {
//		position: relative;
//		&:after{
//			content: "x";
//			display: block;
//			font-family: sans-serif;
//			font-size: 17px;
//			font-weight: bold;
//			line-height: 16px;
//			position: absolute;
//			left: 2px;
//			top: 2px;
//			width: 17px;
//		}
//	}
//}

.icon.search-small{
	color: white;
	font-size: 11px;
	height: 16px;
	line-height: 16px;
	vertical-align: middle;
	width: 16px;
}
//
//.icon.thumb-down,
//.icon.thumb-up{
//	color: black;
//	font-size: 15px;
//	height: 16px;
//	line-height: 16px;
//	width: 15px;
//	&.red,
//	&.white:hover {
//		color: #de2600;
//	}
//	&.no-hover:hover,
//	.btn:hover &.black.no-hover {
//		color: black;
//	}
//	&.white,
//	.btn:hover &.white.no-hover,
//	&.white.no-hover:hover,
//	.btn:hover &.black,
//	.btn:hover &.red {
//		color: white;
//	}
//	&.black-hover:hover,
//	.btn:hover &.black-hover,
//	.active &.black-hover {
//		color: black;
//	}
//	&.grey,
//	&.white.disabled,
//	.disabled &,
//	.disabled &.white:hover {
//		color: #ccc;
//	}
//}


body .alert-with-icf-bg {
	margin: 10px;
}