#suggestions_models_list, #models_list .mozaique {
    .card {
        margin: 0;
        padding: 5px;

        .card-img {
            .overlay-container {
                .overlay {
                    .icf-ban {
                        color: @text-color;
                        font-size: 6rem;
                        left: 0;
                        right: 0;
                        text-shadow: 1px 1px @text-color;
                        top: 20%;
                    }

                    .metadata-remove {
                        background-color: @btn-primary-bg;
                        color: @text-color;
                        cursor: pointer;
                        font-size: 15px;
                        font-weight: bolder;
                        height: 25px;
                        @media screen and (min-width: 1024px) {
                            &:hover {
                                background-color: @text-color;
                                color: @btn-primary-bg;
                            }
                        }
                    }
                }
            }
        }
    }
}

.model-list-container {
    #models_list {
        .super-container {
            a {
                cursor: pointer;
            }

            .container-list-models-suggestion {
                background: @blue-theme-darker2;
            }
        }
    }
}

.metadata-suggest-popup {
    .form-control {
        color: @text-color-medium-tone;
        background-color: @dark-gray-bg;
        border: 1px solid @light-border;
    }

    .model_suggestion, input[name='metadata_tag_suggestion'] {
        height: 32px;
    }

    .panel-models-suggestions {
        .metadata-suggest-box {
            flex-grow: 7;
            flex-wrap: wrap;

            input {
                border: none;
            }
        }

        .validate-suggest-tag, .validate-suggest-model {
            margin-left: 0;
            @media screen and (max-width: 1024px) {
                padding: 3px 0;
            }
        }
    }

    .input-group-btn.metadata-suggest-box, #suggestions_models_list {
        input {
            min-width: 250px;
        }
    }

    .validate-suggest-tag, .validate-suggest-model {
        height: 32px;
        margin-left: 5px;
        font-weight: bold;
    }

    #suggestions_tags_list, #suggestions_removed_tags_list, #suggestions_models_list {
        font-size: 0;

        li {
            margin: 5px 5px 0 0;

            a {
                font-size: 15px;
                margin: 0;
                padding-top: 2px;
            }

            display: inline-block;

            input[type=text] {
                font-size: 12px;
                width: 75px;

                &:focus {
                    outline: none;
                }
            }
        }

        a {
            button {
                outline: none;
                position: relative;

                &.btn-default {
                    padding-right: 20px;
                    color: @text-color;
                    background: @btn-primary-bg;
                }
            }
        }
    }

    #suggestions_models_list button {
        background: @blue-theme-light-sub-title;
        border-radius: 4px;
        margin-left: 10px;
        padding-left: 14px;
        position: relative;

        &:before {
            .font-family-iconfont;
            color: @text-color;
            content: @icf-star-full;
            font-size: 22px;
            left: -11px;
            line-height: 22px;
            position: absolute;
            text-shadow: 0 0 4px @body-bg;
            top: -2px;
            width: 22px;
        }

        &:hover {
            &:before {
                color: @gold-theme;
            }
        }
    }

}
