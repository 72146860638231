// =======================
// ALERT BADGE
// =======================
.ad-alert-badge {
	background: @blue-theme;
	border-radius: 4px;
	color: #fff;
	font-size: 10px;
	font-weight: bold;
	padding: 3px 4px 1px;
	position: absolute;
	top: 1px;
	left: 1px;
	z-index: 10;
	text-transform: uppercase;
}

// =======================
// REMOVE ADS BLOCK
// =======================
.remove-ads {
	a {
		line-height: 18px;
		text-decoration: none;
		white-space: nowrap;

		.remove-ads-link {
			text-decoration: underline;
		}

		.icon-f {
			color: @gray-lighter;
			font-size: 14px;
			vertical-align: text-bottom;

			&:not(:first-child) {
				margin-left: 4px;
			}
		}
	}
}

// =======================
// AD ZONES COMMON DISPLAY
// =======================
#ad-header-mobile,
#content-ad-top-zone,
#ad-footer,
#ad-footer2,
#video-right,
#related-videos .thumb-block.video-ad {
	video,
	img {
		display: block;
	}
}

// =======================
// VIDEO PAGE – SIDEBAR AD
// =======================
#video-right {
	a {
		display: block;
		position: relative;
	}

	> a:first-child {
		margin-bottom: 16px;
	}

	.exo-ad-ins-div {
		position: relative;
	}

	.exo-ad-square {
		height: 250px;

		&:first-child {
			margin-bottom: 16px;
		}
	}

	.exo-ad-playersiderectangle {
		height: 500px;
		overflow: hidden;
	}

	.remove-ads {
		text-align: right;

		a {
			height: auto;
		}
	}

	video,
	img {
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		max-height: 100%;
	}

	video {
		vertical-align: middle;
	}

	&.ad-support-mobile {
		@media @media-ratio-vertical {
			display: none;
		}
	}
}

// =======================
// VIDEO AD SUPPORT MOBILE / TABLET
// =======================
.video-ad-support-mobile-auto-height() {
	&.video-ad-support-mobile,
	&.video-ad-support-tablet {
		.thumb-inside {
			height: auto;
			padding: 0;
			margin-bottom: 0;

			& > a {
				display: block;
				margin: 0 auto;
				max-width: 450px;
			}
		}

		img,
		video {
			max-width: none;
			width: 100%;
		}
	}
}

// =======================
// RELATED VIDEOS ADS
// =======================
#related-videos {
	.thumb-block {
		&.video-ad-support-desktop {
			.thumb-inside {
				background: none;

				.exo-ad-ins-div,
				& > a {
					position: absolute;
					top: 0;
					left: 10px;
					right: 10px;
				}

				.exo-ad-playersiderectangle {
					max-height: 500px;
					overflow: hidden;
				}

				& > a {
					video,
					img {
						height: auto;
						max-height: 100%;
						max-width: 100%;
					}
				}
			}
		}

		&.video-ad {
			.thumb-inside {
				.exo-ad-ins-div,
				& > a {
					max-width: 300px;
					width: 300px;
				}
			}
		}

		&.video-ad-support-mobile,
		&.video-ad-support-tablet {
			margin-top: 0;
			text-align: left;
			width: 100%;

			.thumb-inside {
				padding: 0 15px 33.33%;

				a {
					display: inline-block;
					margin: 0;
					vertical-align: middle;

					img {
						display: block;
					}
				}

				.remove-ads {
					position: absolute;
					top: 100%;
					left: 0;
					right: 0;
					text-align: center;

					.pull-right {
						display: none;
					}

					a {
						box-shadow: none;
					}
				}
			}
		}
	}
}

// =======================
// HEADER / TOP ZONE MOBILE
// =======================
#content-ad-top-zone-contener,
#ad-header-mobile-contener {
	text-align: center;
	margin: 0 10px;
}

#content-ad-top-zone,
#ad-header-mobile {
	display: block;
	height: 0;
	margin: 20px auto;
	max-width: 480px;
	overflow: hidden;
	padding: 0 0 33.33%;
	padding-bottom: ~"min(33.33%, 160px)";
	position: relative;
	text-align: left;

	&.is-filled {
		box-shadow: 0 0 7px 1px #fff;
	}

	.exo-ad-ins-div {
		max-width: 300px;
		width: 300px;
	}

	& > a {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

// =======================
// MEDIA QUERIES – TOP ZONE
// =======================
@media (min-width: 300px + (2 * @content-padding)) {
	#content-ad-top-zone,
	#ad-header-mobile {
		&:not(.ad-support-tablet):not(.ad-support-desktop),
		&.ad-support-mobile {
			margin: 20px auto;
		}
	}
}

@media (min-width: 420px + (2 * @content-padding)) {
	#content-ad-top-zone,
	#ad-header-mobile {
		&:not(.ad-support-mobile),
		&.ad-support-tablet,
		&.ad-support-desktop {
			margin: 20px auto;
		}
	}
}

@media (min-width: @screen-over-all-mobile) {
	#content-ad-top-zone,
	#ad-header-mobile {
		&:not(.ad-support-mobile):not(.ad-support-tablet):not(.ad-support-desktop) {
			margin: 0;
			padding-bottom: 0;
		}
	}
}

@media (min-aspect-ratio: ~"3/4") {
	#content-ad-top-zone:not(.ad-support-mobile):not(.ad-support-tablet):not(.ad-support-desktop) {
		margin: 0;
		padding-bottom: 0;
	}
}

// =======================
// FOOTER ADS
// =======================
#related-videos.with-footer-ad .thumb-block.video-ad-support-mobile,
#related-videos.with-footer-ad .thumb-block.video-ad-support-tablet,
#ad-footer,
#ad-footer2 {
	clear: both;
	margin-top: 14px;

	.exo-ad-ins-div,
	& > a {
		display: inline-block;
		position: relative;
		box-shadow: 0 0 7px #000;
	}

	img,
	video {
		box-shadow: 0 0 0 1px #000;
		max-width: 100%;
	}

	.exo-ad-ins-div {
		img,
		video {
			box-shadow: none;
		}
	}

	video {
		vertical-align: middle;
	}
}

// =======================
// FOOTER LAYOUT
// =======================
#ad-footer,
#ad-footer2 {
	&.is-filled {
		height: 0;
		margin-left: auto;
		margin-right: auto;
		max-width: 480px;

		.is-desktop & {
			max-width: 900px;
			padding-bottom: 27.78%;

			& > * {
				display: block;
				max-width: 900px;
				width: 900px;
			}
		}

		&.ad-support-mobile,
		&.ad-support-tablet {
			padding-bottom: 33.33%;

			& > * {
				display: block;
				max-width: 300px;
				width: 300px;
			}
		}
	}
}

#ad-footer {
	text-align: center;

	&.with-related-ad {
		&,
		& + p.mobile-only-hide,
		& + .remove-ads {
			&, * {
				display: none;
			}
		}
	}

	#content-thumbs & {
		padding: 0 15px;

		& + .remove-ads {
			padding: 0 15px;
			max-width: 960px;
		}
	}

	& + .remove-ads {
		margin-top: 4px;
		text-align: left;

		p {
			margin: 0 auto;
			max-width: 898px;
			overflow: hidden;
		}
	}

	&.ad-support-mobile + .remove-ads p {
		max-width: 298px;
	}

	&.ad-support-tablet + .remove-ads p {
		max-width: 418px;
	}
}

#ad-footer2.is-filled {
	margin-left: @content-padding;
	margin-right: @content-padding;
}

// =======================
// FOOTER MEDIA QUERIES
// =======================
@media (min-width: 900px + (2 * @content-padding)) {
	#ad-footer,
	#ad-footer2 {
		.is-desktop &.is-filled {
			padding-bottom: 250px;
		}
	}

	#ad-footer2.is-filled {
		margin-left: auto;
		margin-right: auto;
	}
}

@media (min-width: 300px + (2 * @content-padding)) {
	#ad-footer,
	#ad-footer2 {
		&.is-filled.ad-support-tablet,
		&.is-filled.ad-support-mobile {
			padding-bottom: ~"min(33.33%, 160px)";
		}
	}

	#ad-footer2.is-filled.ad-support-tablet,
	#ad-footer2.is-filled.ad-support-mobile {
		margin-left: auto;
		margin-right: auto;
	}
}

// =======================
// AD BY TEXT
// =======================
#ad-by {
	text-align: center;
}

// =======================
// THUMB BLOCK – NATIVE EXO
// =======================
.thumb-block.thumb-nat-exo-ad {
	overflow: hidden;

	.mark {
		background: @blue-theme-light;
		border-radius: 3px;
		color: #fff;
		font-size: 10px;
		font-weight: bold;
		padding: 2px 4px;
		position: absolute;
		right: 5px;
		top: 5px;
		z-index: 5;
	}

	.thumb-inside {
		position: relative;
		z-index: 2;
		overflow: visible;
		padding: 0;
		height: auto;

		.thumb {
			position: relative;
			overflow: visible;

			a {
				border: 0;

				.exo-native-widget-item-image-wrapper {
					height: auto !important;
				}

				iframe {
					border: 1px solid @img-border-color;
				}
			}

			.exo-native-widget-item-content {
				background-color: @body-bg !important;
				height: auto !important;

				.exo-native-widget-item-title {
					color: @text-color !important;
					.line-clamp(2);
					display: -webkit-box !important;

					&:hover {
						color: @yellow-theme !important;
					}
				}

				.exo-native-widget-item-text {
					.line-clamp(1);
					display: -webkit-box !important;
				}

				.exo-native-widget-item-text,
				.exo-native-widget-item-brand {
					&, &:hover {
						color: @blue-theme-light-sub-title !important;
					}
				}
			}
		}
	}
}

// =======================
// WIDGET CONTAINER OVERRIDES
// =======================
.exo-native-widget-item-container {
	.exo-native-widget-item-content {
		background-color: #fff !important;

		.exo-native-widget-item-title {
			&, &:hover {
				color: #000 !important;
			}
		}

		.exo-native-widget-item-text,
		.exo-native-widget-item-brand {
			&, &:hover {
				color: @gray !important;
			}
		}
	}
}
