
#content {
	#content-thumbs {
		#results-top {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			margin: 8px;
			
			& > * {
				order: 1;
			}
			
			#listing-page-filters-block {
				order: 0;
			}
			
			h1 {
				line-height: 32px;
				margin: 0 0 0 12px;
				text-align: center;
			}
			
			#results-free-or-premium {
				line-height: 32px;
			}
		}
		
		.mobile-flex-space {
			flex-grow: 50;
		}
	}
}

#listing-page-filters-block {
	display: block;
	float: left;
	min-height: 0;
	padding: 0;
	position: relative;
	text-align: center;
	
	button.filters-opener {
		background: @blue-theme-light;
		border: 0;
		display: block;
		float: left;
		line-height: 24px;
		padding: 4px 10px;
		position: relative;
		white-space: nowrap;
		z-index: 2;
		
		.icon {
			margin-right: 10px;
			transition: all 0.3s;
			vertical-align: bottom;
		}
		
		&:active,
		&:focus {
			outline: none;
		}
	}
	#listing-settings{
		height: 40px;
	}
	&.opened_menu button.filters-opener {
		.icon {
			transform: rotate(90deg);
		}
	}
	
	
	&.opened_menu #filters-list {
		left: -8px;
		.close {
			opacity: 1;
			transition: opacity 0.4s 0.2s;
		}
	}
	#filters-list {
		background: @blue-theme;
		border-bottom: 1px solid @yellow-theme;
		border-right: 1px solid @yellow-theme;
		font-size: 15px;
		left: -400px;
		margin: 0;
		max-width: 350px;
		min-width: 250px;
		overflow: auto;
		padding-top: 62px;
		position: absolute;
		text-align: left;
		top: -8px;
		transition: left 0.5s;
		width: 95%;
		z-index: 1;
		.filter {
			display: block;
			margin-bottom: 16px;
			padding-left: 12%;
			&:after {
				content: '';
				display: none;
			}
			span {
				display: block;
				margin: 0 20px 0 0;
			}
			div {
				display: block;
				padding: 0 20px 0 18%;
				white-space: normal;
				ul {
					background: none;
					border: 0;
					display: block;
					padding: 0;
					position: relative;
					a {
						display: block;
						padding: 6px 4px 6px 8px;
						position: relative;
						&:before {
							border: 1px solid @yellow-theme;
							border-radius: 50%;
							content: " ";
							display: block;
							height: 20px;
							left: -20px;
							position: absolute;
							top: 5px;
							width: 20px;
						}
					}
					.selected a:after,
					a:active:after,
					a:focus:after {
						background: darken(@yellow-theme, 10%);;
						border-radius: 50%;
						content: " ";
						display: block;
						height: 10px;
						position: absolute;
						left: -15px;
						top: 10px;
						width: 10px;
					}
				}
				strong {
					display: none;
				}
				&:after {
					content: "";
				}
			}
		}
		.close {
			cursor: pointer;
			display: block;
			font-size: 32px;
			font-weight: bold;
			opacity: 0;
			position: absolute;
			top: 20px;
			transition: opacity 0.1s;
			right: 20px;
			& + .filter {
				padding-left: 12%;
			}
		}
	}
	
	.results-for{
		color: @yellow-theme;
		font-size: 20px;
		font-weight: bold;
		display: inline-block;
		line-height: 24px;
		padding: 4px 10px;
		span{
			font-size: 16px;
		}
		.gold-plate, .free-plate {
			font-size: 13px;
			line-height: 0.9em;
			vertical-align: middle;
		}
	}
}


#search-page-filters-block {
	margin-top: 0;
	padding: 4px;
	.btn {
		margin-right: 4px;
	}
	.filters-column {
		ul {
			padding: 0;
		}
		.title {
			display: none;
			&:after {
				content: '';
			}
		}
	}
	&.expanded {
		.filters-column {
			width: 50%;
			float: left;
			border: 0;
			.title {
				display: list-item;
				padding-bottom: 8px;
			}
			&:nth-child(2) {
				clear: left;
				margin-top: 5px;
				ul  {
					margin-left: 8px;
				}
			}
			&:last-child {
				float: none;
				width: auto;
			}
		}
	}
	& + .pagination{
		margin-top: 10px;
	}
}

#search-associates,
#search-associates-bottom {
	padding: 2px 0;
	strong {
		font-size: 13px;
	}
	strong,
	span {
		padding: 4px 6px;
	}
	.size-0,
	.size-1,
	.size-2 {
		display: none;
	}
	.size-3,
	.size-4 { font-size: 13px; }
	.show-more {
		display: inline;
	}
	&.mobile-expanded {
		strong {
			font-size: 13px;
		}
		.size-0,
		.size-1,
		.size-2 {
			display: inline-block;
		}
		.size-0 { font-size: 12px; }
		.size-1 { font-size: 13px; }
		.size-2 { font-size: 14px; }
		.size-3 { font-size: 15px; }
		.size-4 { font-size: 16px; }
		.show-more {
			display: none;
		}
	}
}