.banner-sheer {
	position: relative;
	margin: 5px auto 10px auto;
	height: auto;
	max-width: 728px;
	@media (max-width: @screen-xxs-max) {
		max-width: 300px;
	}
	.profile-page & {
		margin-top: -26px;
		@media (max-width: @screen-xxs-max) {
			margin-top: -15px;
		}
	}
	&:before {
		content: '';
		display: block;
		padding-top: 8.241758242%;
		@media (max-width: @screen-xxs-max) {
			padding-top: 33.333333333%;
		}
	}
	.banner-sheer__lnk,
	.banner-sheer__img {
		display: block;
		width: 100%;
		height: 100%;
	}
	.banner-sheer__lnk {
		position: absolute;
		top: 0;
		left: 0;
	}
	.banner-sheer__img {
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
	}
}
