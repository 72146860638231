.x-overlay {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	.height-100vh();
	.min-height-100vh();
	z-index: @z-index-overlay;
	.bg-rgba(102, 102, 102, 0.5);
	white-space: normal;
	
	.x-body {
		position: relative;
		height: 100%;
		max-width: 100%;
		margin: 0;
		padding: 10px 20px;
		color: #fff;
		.bg-rgba(0, 0, 0, 0.9);
		
		.x-close {
			cursor: pointer;
			position: absolute;
			top: 2px;
			right: 4px;
			font-weight: bold;
			font-size: 14px;
			text-shadow: 0 1px 0 #fff;
			
			&:hover {
				text-shadow: 0 1px 0 #666;
			}
		}
		
		a,
		.text-danger,
		.text-success {
			color: #fff;
		}
	}
	
	&.x-overlay-box {
		padding: 0 40px;
		
		.x-body {
			height: auto;
			margin: 0 auto 5px;
			padding: 20px 40px;
			font-size: 14px;
			line-height: 1.5;
		}
	}
	
	&.x-overlay-fullscreen {
		.height-100vh();
		left: 0;
		position: fixed;
		top: 0;
		width: 100%;
		
		.x-body {
			bottom: 10px;
			height: auto;
			left: 10px;
			overflow: hidden;
			padding: 25px 15px 25px 25px;
			position: absolute;
			right: 10px;
			top: 10px;
			width: auto;
			
			.x-content {
				padding-right: 10px;
				overflow-x: hidden;
				overflow-y: auto;
			}
		}
	}
	
	&.auto-width-popup,
	&.contextual-popup {
		text-align: center;
		
		.x-body {
			display: inline-block;
			text-align: left;
		}
	}
	
	&.contextual-popup {
		.x-body {
			background: fadeOut(@blue-theme-darker, 10%);
			
			.contextual-popup-message {
				font-size: 17px;
				margin-bottom: 14px;
			}
			
			.contextual-popup-actions {
				padding: 0 10px 0 20px;
				text-align: right;
				
				.btn {
					font-size: 13px;
					
					& + .btn {
						margin-left: 8px;
					}
					
					&.btn-danger {
						font-weight: bold;
						.button-variant(#fff; @blue-theme-light-border; darken(@blue-theme-light-border, 10%));
					}
					&.btn-default {
						.button-variant(#fff; transparent; @blue-theme-pastel);
					}
				}
			}
			
			form {
				color: #fff;
			}
		}
	}
	&.over-overlay {
		z-index: @z-index-overlay + 1;
	}

	.videos-i-like-container {
		position: relative;

		h3.text-center {
			font-size: 2em;
		}

		.popup-loader-container {
			position: absolute;
			top: 0;
			left: 0;
			height:100%;
			width: 100%;
			background-color:rgba(0,0,0,.9);
			display:flex;
			justify-content:center;
			align-items:center;
			font-size: 2em;
		}
	
		.popup-loader-container .icon-f.icf-spinner.icf-anim-pulse {
			font-size: 2em;
		}
	}
}

.x-overlay.overlay-subs {
	.x-body {
		max-width: 960px;
	}
}

body.x-overlay-floating-opened {
	.height-100vh();
	.max-height-100vh();
	max-width: 100%;
	overflow: hidden!important;
	position: sticky!important;
	width: 100%;
	
	.x-overlay {
		.height-100vh();
		.max-height-100vh();
		position: fixed;
		overflow: auto;
	}
}

html {
	.height-100vh();
}