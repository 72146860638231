// .xs-col-only-
.mobile-only-hide { display: none;}
.mobile-only-show { display: block;}
.mobile-only-show-inline { display: inline;}
.mobile-only-show-inline-block { display: inline-block;}
.mobile-only-show-list-item { display: list-item;}

.home {
	.pagination {
		.no-page {
			display: none;
		}
	}
}
#header {
	#site-nav {
		//text-align: center;
		.mobile-site-nav-title {
			font-size: 14px;
			padding-left: 10px;
		}
		.country-switch,
		.header-mobile-link,
		#main-cat-switcher-mobile {
			margin-left: 4px;
		}
	}
}

#warning-survey {
	font-size: 15px;
	padding: 5px;
	line-height: 1.3;
}

#content {
	.infobar,
	.clear-infobar {
		font-size: 11px;
	}
	.clear-infobar{
		.infobar-title,
		.infobar-list {
			font-size: 16px;
		}
		.home & .pull-right {
			line-height: 22px;
		}
	}
}

#tags li {
	width: 50%;
}

#video-playlist #playlist-content .mozaique .thumb-block {
	width: 50%;
}

.mozaique {
	
	.thumb-block {
		.thumb-block-size-mixin(1);
	}
	
	&.cust-nb-cols {
		html:not(.nb-thumbs-cols-xs-2):not(.nb-thumbs-cols-xs-3) & {
			padding-right: 15px;
		}
		
		.thumb-block {
			.nb-thumbs-cols-xs-2 & {
				.thumb-block-size-mixin(2);
			}
			
			.nb-thumbs-cols-xs-3 & {
				.thumb-block-size-mixin(3);
			}
			
			html:not(.nb-thumbs-cols-xs-2):not(.nb-thumbs-cols-xs-3) & {
				padding-left: 15px;
				padding-bottom: 15px;
				
				.thumb-under {
					min-height: 0;
				}
				
				.thumb-inside + .thumb-under {
					margin-bottom: 28px;
				}
			}
			
			.nb-thumbs-cols-xs-2 &,
			.nb-thumbs-cols-xs-3 & {
				.superfluous {
					display: none;
				}
				
				.thumb-under {
					p {
						&.metadata {
							font-size: 1em;
						}
					}
				}
			}
		}
	}
	
	&.thumbs-small {
		.thumb-block {
			.thumb-block-size-mixin(3);
		}
	}
}

#related-videos {
	.mozaique {
		padding-left: 7px;
		
		.thumb-block {
			.thumb-block-size-mixin(2);
			
			padding-bottom: 0;
			padding-left: 7px;
			
			.superfluous {
				display: none;
			}
		}
	}
	.thumb-block {
		&.after-12,
		&.after-15 {
			display: none;
		}
	}
	&.with-ad {
		&.with-footer-ad {
			.thumb-block:not(.thumb-nat-exo-ad) {
				.thumb-under {
					min-height: 0;
					padding-bottom: 4px;
				}
			}
			.thumb-block {
				&.with-add-nth-child-2-plus-1{
					clear: left;
				}
				&.video-ad {
					width: 100%;
					margin: 20px -5px 30px 0;
					padding-bottom: 0;
					
					.thumb-inside {
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
		}
	}
}

.video-page {
	#video-content-metadata {
		&.reduced-size {
			.clear-infobar {
				font-size: 14px;
				line-height: 18px;
			}
		}
		.infobar,
		.clear-infobar {
			font-size: 18px;
		}
		.video-metadata {
			.metadata-btn {
				padding: ceil(@content-padding / 2);
				margin: 0 ceil(@content-padding / 4) @content-padding 0;
				strong {
					font-size: 13px;
				}
				.value {
					padding: 1px 0 0;
					font-size: 12px;
				}
			}
		}
	}
}

#ad-footer2 {
	display: block;
}

.tab-form {
	.create-account,
	.btn {
		font-size: 13px;
	}
}

#listing-page-filters-block {
	button.filters-opener {
		margin-bottom: 0;
	}
}

#content {
	#content-thumbs {
		#results-top {
			flex-direction: column;
			
			h1 {
				margin: 8px 0 0;
				order: 1;
			}
		}
		
		.mobile-flex-space {
			flex-grow: 0;
		}
		
		.infobar-title {
			& + .simple-dropdown {
				clear: left;
				display: block;
				float: left;
				margin: 10px 0 0 0;
				padding-left: 0;
			}
		}
		
		.tour-pushs {
			grid-template-columns: repeat(2, minmax(0, 1fr));
			.inter {
				padding: 15px 5px;
				h3 {
					margin: 10px 5px;
				}
			}
			.inter:nth-child(5) {
				display: none;
			}
			.cta {
				grid-column-end: 3;
		  	}
		}
	}
	
	.simple-dropdown {
		//display: block;
		//width: 100%;
		
		/*& +	.simple-dropdown {
			padding-left: @content-padding;
		}*/
	}
}

#results-free-or-premium {
	display: block;
	text-align: center;
}


#profile-search-form {
	&.display-compact {
		.form-group {
			display: block;
			clear: both;
			width: auto;
			margin-left: -15px;
			margin-right: -15px;
		}
	}
}

.profile-page {
	#content {
		.infobar {
			font-size: 14px;
			.sex-age-country {
				display: block;
				padding: 0;
			}
		}
	}
	#profile-info {
		#profile-info-title {
			#results-free-or-premium {
				margin: 0 5px;
			}
			.sub-strip {
				margin: 5px 0;
			}
		}
		.views {
			display: block;
			margin-top : 5px;
		}
		.followers {
			margin-top : 5px;
			margin-right: 5px;
		}
		.user-actions {
			display: inline-block;
			position: relative;
			top: 2px;
			right: auto;
			.user-subscribe,
			.user-reject {
				display: inline-block;
				height: auto;
				padding: 4px 6px;
				& + span {
					margin-left: 2px;
				}
				&.active {
					.sub-state-text {
						display: inline-block;
					}
				}
				.sub-state-text {
					display: none;
					margin-left: 3px;
				}
			}
			.loader {
				display: none;
			}
		}
	}
}

.x-overlay.x-overlay-box {
	padding: 0 5px;
}

.video-page {
	.video-user-suggest-overlay {
		.x-body {
			h1 {
				font-size: 16px;
				margin-top: 10px;
			}
			h2 {
				font-size: 16px;
				margin-bottom: 6px;
			}
			h3 {
				font-size: 13px;
				margin-bottom: 12px;
			}
			form{
				.input-lang-group {
					.input-lang {
						padding: 0 4px;
					}
					.icon-switch{
						span:first-child {
							display: none;
						}
						span + span {
							display: inline-block;
						}
					}
				}
			}
		}
	}
	
	.video-page-lang-switcher-popup {
		.video-page-lang-sel {
			max-width: 200px;
			li {
				width: 100%;
			}
		}
	}
}


#multi-footer-link{
	font-size: 14px;
}

.copy-link {
	.copy-btn{
		border-radius: 6px ;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
		width: 100%;
	}
	input{
		border-left-width: 1px;
		border-top-width: 0;
		border-radius: 6px;
		border-top-right-radius: 0;
		border-top-left-radius: 0;
		width: 100%;
	}
}

.eventslist {
	.mozaique {
		&.cust-nb-cols {
			html:not(.nb-thumbs-cols-xs-2):not(.nb-thumbs-cols-xs-3) & {
				.show-more-less-vids {
					padding-bottom: 0;
					
					.thumb-inside {
						padding-bottom: 0;
						height: auto;
					}
					
					.show-more-less-btns {
						position: relative;
					}
					
					a {
						border: 1px solid @btn-default-border;
						margin: 5px 0;
						padding: 5px;
						text-align: center;
					}
				}
			}
			
			html.nb-thumbs-cols-xs-2 &,
			html.nb-thumbs-cols-xs-3 & {
				.show-more-less-vids {
					.show-more-incremental {
						padding-bottom: 0;
						height: auto;
						
						.show-more-less-btns {
							position: relative;
						}
						
						a {
							border: 1px solid @btn-default-border;
							margin: 5px 0;
							padding: 5px 0;
							text-align: center;
						}
						
						.show-more-less-long {
							display: none;
						}
						
						.show-more-less-short {
							display: inline;
						}
						
						.no-more-to-load {
							&, & + .less {
								.show-more-less-long {
									display: inline;
								}
								
								.show-more-less-short {
									display: none;
								}
							}
						}
					}
					
					.show-less {
						.show-more-incremental {
							.show-more-less-long {
								display: inline;
							}
							
							.show-more-less-short {
								display: none;
							}
						}
					}
				}
			}
		}
	}
	
	@media @media-ratio-vertical {
		.show-less {
			.thumb-block {
				&.thumb-block-10-more {
					display: none;
				}
			}
			&.has-less-21 {
				.show-more-less-vids {
					display: block;
				}
			}
		}
	}
}


body#red-page {
	
	.main-slider__item--img-1 {
		.main-slider__layer {
			h1 {
				img {
					display: block;
					height: 70px;
					margin: 0 auto 14px;
				}
			}
		}
	}
}


#page.premium_feed {
	.sub-suggests {
		.thumb-block-premium-feed-account {
			&.thumb-block-premium-feed-other-suggest {
				.cat {
					display: none;
				}
			}
		}
	}
}


#video-premium-alert {
	p {
		.btn-primary {
			font-size: 20px;
		}
	}
}