
@import "modules/ads";
@import "modules/mozaique.less";
@import "modules/search-tags.less";
@import "modules/video.less";
@import "modules/upload_simple.less";
@import "modules/history.less";
@import "modules/profiles.less";
@import "modules/profile_page.less";
@import "modules/premium";
@import "modules/premium_feed";
@import "modules/user_metadata_suggest";

.http-error-page {
	#content {
		padding: 50px 20px;
	}
	h1, h2, h3, h4 {
		margin-left: 0;
		text-align: center;
	}
}

.tab-form {
	background: @blue-theme;
	padding: @content-padding;
	text-align: left;
	font-size: 14px;
	.form-group {
		margin-bottom: @content-padding;
	}
	.left-col {
		border-right: 2px solid @gray-lighter;
	}
	.footer-note {
		margin: 10px 0 0;
		font-weight: bold;
		font-style: italic;
		font-size: 14px;
	}
	label.control-label {
		text-align: left;
		color: #fff;
	}
	.btn {
		margin: 0;
		font-size: 15px;
	}
	.forgot-password {
		float: left;
		margin-top: 15px;
	}
	ul {
		padding-left: 10px;
	}
	&:last-child {
		margin-top: 15px;
		margin-bottom: 0;
	}
}

#switch-redirect-alert {
	max-width: 610px;
	overflow: hidden;
	text-align: center;
	
	p {
		text-transform: uppercase;
		font-size: 26px;
		font-weight: bold;
		margin: 0 auto;
		padding: 10px 0;
	}
	
	.logo {
		inline-display: block;
		max-width: 200px;
		margin: 0 auto;
	}
	
	.gold-plate {
		font-size: 55px;
		vertical-align: middle;
	}
	
	.btn-primary {
		text-transform: uppercase;
		font-size: 26px;
		font-weight: 700;
		white-space: normal;
	}
	
}
