
.profile-page {
	/*.infobar {
		.sex-age-country {
			padding-left: 30px;
		}
	}
	#aboutme {
		font-style: italic;
		font-size: 12px;
		padding: 5px 2px;
	}*/
	.pagination li.sort-filters-li .x-dropdown{
		min-height: 28px;
		min-width: 150px;
		padding-bottom: 1px;
		padding-top: 1px;
	}
	
	#profile-info {
		position: relative;
		margin: 14px 7px 8px;
		font-size: 14px;
		line-height: 1.4;
		padding: 0 5px;
		
		#profile-info-title {
			font-size: 20px;
			line-height: 20px;
			margin: 0;
			padding: 8px 0;
			
			.profile-username {
				color: @yellow-theme;
				font-size: 28px;
				font-weight: bold;
				line-height: 20px;
			}
			
			+ p {
			}
		}
		
		.profile-info-views {
			position: relative;
		}
		
		p {
			overflow: hidden;
			margin: 0;
			font-size: 16px;
		}
		
		#pinfo-videos-views {
			strong {
				font-weight: bold;
			}
		}
		
		/*#pinfo-channel-ranks,
		#pinfo-pornstar-ranks{
			strong {
				&.first-100{
					color: #87b4ff;
					font-weight: bold;
				}
				&.world-first-1000{
					color: @yellow-theme;
					font-weight: bold;
				}
			}
		}
		#pinfo-channel-ranks,
		#pinfo-pornstar-ranks,
		#pinfo-video-tags {
			margin: 5px 0;
			br + span {
				padding-left: 10px;
			}
		}
		#pinfo-workedfor a {
			font-weight: bold;
		}*/
		
		#results-free-or-premium {
			.init-ok-btn();
			display: inline-block;
			vertical-align: middle;
		}
		
		.views,
		.followers{
			display: inline-block;
			margin-right: 10px;
			font-size: 1.2em;
			font-weight: bold;
			line-height: 20px;
			color: @blue-theme-light-sub-title;
		}
		
		.views {
			margin-right: 10px;
		}
		
		.user-actions {
			position: absolute;
			top: -3px;
			right: 0;
			padding: 0;
			font-size: 0;
			background: @blue-theme-darker;
			background: linear-gradient(0deg, @blue-theme 0%,  @blue-theme-darker 33%,  @blue-theme-darker 66%, @blue-theme 100%);
			
			& > span {
				display: inline-block;
				height: 25px;
				padding: 5px 6px;
				font-size: 14px;
				line-height: 1;
				vertical-align: middle;
				background: transparent;
				cursor: pointer;
				
				.notouch &:hover, .notouch &:active {
					background: rgba(255, 255, 255, 0.2);
				}
				
				.icon-f {
					opacity: 0.8;
				}
				
				.sub-state-text {
					margin-left: 5px;
					margin-right: 5px;
				}
				
				.loader {
					margin-left: 5px;
				}
				
				&.wait {
					cursor: wait;
				}
				
				&.active {
					.icon-f {
						opacity: 1;
					}
					.sub-state-text {
						font-weight: bold;
					}
				}
				&.no-active {
					display: none;
				}
			}
			
			.user-reject {
				.icon-f {
					opacity: 0.3;
				}
			}
		}
		
		.row {
			margin: 0 -5px;
		}
		
		.pagination {
			text-align: left;
		}
		
		#gold-videos,
		#psvideos {
			.pagination {
				.no-page {
					border: 0;
					font-weight: bold;
					text-decoration: underline;
					
					&:hover {
						background: #004be8;
					}
				}
			}
			
			> div.text-center,
			> p.text-center {
				font-size: 16px;
				margin: 50px 10px;
			}
		}
		
		#switch-to-free-results,
		#switch-to-gold-results {
			padding-left: 8px;
			padding-right: 8px;
		}
	}
	
	.mozaique {
		position: relative;
		
		.load {
			clear: both;
			display: block;
			padding: 60px 20px;
			text-align: center;
			width: 100%;
			
			.icf-spinner {
				color: fadeOut(white, 50%);
				display: inline-block;
				font-size: 60px;
			}
			
			&:not(:first-child) {
				background: fadeOut(@body-bg, 40%);
				bottom: 0;
				position: absolute;
				top: 0;
			}
			
			&:last-child {
				margin-bottom: 20px;
			}
		}
	}

	.simple-dropdown-pop {
		.niv2 {
			ul {
				a{
					&.uploads-only {
						white-space: nowrap;
						
						.toggle {
							display: inline-block;
							margin: 0 5px 0 0;
							vertical-align: middle;
						}
					}
				}
			}
		}
	}

	.no-content {
		display: flex;
		align-items: center;
		gap: 15px;

		span {
			letter-spacing: 0;
		}
	}
}

.profile-page, #content-thumbs.search-content-thumbs {
	button.content-filter, a.content-filter {
		background: @blue-theme;
		border: 1px solid #004be8;
		display: flex;
		align-items: center;
		gap: 2px;
		margin: 2px;

		.checkbox {
			margin: 2px;
		}
	}
}

.floating-purchase-btn {
	bottom: 15px;
	box-shadow: 0 2px 5px fadeout(#000, 50%);
	left: 50%;
	letter-spacing: 0;
	max-width: 300px;
	position: fixed;
	transform: translateX(-50%);
	transition: bottom 0.25s;
	white-space: normal;
	width: 90%;
	z-index: @zindex-profile-sub-btn;
	
	&.is-hidden {
		bottom: -40px;
	}
	
	.gold-plate {
		border: 0;
		font-size: 18px;
		width: 100%;
	}
}

#switch-to-free-results,
#switch-to-gold-results {
	border-width: 0 0 2px 0;
	font-size: 16px;
	margin: 20px;
}

.dropdown-filters-pop {
	
	.x-popup-content {
		padding: 8px 0;
		
		.main-cat-switch-link,
		button {
			background: none;
			border: 0;
			display: block;
			font-size: 14px;
			line-height: 20px;
			padding: 8px 14px;
			width: 100%;
			text-align: left;
			
			&.current,
			&.is-selected {
				font-weight: bold;
				color: @yellow-theme;
			}
			
			.checkbox {
				display: inline-block;
				margin: 0 4px 0 0;
				vertical-align: top;
			}
			
			.label {
				display: inline-block;
				vertical-align: top;
			}
			
			&, &:hover {
				text-decoration: none;
			}
			/*&.is-selected {
				display: none;
			}*/
		}
		
		.main-cat-switch-link-first {
			border-top: 1px solid fadeOut(white, 80%);
			margin-top: 4px;
			padding-top: 12px;
		}
		
		.main-cat-switch-link {
			& > span:first-child {
				color: @text-color;
				display: inline-block;
				font-size: 20px;
				height: 20px;
				line-height: 20px;
				text-align: center;
				vertical-align: top;
				width: 26px;
			}
		}
	}
}

#content .pagination {
	margin-top: 10px;
}
#gold-videos {
	position: relative;
}

#content-thumbs.search-content-thumbs, .profile-page {
	.content-filters {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 5px;

		@media(max-width: @screen-sm-max) {
			margin: 10px 0;
		}
		@media(min-width: @screen-sm-min) {
			margin: 18px 0;
		}

		button.content-filter.radio-toggle-tab, a.content-filter.radio-toggle-tab {
			padding-bottom: 7px;
			color: inherit;
    		text-decoration: none;
			border: none;
			border-bottom: 1px solid blue;
			&.active {
				border-bottom: 2px solid rgb(81, 81, 255);
			}
		
			font-size: 110%;
		
			opacity: 0.75;
			&.active,
			&:hover {
				opacity: 1;
			}
		
			.label {
				display: flex;
				align-items: center;
				gap: 4px;
				.count {
					background-color: #4a4adf;
					border-radius: 5px;
					padding: 3px;
					font-size: 75%;
				}
			}
		}
	}
} 