@import "../../../common/premium.less";


.bg_img_slide(@num, @main_cat, @ethnic) {
	.bg_img_folder("xnxx/gold/tour-bg/@{main_cat}/@{ethnic}/slide-@{num}.jpg");
}
.gold_gradient() {
}

.gold-plate,
.free-plate {
	background-color: @gold-theme;
	background-image: linear-gradient(to bottom, @gold-plate-bg-1 0%, @gold-plate-bg-2 45%, @gold-plate-bg-3 55%, @gold-plate-bg-4 100%);
	border-bottom: 2px solid @gold-plate-border;
	color: @gold-plate-color;
	font-weight: bold;
	overflow: hidden;
	padding: .16em;
	position: relative;
	.text-border-1px(rgba(255, 255, 255, 0.5));
	
	&.link {
		cursor: pointer;
	}
}

a.gold-plate:hover,
.link.gold-plate:hover,
a.free-plate:hover,
.link.free-plate:hover,
.plate-shine {
	&:after {
		animation: plate_shine 3s ease-in-out;
		animation-fill-mode: forwards;
		background: rgba(255, 255, 255, 0.13);
		background: linear-gradient(
			-50deg,
			rgba(255, 255, 255, 0.13) 0%,
			rgba(255, 255, 255, 0.23) 60%,
			rgba(255, 255, 255, .8) 65%,
			rgba(255, 255, 255, 0) 82%,
			rgba(255, 255, 255, 0) 100%
		);
		content: " ";
		height: 120%;
		left: -140%;
		opacity: 0;
		position: absolute;
		top: -10%;
		width: 200%;
	}
}

a.gold-plate,
.link.gold-plate,
a.free-plate,
.link.free-plate,
* {
	&.plate-shine {
		&:after {
			animation: plate_shine 3s .5s ease-in-out;
		}
	}
}

a.gold-plate,
.link.gold-plate,
a.free-plate,
.link.free-plate,
*.plate-shine {
	display: inline-block;
	overflow: hidden;
	position: relative;
}

@keyframes plate_shine {
	0% {
		left: -200%;
		opacity: 0;
	}
	10% {
		opacity: 1;
	}
	20% {
		left: 110%;
		opacity: 0;
		transition-duration: 0.7s, 0.7s, 0.15s;
		transition-property: left, top, opacity;
		transition-timing-function: ease;
	}
	100% {
		left: 110%;
		opacity: 0;
		transition-property: left, top, opacity;
	}
}

.gold-plate {
	&.big-plate {
		border: 0.04em solid @gold-plate-border;
		border-radius: 0.20em;
		padding: 0 .16em;
		.text-border-2px(rgba(255, 255, 255, 0.4));
	}
}
.free-plate {
	background-color: @gold-theme;
	background-image: linear-gradient(to bottom, @free-plate-bg-1 0%, @free-plate-bg-2 45%, @free-plate-bg-3 55%, @free-plate-bg-4 100%);
	border-color: @free-plate-border;
	color: @free-plate-color;
}
a, .link {
	&.gold-plate:hover {
		text-decoration: none;
		background-image: linear-gradient(to bottom, @gold-plate-bg-3 0, @gold-plate-bg-2 20%, @gold-plate-bg-1 40%, @gold-plate-bg-2 60%, @gold-plate-bg-3 90%, @gold-plate-bg-4 100%);
	}
	&.free-plate:hover {
		text-decoration: none;
		background-image: linear-gradient(to bottom, @free-plate-bg-3 0, @free-plate-bg-2 20%, @free-plate-bg-1 40%, @free-plate-bg-2 60%, @free-plate-bg-3 90%, @free-plate-bg-4 100%);
	}
}
.gold-plate,
.free-plate {
	&:hover,
	&:active,
	&:focus {
		color: initial;
		text-decoration: initial;
	}
}

body#red-page {
	background-color: #000048;
	a {
		&:hover {
			color: @gold-theme;
		}
	}
	
	each(@tour_bg_sections, .(@section) {
		.main-slider__item--img-@{section} {
			.slider-bg {
				.bg_img_slide(@section, @tour_bg_main_cat_default, @tour_bg_ethnic_default);
			}
		}
	});
	each(@tour_bg_main_cats, .(@main_cat) {
		&.bg-@{main_cat} {
			each(@tour_bg_ethnics, .(@ethnic) {
				&.bg-@{ethnic} {
					each(@tour_bg_sections, .(@section) {
						.main-slider__item--img-@{section} {
							.slider-bg {
								.bg_img_slide(@section, @main_cat, @ethnic);
							}
						}
					});
				}
			})
		}
	});
	
	.btn-primary {
		.btn-custom(#fff, #000060, #00007d, #000064, #000064, #00004b, rgba(0, 0, 144, 0.5));
	}
	
	.btn-outline-secondary {
		.btn-outline(#fff, rgba(255, 255, 255, 0.3), rgba(64, 64, 64, 0.5));
	}
	
	.btn-outline-success {
		.btn-outline(#fff, #00c851, rgba(0, 200, 81, 0.5));
	}
	
	#header {
		background-color: rgba(0, 0, 72, 0.55);
		
		&.pinned {
			background-color: rgba(0, 0, 60, 0.95);
		}
		
		#header-logo {
			img {
				height: 80px;
				padding: 15px 0;
			}
		}
	}
	
	.main-slider__item--img-1 {
		.main-slider__layer {
			&:before {
				background-color: rgba(0, 0, 40, 0.4);
			}
			
			h1 {
				* {
					display: inline-block;
					vertical-align: middle;
				}
				span {
					display: inline-block;
					font-size: 120px;
					margin: 0;
				}
				img {
					height: 130px;
				}
			}
			
			.inter-wrap {
				background-color: #000015;
				background-color: rgba(0, 0, 40, .4);
			}
		}
	}
	
	a.learn-more {
		&:hover {
			color: #fbd64e;
		}
	}
	
	.section-content--gradient-overlay-left-black,
	.section-content--gradient-overlay-right {
		&:before {
			background-image: linear-gradient(
				to left,
				transparent 0%,
				rgba(0, 0, 16, 0.28) 16%,
				rgba(0, 0, 16, 0.53) 25%,
				rgba(0, 0, 16, 0.73) 35%,
				rgba(0, 0, 16, 0.85) 45%,
				rgba(0, 0, 16, 0.96) 78%,
				#000010 80%);
		}
	}
	
	.section-content--gradient-overlay-right {
		&:before {
			background-image: linear-gradient(to right,
			transparent 0%,
			rgba(0, 0, 16, 0.28) 5%,
			//rgba(0, 0, 16, 0.33) 10%,
			//rgba(0, 0, 16, 0.43) 20%,
			//rgba(0, 0, 16, 0.63) 23%);
			rgba(0, 0, 16, 0.73) 60%);
		}
	}
	
	.section-bottom {
		background: @blue-theme-darker2;
		.tb-pan {
			h3 {
				background: desaturate(lighten(@blue-theme-darker2, 5%), 40%);
			}
		}
	}
	
	.contextual-popup-actions {
		.btn {
			&.btn-danger {
				.button-variant(#fff; @blue-theme-light-border; darken(@blue-theme-light-border, 10%));
			}
			&.btn-default {
				.button-variant(#fff; transparent; @blue-theme-pastel);
			}
		}
	}
}

.x-overlay {
	&.premium-popup-form {
		.x-body {
			form {
				input[type="text"],
				input[type="email"],
				input[type="tel"],
				input[type="password"],
				select {
					&::selection {
						background: @blue-theme;
						color: #fff;
					}
				}
				.alert {
					
					&.alert-danger a {
						color: @gold-theme;
					}
				}
				.text-danger {
					color: @red-color;
				}
			}
			
			#premium-popup-form {
				h2 span,
				h3 span {
					color: @gold-theme;
				}
			}
			
			#premium-modes {
				.mode {
					background: @gold-theme;
				}
			}
			
			.btn-danger {
				.button-variant(@btn-danger-color; @blue-theme-dark; @blue-theme-darker);
			}
		}
	}
}

.premium-page {
	font-size: 1rem;
	min-height: 100%;
	#header {
		.topbar {
			background: url('../../../v3/img/skins/xnxx/top-stripe2.png') fade(@blue-theme-dark, 90%);
			.logo-xnxx {
				a {
					&:hover {
						opacity: 1;
						filter: brightness(1.2);
					}
				}
			}
		}
		#site-nav {
			ul {
				li {
					&.hide-if-premium {
						display: none;
					}
					&.hide-if-not-premium {
						display: inline;
					}
				}
			}
		}
	}
	
	.highlight {
		color: @gold-theme;
	}
	
	#content {
		.videos-by-profile {
			.activity-event {
				.clear-infobar {
					.tag {
						color: @gold-theme;
					}
				}
			}
		}
	}
}

