@import "./../../../common/form/models-finder";
.video-page {
	.wrapper {
		max-width: @video-page-width;
		margin: 0 auto;
		box-shadow: 0 0 100px -1px rgba(0, 0, 0, 0.9);
		overflow: hidden;
	}
	#video-content {
		margin: 1px 0 0;
	}
	#video-right {
		float: right;
		width: 300px;
		min-height: 1px;
		position: relative;
		text-align: center;
		margin-left: 16px;
	}
	.live_model_thumb {
		position: relative;

		img {
			display: block;
			width: 100%;
		}
		
		&:hover {
			img {
				box-shadow: 0 0 0 2px #fff;
			}
		}

		video {
			height: 100%;
			top: 0;
			left: 0;
			object-fit: cover;	
			position: absolute;
			width: 100%;		
		}
	}
	#video-player-bg {
		overflow: hidden;
		background: #000;
		#html5video.embed-responsive,
		#player {
			overflow: hidden;
			text-align: center;
			color: #000;
			line-height: 0;
			font-size: 18px;
			font-weight: bold;
			height: 516px;
			padding-bottom: 0;
			embed,
			.embed-responsive-item {
				background-repeat: no-repeat;
				background-position: center center;
				background-size: 100% auto;
				height: 516px;
				position: static;
			}
			&.with-thumb-cover {
				embed,
				.embed-responsive-item {
					filter: blur(3px) brightness(0.5);
				}
			}
		}
		h2 {
			text-align: center;
			color: @text-color;
		}
	}
	.player-enlarged {
		#video-content {
			background: #000;
			.wrapper {
				box-shadow: none;
			}
			#video-right {
				display: none;
			}
		}
		#video-player-bg {
			#html5video.embed-responsive,
			#player {
				height: auto;
				padding-bottom: 40%;
				@media (max-aspect-ratio: ~"1280/680") {
					padding-bottom: 45%;
				}
				@media (max-aspect-ratio: ~"1280/760") {
					padding-bottom: 50%;
				}
				@media (max-aspect-ratio: ~"1280/840") {
					padding-bottom: 56.25%;
				}
				@media (max-aspect-ratio: ~"1280/960") {
					padding-bottom: 62.5%;
				}
				embed,
				.embed-responsive-item {
					height: 100%;
					position: absolute;
				}
			}
		}
	}
	#html5video {
		&,
		* {
			box-sizing: content-box;
		}
		.video-title {
			.gold-plate {
				padding-left: 4px;
				padding-right: 4px;
			}
		}
		.video-gold {
			background: transparent url('/v3/img/skins/xnxx/gold/thumb-cover-720.png') 0 no-repeat;
			background-size: auto 100%;
			background-size: cover;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}
	--plyr-autoplay-act-bg: @blue-seekbar-color;
	--plyr-autoplay-act-color: fadeOut(#fff, 10%);
	--plyr-checkbox-bg: @blue-seekbar-color;
	--plyr-volume-active: @blue-seekbar-color;
	
	.video-subscribe {
		display: inline-block;
		border: 1px solid #ddd;
		background: #f00;
		font-weight: bold;
		color: @text-color;
		text-decoration: none;
		font-size: 13px;
		line-height: 15px;
		.sub-btn {
			display: inline-block;
			background: @text-color;
			color: #f00;
			padding: 0 3px 0 4px;
			margin: 2px;
			line-height: 13px;
			border-radius: 1px;
			.icon {
				margin: 1px 0px;
				vertical-align: bottom;
			}
		}
		.nb-subscribers {
			display: inline-block;
			background: @text-color;
			color: #000;
			padding: 1px 2px;
			font-weight: normal;
			margin-left: 1px;
		}
		a {
			color: @text-color;
		}
		&:hover {
			& {
				background: @theme-color;
			}
			.sub-btn {
				color: @theme-color;
			}
		}
	}
	#suggestions_tags_list {
		list-style-position: inside;
		list-style-type: none;
		margin: 10px 0;
		padding: 0;
	}
	.video-tags, #suggestions_tags_list, #suggestions_removed_tags_list {
		a.added {
			font-style: italic;
		}
		a.is-pornstar:before {
			.font-family-iconfont;
			color: @text-color;
			content: @icf-star-full;
			font-size: 22px;
			left: -11px;
			line-height: 22px;
			position: absolute;
			text-shadow: 0 0 4px @body-bg;
			top: -2px;
			width: 22px;
		}
		a {
			background: @blue-theme-light-sub-title;
			color: @text-color;
			display: inline-block;
			margin: 2px 4px 2px 0;
			text-decoration: none;
			padding: 2px 4px;
			border: 0;
			&.is-pornstar {
				border-radius: 4px 0 0 4px;
				margin-left: 10px;
				padding-left: 14px;
				position: relative;
				&:hover {
					background: @text-color;
					&:before {
						color: @gold-theme;
					}
				}
			}
			&:hover {
				background: @text-color !important;
				border-color: @text-color !important;
				color: @blue-theme-dark !important;
				text-decoration: none;
			}
		}
	}
	#suggestion {
		color: @darker-base-text;
		span {
			margin: 0 !important;
			padding: 0 !important;
		}
	}
	#video-sponsor-links {
		margin-left: auto;
		text-align: center;
		font-size: 14px;
		font-weight: bold;
		padding: @content-padding;
		background: #000;
		color: @text-color;
		height: fit-content;
		a {
			color: @text-color;
			text-decoration: underline;
		}
		.channel-desc {
			font-size: 13px;
			font-weight: normal;
		}

		&.sheer-sponsor-link {
			font-style: normal;
			.sponsor-text {
				padding: 2px;
				.sheer-bg {
					display: inline;
					// background: @video-sponsor-sheer-bg;
					padding: 6px;
				}
	
				strong {
					text-transform: uppercase;
				}
			}
		}
	}
	#video-content-metadata {
		clear: both;
		overflow: hidden;
		.metadata-row {
			margin: 0 @content-padding;
			overflow: hidden;
			zoom: 1;
			clear: both;
			list-style-type: none;
			list-style-position: inside;
			a {
				display: inline-block;
			}
		}
		.infobar,
		.clear-infobar {
			display: flex;
			justify-content: flex-start;
			font-size: 18px;
			padding: 7px 7px 5px;
			strong {
				box-sizing: content-box;
				display: inline;
				line-height: 20px;
				//padding: 7px 0;
				word-break: break-word;
				.icf-refresh {
					color: @blue-theme-pastel;
					cursor: pointer;
					display: inline-block;
					font-size: 80%;
					line-height: inherit;
					margin-right: 4px;
					vertical-align: middle;
				}
			}
			.rename-space {
				font-size: 15px;
			}
			#video-rename {
				background: none;
				border: 0;
				color: @text-color;
				font-style: normal;
				padding: 0;
				vertical-align: middle;
				.loader{
					display: none;
				}
				&:hover {
					transform: scale(1.1);
				}
				&:active, &:focus {
					outline: none;
				}
				&.loading {
					span {
						display: none;
					}
					.loader {
						display: inline;
					}
				}
			}
			
			.video-title-container {
				display: flex;
				vertical-align: middle;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;

				.video-title {
					display: flex;
					align-items: center;
					flex-grow: 0;
					justify-content: space-between;

					#video-rename {
						margin-left: 9px;
					}
				}
				#video-sponsor-links {
					margin: 0;
					flex-grow: 0.9;
					padding: 2px;
				}
			}
		}
		.clear-infobar {
			.metadata {
				color: @blue-theme-light-sub-title;
				display: block;
				font-size: 15px;
				line-height: 22px;
				padding-top: 4px;
				
				.icon-f {
					font-size: 13px;
					margin-left: 3px;
					
					&:first-child {
						margin-left: 0;
					}
				}
				
				.gold-plate,
				.free-plate {
					float: left;
					margin-right: 6px;
					padding: 0 0.32em;
				}
			}
		}
		.video-description {
			margin-bottom: @content-padding;
			font-size: 13px;
			font-style: italic;
		}
		.video-metadata {
			float: left;
			clear: left;
			overflow: visible;
			[dir="rtl"] & {
				float: right;
			}
		}
		.metadata-btn {
			background: @blue-theme-light;
			color: @text-color;
			cursor: default;
			display: inline-block;
			font-size: 16px;
			line-height: 1;
			margin: 0 @content-padding @content-padding 0;
			padding: @content-padding;
			text-decoration: none;
			.transition;
			user-select: none;
			
			.icon {
				margin-top: 1px;
				vertical-align: text-top;
			}
			
			.value {
				padding-left: @content-padding;
			}
			
			&.metadata-btn-nobg {
				background: transparent;
				padding-left: @content-padding/2;
				padding-right: @content-padding/2;
			}
			
			&.hide-if-zero-0 {
				.value {
					display: none;
				}
			}
			
			strong {
				vertical-align: text-top;
			}
		}
		
		a.metadata-btn {
			cursor: pointer;
			&:hover,
			&.disabled,
			&.active {
				background: @blue-theme-pastel;
				color: @text-color;
				text-decoration: none;
			}
			&.disabled,
			&.active {
				cursor: default;
			}
		}
		#video-votes,
		.tab-buttons {
			display: inline-block;
		}
		.video-tags {
			.suggestion {
				background: none;
			}
			clear: right;
			[dir="rtl"] & {
				clear: left;
			}
		}

		.tabs {
			margin: @content-padding;
			overflow: initial;
			.tab {
				display: none;
				background: @blue-theme-dark;
				padding: (2 * @content-padding);
				overflow: hidden;
				zoom: 1;
				&#tabComments {
					//background: none;
					overflow: initial!important;
				}
				&#tabReport {
					.tab-form label.control-label {
						padding-top: 7px;
					}
					
					.radio-inline,
					.radio-inline + .radio-inline {
						padding-top: 7px;
						margin: 0 10px 0 0;
						label {
							font-weight: bold;
						}
					}
					.btn {
						margin: 8px 0;
						white-space: normal;
					}
				}
				h4 {
					margin: 10px 0 @content-padding;
					padding: @content-padding;
					font-variant: small-caps;
					background: @blue-theme;
					&.top{
						margin-top: 0;
					}
					
					a {
						cursor: pointer;
					}
				}
				.text-danger,
				.text-success {
					color: @text-color;
				}
			}
		}
		.form-control {
			background: @text-color;
			color: @blue-theme-darker;
			border: 0;
			padding: 7px 12px;
			&::selection {
				background: @blue-theme;
				color: @text-color;
			}
		}
		.radio,
		.radio-inline,
		.checkbox,
		.checkbox-inline {
			label {
				color: @text-color;
			}
		}
		h3 {
			margin: @content-padding;
			font-variant: small-caps;
			font-size: 16px;
		}
	}
	
	.video-user-suggest-overlay {
		color: white;
		.x-body {
			max-width: 820px;
			padding: 10px;
			h1 {
				font-size: 24px;
				margin-bottom: 10px;
				text-align: center;
			}
			h2 {
				font-size: 24px;
				margin-top: 0;
				margin-bottom: 15px;
				text-align: center;
				color: @blue-theme-light-border;
			}
			h3 {
				font-size: 16px;
				margin-bottom: 20px;
				text-align: center;
			}
			form {
				.input-lang-group {
					margin-bottom: 6px;
					.input-group-addon {
						height: 32px;
						line-height: 32px;
						padding: 0 8px;
						&.input-lang {
							background: @blue-theme;
							color: @text-color;
							font-size: 11px;
							font-weight: bold;
							padding: 0 6px;
							overflow: hidden;
							text-overflow: ellipsis;
							.flag {
								display: block;
								margin: 0 auto;
							}
						}
						&.lang-save {
							background: @blue-theme-light;
							color: white;
							cursor: pointer;
							position: relative;
							span {
								vertical-align: middle;
								& + span {
									display: none;
								}
							}
							em {
								display: none;
								height: 32px;
								left: 0;
								line-height: 32px;
								position: absolute;
								text-align: center;
								top: 0;
								width: 100%;
							}
							&.saving {
								span {
									visibility: hidden;
								}
								em {
									display: block;
								}
							}
							&.saved,
							&.not_savable {
								background: @gray-light;
								color: @gray-light2;
								cursor: default;
							}
						}
					}
					input[type=text] {
						height: 34px;
						line-height: 20px;
					}
					textarea {
						resize: vertical;
						min-height: 62px;
						line-height: 16px;
					}
				}
				> .btn {
					padding: 0 12px;
					line-height: 28px;
				}
				
			}
			
			input[type=text],
			textarea {
				&[disabled] {
					background: @gray-light2;
					color: @gray-dark;
				}
				&::selection {
					background: @blue-theme;
					color: @text-color;
				}
				&.rtl {
					direction: rtl;
				}
			}
			.tag-line {
				margin: 8px 0;
				.tag {
					display: inline-block;
					background: @blue-theme-dark;
					color: white;
					cursor: default;
					line-height: 24px;
					margin: 0 3px 3px 0;
					padding: 2px 4px;
					text-decoration: none;
					&.editable {
						background: #a9a9a9;
						color: black;
						cursor: text;
						&:hover {
							background: #c3c3c3;
						}
					}
					&.add {
						background: @blue-theme;
						color: white;
						cursor: pointer;
						&:hover {
							background: lighten(@blue-theme, 10%);
						}
					}
					&.input {
						background: #c3c3c3;
						color: black;
						padding: 0;
						label {
							float: left;
							margin: 0;
							padding: 0 0 0 24px;
							position: relative;
							.flag-small {
								left: 4px;
								position: absolute;
								top: 8px;
							}
							input {
								border: 1px solid #c3c3c3;
								display: block;
								height: 28px;
								line-height: 26px;
								max-width: 160px;
								padding: 0 2px 0 4px;
							}
						}
						.btn-save {
							background: @blue-theme;
							border: 0;
							height: 28px;
							margin: 0;
							padding: 0;
							width: 32px;
							.save {
								left: 1px;
								position: relative;
								top: -1px;
								vertical-align: middle;
							}
						}
					}
				}
			}
			#video-tag-add-tag-line {
				.tag.input {
					background: @blue-theme-dark;
					input {
						border-color: @blue-theme-dark;
					}
				}
			}
			#video-tag-add {
				border-color: @blue-theme;
				background: @blue-theme;
				color: white;
				&:hover {
					background: lighten(@blue-theme, 10%);
				}
			}
		}
	}
	
	.video-vote-popup {
		.x-popup-content {
			padding: 8px 16px;
		}
	}
	.video-page-lang-switcher-popup {
		margin-right: 50px;
		z-index: @z-index-overlay + 1;
		.x-popup-arrow {
			left: 21px;
		}
		.video-page-lang-sel {
			background: @gray-darker;
			max-width: 800px;
			padding: 10px 0;
			li {
				padding: 0;
				a {
					color: @text-color;
					cursor: pointer;
					line-height: 20px;
					padding: 5px 16px;
					.flag-small,
					.no-flag {
						display: block;
						float: left;
						height: 11px;
						margin: 3px 10px 0 0;
						width: 16px;
					}
					&:hover {
						background: @blue-theme-darker;
					}
				}
				&.is-validated a,
				&.is-used a {
					background: @gray;
					color: @gray-light2;
					cursor: default;
					&:hover {
						background: @gray-light;
					}
				}
				.icon,
				.icon-f{
					display: inline-block;
					float: right;
					height: 20px;
					line-height: 20px;
				}
			}
		}
	}
	
	.rename-input-error {
		p {
			background: @theme-color;
			color: white;
			font-weight: bold;
			padding: 6px 16px;
			margin: 0;
		}
		.x-popup-arrow {
			border-top-color: @theme-color;
		}
	}
	
	.videoad-title.videoad-title-beforevideo,
	.videoad-centerlink {
		background-color: rgba(0, 75, 232, 0.75);
		border-color: rgba(0, 0, 103, 0.35 );
		&:hover {
			background-color: rgba(0, 75, 232, 1);
		}
	}
}

#html5video {
	#centered-full-video-link {
		background-position: center;
		background-size: cover;
		box-sizing: border-box;
		
		&:before{
			background: rgba(0,0,0,0.7);
			backdrop-filter: blur(20px);
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 1;
		}
		
		a {
			position: relative;
			z-index: 2;
		}
		
		a.is-xn {
			background: @blue-theme;
			border: 0;
			border-radius: 5px;
			color: #fff;
			padding: 8px 18px;
			
			&:hover {
				background: lighten(@blue-theme, 5%);
				box-shadow: 0 0 0 2px @gold-plate-bg-3;
			}
			
			.logo {
				display: block;
				padding-top: 4px;
				white-space: nowrap;
				
				img {
					vertical-align: middle;
				}
				
				img.sheer {
					display: block;
					margin: 5px auto;
					max-width: 100%;
					width: 110px;
				}
				
				.gold-plate {
					display: inline-block;
					line-height: 0.9em;
					vertical-align: middle;
				}
			}
		}
	}
}

#ad-header-mobile,
#ad-footer,
#ad-footer2,
#video-right,
#related-videos .thumb-block.video-ad {
	video,
	img {
		display: block;
	}
}

.auto-translate-pop-info {
	.x-popup-content {
		padding: 15px 20px;
		
		p {
			font-size: 14px;
			margin: 0;
		}
	}
}

#video-playlist {
	margin: 10px 0;
	
	#playlist-header {
		overflow: hidden;
		
		button {
			margin: 0;
			background: @blue-theme;
			border-color: @blue-theme-light;
			color: @text-color;
			box-shadow: none;
			font-weight: bold;
			font-size: 15px;
			line-height: 24px;
			border-radius: 0;
			
			&.pull-left {
				border-width: 0 1px 0 0;
			}
			
			&.pull-right {
				border-width: 0;
				
				&.toggle {
					border-width: 0 0 0 1px;
				}
			}
			
			&:hover {
				background: @blue-theme-light;
			}
			
			&.disabled {
				background: @blue-theme;
				color: @gray-light
			}
		}
		
		h4 {
			font-weight: bold;
			font-size: 15px;
			line-height: 33px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			padding: 0 8px;
			margin: 0;
			
			.icon.playlist {
				vertical-align: middle;
			}
			
			small {
				color: @gray-dark;
				
				a {
					text-decoration: underline;
				}
			}
			
			.badge {
				background: @blue-theme-darker;
				color: @text-color;
			}
			
			a {
				text-decoration: none;
				
				&:hover {
					text-decoration: underline;
				}
			}
		}
		
		.rating-box {
			float: right;
			margin: 3px 8px;
			
			.btn {
				box-shadow: none;
			}
		}
	}
	
	#playlist-content {
		padding: 0 2px 5px;
		
		.mozaique {
			white-space: nowrap;
			overflow: hidden;
			
			.thumb-block {
				width: 242px;
				display: inline-block;
				float: none;
				clear: none;
				background: none;
				border: 0;
				
				.thumb-overlay {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom:  0;
					font-size: 22px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					background: rgba(0, 0, 0, 0.6);
					
					p {
						text-align: center;
						color: @text-color;
						font-weight: bold;
						white-space: normal;
						line-height: 1.3;
						padding: 10px;
					}
				}
				
				&.selected {
					.thumb-overlay {
						border: 3px solid @text-color;
					}
					
					p a {
						font-weight: bold;
					}
				}
			}
			
			> p {
				font-size: 13px;
				margin: 8px 0 10px;
			}
		}
	}
}

#tabComments {
	#comments {
		margin-top: @content-padding;
	}
	.comment {
		background: @blue-theme;
		border: 1px solid @blue-theme-light-border;
		overflow: hidden;
		margin: 14px 0;
		padding: ceil(@content-padding / 2) @content-padding;
		.comment-title {
			font-weight: bold;
			padding: 0 0 ceil(@content-padding / 2);
			color: @yellow-theme;
			.date {
				float: right;
				font-weight: normal;
				color: @text-color;
			}
		}
		.comment-text {
			overflow: hidden;
		}
	}
	.pagination {
		margin: 0;
		padding-top: 15px;
	}
	.tab-form{
		display: none;
	}
}

#tabDownload {
	.download-ready {
		margin: 0;
		font-weight: bold;
		font-size: 15px;
	}
}

#related-videos {
	margin-bottom: 8px;
	overflow: hidden;
	.any-col-config({
		&,
		&.enlarged-player.with-ad,
		&.with-footer-ad.with-ad{
			&.expanded {
				.thumb-block {
					&.after-10,
					&.after-12,
					&.after-13,
					&.after-14,
					&.after-15 {
						display: inline-block;
					}
				}
			}
		}
	});
	
	&.expanded {
		.show-more {
			display: none;
		}
	}
	
	&.with-ad {
		&.enlarged-player {
			.thumb-block {
				&.video-ad {
					padding-bottom: 69px + 24px + @thumb-block-padding/2;
					
					.thumb-inside {
						overflow: visible;
					}
				}
			}
		}
		
		&.with-footer-ad {
			.thumb-block {
				&.video-ad-support-mobile,
				&.video-ad-support-tablet {
					clear: none;
					margin: 16px 0 20px;
					.thumb-inside {
						background: none;
						overflow: visible;
						a {
							display: inline-block;
							box-shadow: none;
						}
					}
				}
			}
		}
	}
	
	.row {
		margin: 0 0.5%;
		
		.col-sm-6 {
			padding: 0 2px;
		}
	}
	
	.show-more,
	.plist {
		display: block;
		margin: 10px 0 5px;
		padding: 5px 12px;
		box-shadow: none;
		font-weight: bold;
		border: 0;
		background: @blue-theme-darker;
		color: @text-color;
		line-height: 18px;
		text-decoration: none;
		
		&:hover {
			background: @blue-theme-light;
		}
	}
	
	.show-more {
		margin: 10px 0 5px;
		overflow: hidden;
	}
	
	.plist {
		margin: 10px 0 5px 2px;
		float: right;
	}
}

.premium-log-overlay {
	bottom: 0;
	cursor: pointer;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 2;
	
	.video-loader {
		background: rgba(0, 0, 0, .5);
		color: @text-color;
		font-size: 12px;
		font-weight: bold;
		height: 72px;
		left: 50%;
		line-height: 72px;
		margin: -36px;
		position: absolute;
		text-align: center;
		top: 50%;
		transition: 0.2s background;
		width: 72px;
		
		.buffer {
			bottom: 0;
			background: rgba(255,255,255,.3);
			height: 0%;
			left: 0;
			right: 0;
			position: absolute;
			z-index: 1;
		}
		
		.label {
			position: relative;
			z-index: 2;
		}
	}
	
	&:hover {
		.icon-f {
			background: rgba(0,0,0,.7);
		}
	}
}

#video-premium-alert {
	max-width: 610px;
	overflow: hidden;
	text-align: center;
	
	.premium-logo-span img {
		width: 90px;
	}
	
	.video-premium-alert-row {
		align-items: center;
		display: flex;
		overflow: hidden;
		padding: 10px 0 20px;
		
		p {
			flex: 1 1 0;
		}
		
		.video {
			flex: 1.1 1 0;
			float: left;
			margin-right: 15px;
			
			&.with-thumb-cover {
				img {
					filter: blur(1px) brightness(0.5);
				}
			}
		}
	}
	
	.video {
		margin: 0 auto;
		overflow: hidden;
		position: relative;
		width: 300px;
		
		img {
			width: 100%;
		}
		
		.premium-log-overlay {
			.icon-f {
				&,
				&:hover {
					background: rgba(0, 0, 0, .5);
					color: @text-color;
					cursor: default;
					font-size: 34px;
					height: 52px;
					left: 50%;
					line-height: 52px;
					margin: -26px;
					position: absolute;
					text-align: center;
					top: 50%;
					transition: 0.2s background;
					width: 52px;
				}
			}
		}
		
		span.premium-log-overlay {
			cursor: default;
		}
		
		.video-gold {
			background: transparent url('/v3/img/skins/xnxx/gold/thumb-cover-360.png') 0 no-repeat;
			background-size: auto 100%;
			background-size: cover;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}
	
	
	.icon-f {
		color: @gold-plate-bg-3;
		font-size: 1.8em;
		line-height: 0.6;
		vertical-align: text-bottom;
	}
	
	p {
		font-size: 22px;
		margin: 0;
		padding: 10px 0;
		
		&:first-child {
			padding: 0 0 20px;
			text-transform: uppercase;
			
			strong {
				display: block;
				font-size: 26px;
				
				.icon-f {
					font-size: 1.4em;
					line-height: 0.9;
				}
			}
		}
		
		.link {
			cursor: pointer;
			text-decoration: underline;
			
			&:hover {
				text-decoration: none;
			}
		}
		
		.btn-primary {
			text-transform: uppercase;
			font-size: 26px;
			font-weight: bold;
			white-space: normal;
		}
	}
}

.premium-log-overlay {
	display: flex;
    align-items: center;
    justify-content: center;

	.purchase-content {
		max-width: 90%;
		padding: 0;
		border: 1px solid #000090b8;
		border-radius: 3px;

		.btn {
			padding: 6px 12px;
			max-width: calc(100% - 24px);

			white-space: break-spaces;
			font-size: 145%;
			@media(max-width: 479px) {
				font-size: 105%;
			}

			.icon-f {
				background: none;
			}
		}
	}
}

#thumbs-select-form {
	p {
		font-style: italic;
		margin-bottom: 15px;
	}
	.mozaique {
		margin-left: -3px;
		margin-right: -3px;
		.thumb-block {
			border-bottom: none;
			padding: 0 3px 4px;
			.thumb a {
				border-color: #999;
				.corner-select {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					&:before {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						border-top: 34px solid #999;
						border-right: 34px solid transparent;
						width: 0;
					}
				}
				.select-over,
				.rejected {
					display: none;
					flex-direction: column;
					justify-content: center;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					background: rgba(0, 0, 0, 0.7);
					color: #fff;
					font-size: 24px;
					font-weight: bold;
					text-align: center;
				}
				input {
					display: none;
				}
				.icf-check-square {
					display: none;
					position: absolute;
					top: 2px;
					left: 2px;
					color: @theme-color;
				}
			}
			&.casting,
			&.selected {
				.thumb a {
					.select-over {
						display: flex;
					}
				}
			}
			&.selected {
				.thumb a {
					& {
						border: 4px solid #fff;
					}
					.corner-select:before {
						border-top-color: #fff;
					}
					.icf-check-square {
						display: inline-block;
					}
				}
			}
			&.disabled {
				.thumb a {
					& {
						border: 4px solid #999;
					}
					.rejected {
						display: flex;
						color: #aaa;
						font-style: italic;
						background: rgba(0, 0, 0, 0.8);
					}
				}
			}
		}
	}
	.thumb-select-actions {
		margin-top: 20px;
		border-top: 1px solid #fff;
		padding-top: 10px;
		text-align: right;
		.alert {
			text-align: left;
			margin-bottom: 10px;
			font-weight: bold;
		}
		.btn {
			margin: 0 0 2px 10px;
			font-weight: normal;
			font-size: 15px;
			border-radius: 0;
			&.validate {
				font-weight: bold;
			}
		}
	}
}