
#content #filter-tags-input {
	margin: 0 @content-padding;
	width: calc(~"100% - 2 * " @content-padding);
}

#tags,
.tags-list {
	margin: 10px 5px;
	padding: 0;
	li {
		display: inline-block;
		padding: 2px 5px;
		vertical-align: top;
		width: 25%;
	}
	a {
		display: inline-block;
		margin-right: 5px;
		padding: 2px 0;
		[dir="rtl"] & {
			margin-left: 5px;
			margin-right: 0;
		}
	}
	strong {
		color: @yellow-theme;
	}
}

#page {
	&.search-page,
	&.tag-page {
		.bg-title {
			&.black {
				margin: 0 0 2px;
			}
			&.grey {
				margin: 6px 0;
			}
		}
	}
	&.search-page .thumb-block.thumb-profile {
		background: darken(@gray-lighter, 20%);
		p a {
			font-weight: bold;
		}
	}
}

#content {
	#content-thumbs {
		h1 {
			color: @yellow-theme;
			font-size: 20px;
			font-weight: bold;
			line-height: 22px;
			margin: 15px 0 0 24px;
			
			.gold-plate, .free-plate {
				font-size: 13px;
				line-height: 0.9em;
				vertical-align: middle;
			}
			
			&.infobar-title {
				margin: 0;
				padding-right: 10px;
			}
		}
		
		#search-associates,
		#search-associates-bottom {
			margin-top: 0;
			padding: 4px 0;
			color: #fff;
			line-height: 1.2;
			strong {
				font-size: 16px;
			}
			strong,
			span {
				display: inline-block;
				padding: 1px 6px 0 0;
				vertical-align: middle;
			}
			.size-0 { font-size: 12px; }
			.size-1 { font-size: 13px; }
			.size-2 { font-size: 16px; }
			.size-3 { font-size: 18px; }
			.size-4 {
				font-size: 20px;
				font-weight: bold;
			}
			.show-more {
				display: none;
			}
		}
		
		.banner-game {
			position: relative;
			margin: 5px auto 10px auto;
			height: auto;
			max-width: 728px;
			&:before {
				content: '';
				display: block;
				padding-top: 8.241758242%;
			}
			a {
				position: absolute;
				top: 0;
				left: 0;
				display: block;
			}
			img {
				display: block;
				max-width: 100%;
				height: auto;
			}
		}
	}
	
	#results-top {
		margin: 15px @content-padding 0;
		
		h1 {
			display: inline-block;
			margin: 0 0 6px;
		}
	}
}

#results-free-or-premium {
	display: inline-block;
	font-size: 0;
	margin: 0 12px;
	vertical-align: top;
	white-space: nowrap;
	
	& > * {
		display: inline-block;
		vertical-align: middle;
	}
	
	a {
		cursor: pointer;
	}
	
	.free-plate,
	.gold-plate {
		font-size: 13px;
		line-height: 16px;
		padding-left: 5px;
		padding-right: 5px;
	}
	
	.switch {
		background: @free-plate-color;
		border: 2px solid @free-plate-bg-4;
		border-radius: 9px;
		display: inline-block;
		height: 18px;
		margin: 0 4px;
		width: 30px;
		
		&:hover {
			text-decoration: none;
			border-color: lighten(@free-plate-bg-4, 10%);
		}
		
		span {
			background: #fff;
			border-radius: 50%;
			cursor: pointer;
			display: block;
			float: left;
			height: 12px;
			margin: 1px;
			width: 12px;
		}
		
		&.is-premium {
			background: @gold-plate-color;
			border-color: darken(@gold-plate-border, 10%);
			
			&:hover {
				border-color: @gold-plate-border;
			}
			
			span {
				float: right;
			}
		}
	}
}

.report-search {
	float: right;
	vertical-align: top;
	
	.icon-f {
		padding-right: 4px;
	}
	
	&:focus,
	&:hover {
		text-decoration: none;
	}
	
	.notouch &:hover {
		.label {
			text-decoration: underline;
		}
	}
}

#listing-page-filters-block {
	box-sizing: content-box;
	display: inline-block;
	min-height: 36px;
	padding: 8px 0 0;
	position: relative;
	vertical-align: top;
	z-index: 2;
	//overflow: hidden;
	
	#listing-settings {
		float: right;
		height: 32px;
	}
	
	button.filters-opener {
		display: none;
	}
	
	#filters-list {
		display: inline-block;
		font-size: 13px;
		line-height: 20px;
		margin: 4px 0 0;
		padding: 0;
		.filter {
			display: inline-block;
			padding: 0 0 0 16px;
			&:after {
				content: '/';
				color: @gray-lighter;
				padding-left: 12px;
			}
			&:last-child:after {
				content: '';
			}
			span {
				color: white;
				font-weight: bold;
				margin: 0 6px 0 0;
				&:after {
					content: ' :';
				}
			}
			div {
				display: inline-block;
				color: @yellow-theme;
				cursor: pointer;
				padding: 0 6px;
				position: relative;
				white-space: nowrap;
				z-index: 1;
				&:after {
					color: darken(@yellow-theme, 10%);
					content: "▼";
					font-size: 10px;
					opacity: 1;
					position: relative;
					top: 0;
					transition: all 0.2s;
				}
				&:hover:after {
					color: @yellow-theme;
				}
			}
		}
		
		.close {
			display: none;
			
			+ .filter {
				padding-left: 0;
			}
		}
	}
	ul,
	li {
		list-style: none;
		margin: 0;
		padding: 0;
	}
}

#search-page-filters-block {
	padding: 8px;
	overflow: hidden;
	.btn {
		margin: 0 8px 0 0;
	}
	.filters-column {
		padding-right: 2px;
		display: inline-block;
		ul {
			margin: 0;
			padding: 0 0 0 20px;
			display: inline-block;
			font-size: 13px;
			list-style: none;
		}
		li {
			padding: 3px 0;
			display: none;
			vertical-align: text-top;
		}
		.title {
			padding-right: 8px;
			display: inline;
			color: #fff;
			font-weight: bold;
			font-size: 13px;
			&:after {
				content: ':';
			}
		}
		a {
			color: @yellow-theme;
			text-decoration: none;
		}
		.selected {
			display: inline;
			& a {
				font-weight: bold;
			}
			&:after {
				content: ' /';
				color: @gray-lighter;
			}
		}
		&:last-child .selected:after {
			content: '';
		}
	}
	&.expanded {
		.btn {
			display: block;
			margin-bottom: 4px;
		}
		.filters-column {
			float: left;
			width: 25%;
			border-left: 1px solid @blue-theme-lighter;
			ul {
				margin: 0 8px;
				padding: 0;
				font-size: 13px;
			}
			li {
				padding: 0;
				display: list-item;
			}
			.title {
				padding: 0 8px 8px 0;
				font-size: 16px;
				&:after {
					content: '';
				}
			}
			.selected:after {
				content: '';
			}
			&:nth-child(2) {
				border-left: 0;
				ul {
					margin-left: 0;
				}
			}
		}
	}
}


.mozaique {
	.premium-results-line-title {
		letter-spacing: normal;
		margin-bottom: 15px;
		text-align: center;
		
		a.see-more {
			color: #fff;
			font-size: 18px;
			margin: 0 10px;
			
			.query {
				color: @yellow-theme;
			}
			
			.gold-plate {
				font-size: 16px;
			}
			
			&:active,
			&:focus,
			&:hover {
				text-decoration: none;
			}
		}
	}
	.premium-results-line-see-more {
		font-size: 16px;
		letter-spacing: normal;
		margin: 2px 0 8px;
	}
	
	.premium-results-line {
		background: @blue-theme-dark;
		background-image: linear-gradient(to left, @blue-theme 0%, @blue-theme-dark 5%, @blue-theme-dark 95%, @blue-theme 100%);
		border-top: 1px solid @yellow-theme;
		border-bottom: 1px solid @yellow-theme;
		border-image: linear-gradient(to left, @blue-theme 0%, @yellow-theme 10%, @yellow-theme 90%, @blue-theme 100%) 1;
		margin: 0 -@thumb-block-padding 20px 0;
		overflow: hidden;
		padding: 15px @thumb-block-padding 0 0;
		width: calc(~"100% + @{thumb-block-padding}");
		
		.thumb-block {
			.thumb-under {
				margin-bottom: 0;
			}
		}
	}
}
