.video-hd-mark-mixin(@video-hd-mark-height) {
	font-size: round(@video-hd-mark-height * 2 / 3);
	line-height: @video-hd-mark-height;
	height: @video-hd-mark-height;
	padding: 0 round(@video-hd-mark-height / 4);
}

.video-hd-mark {
	display: inline-block;
	background: @blue-theme-light;
	color: white;
	font-weight: bold;
	text-align: center;
	border-radius: 1px;
	.video-hd-mark-mixin(22px);
	vertical-align: text-top;
	
	.thumb-inside & {
		.video-hd-mark-mixin(16px);
		vertical-align: baseline;
		position: absolute;
		top: 6px;
		right: 4px;
	}
}

@keyframes videopvprogress {
	from {
		width: 0;
	}
	to {
		width: 100%;
	}
}

.mozaique {
	overflow: hidden;
	padding-top: 15px;
	padding-right: @thumb-block-padding;
	text-align: center;
	letter-spacing: -0.31em;
	
	&.mozaique-left-bordered {
		border-left: 2px solid @blue-theme-light;
		margin-left: @content-padding + 4px;
		padding-left: 4px;
		text-align: left;
		
		&.mozaique-left-bordered-with-pagin {
			padding-top: 15px;
			
			.pagination {
				letter-spacing: 0;
				
				&:first-child {
					margin-bottom: 30px;
					margin-top: 0;
				}
				
				&:last-child {
					margin-bottom: 8px;
				}
			}
		}
	}
	
	.pagination,
	.thumb-block {
		letter-spacing: 0;
	}
	
	&.thumbs-5-cols {
		.thumb-block {
			.any-col-config({.thumb-block-size-mixin(4);});
		}
	}
}

.thumb-block-size-mixin(@nb_thumbs) {
	font-size: 0.8em + (1.05em/(@nb_thumbs+1));
	width: 100% / @nb_thumbs;
}

.any-col-config(@rule_set) {
	&,
	.nb-thumbs-cols-lg-3 &,
	.nb-thumbs-cols-lg-4 &,
	.nb-thumbs-cols-lg-5 &,
	.nb-thumbs-cols-md-2 &,
	.nb-thumbs-cols-md-3 &,
	.nb-thumbs-cols-md-4 &,
	.nb-thumbs-cols-sm-2 &,
	.nb-thumbs-cols-sm-3 &,
	.nb-thumbs-cols-sm-4 &,
	.nb-thumbs-cols-xs-1 &,
	.nb-thumbs-cols-xs-2 &,
	.nb-thumbs-cols-xs-3 & {
		@rule_set();
	}
}

.thumb-block {
	display: inline-block;
	margin: 0;
	padding-bottom: @thumb-block-padding/2;
	padding-left: @thumb-block-padding;
	position: relative;
	text-align: center;
	vertical-align: text-top;
	width: 290px;
	
	.thumb-inside {
		display: block;
		height: 0;
		overflow: hidden;
		padding: 0 0 56.25%;
		position: relative;
		
		.loading-content & {
			background: linear-gradient(130deg, @blue-theme-dark, @blue-theme-darker, @blue-theme-dark);
			background-size: 200% 200%;
			animation: loading-thumb-bg-animation 2s ease infinite;
			
			@keyframes loading-thumb-bg-animation {
				0% {
					background-position: 10% 0
				}
				50% {
					background-position: 91% 100%
				}
				100% {
					background-position: 10% 0
				}
			}
		}
		
		.cover-thumb {
			background: rgba(0, 0, 0, 0.1);
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			
			.video-gold {
				background: transparent url('/v3/img/skins/xnxx/gold/thumb-cover-360.png') 0 no-repeat;
				background-size: auto 100%;
				background-size: cover;
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}
		
		.action-menu {
			background: #000;
			background: rgba(0, 0, 0, 0.6);
			border: 0;
			border-radius: 3px;
			color: #eee;
			height: 30px;
			line-height: 30px;
			font-size: 16px;
			padding: 0;
			position: absolute;
			right: 5px;
			text-align: center;
			top: 5px;
			width: 30px;
			z-index: 2;
			
			.icon-f {
				line-height: 30px;
				vertical-align: middle;
				&, &.open {
					display: block;
				}
				&.close {
					display: none;
				}
			}
			
			&,	&.disabled {
				display: none;
			}
		}
	}
	
	&.show-tb-menu .thumb-inside .action-menu,
	.is-desktop & .thumb-inside .action-menu:hover,
	&.menu-opened .thumb-inside .action-menu {
		display: block;
		top: 5px;
	}
	
	&.menu-opened .thumb-inside .action-menu {
		.open {
			display: none;
		}
		.close {
			display: block;
		}
	}
	
	.thumb {
		bottom: 0;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		
		a {
			border: 1px solid @img-border-color;
			bottom: 0;
			display: block;
			height: 100%;
			left: 0;
			overflow: hidden;
			position: absolute;
			top: 0;
			width: 100%;
			zoom: 1;
			
			.is-desktop &:hover {
				border-color: #fff;
			}
		}
		
		img {
			width: 100%;
			
			&.profile-pic {
				background: #000;
				padding: 0 21.875%;
			}
			
			&.video-pic {
				background: #000;
				padding: 12.5% 0;
			}
		}
	}
	
	&.with-thumb-cover {
		.thumb {
			img {
				filter: blur(1px) brightness(0.5);
			}
		}
	}
	
	.uploader {
		height: 20px;
		margin-top: 4px;
		
		a {
			display: block;
			font-size: 1.2em;
			overflow: hidden;
			text-align: right;
			text-decoration: none;
			text-overflow: ellipsis;
			white-space: nowrap;
			
			.gold-plate,
			.free-plate {
				margin-left: 5px;
				margin-right: 1px;
			}
			
			.name {
				float: left;
				font-size: 1em;
			}

			.name-italic {
				font-style: italic;
			}
		}
		
		.name {
			color: @blue-theme-light-sub-title;
			display: block;
			font-size: 1.2em;
			font-weight: bold;
			line-height: 20px;
			max-width: 100%;
			overflow: hidden;
			position: relative;
			text-align: left;
			text-overflow: ellipsis;
		}
		
		button {
			&.sub {
				background: transparent;
				border: 0;
				color: #fff;
				height: 20px;
				line-height: 20px;
				float: left;
				margin: 0;
				overflow: hidden;
				padding: 0;
				text-align: right;
				transition: 0.2s all;
				width: 0;
				
				&.sub-checked {
					width: 26px;
					padding-right: 5px;
					
					* {
						opacity: 1;
						transition: 0.2s 0.2s opacity;
					}
					
					& + a {
						max-width: calc(~'100% - 28px');
						
						span:hover {
							text-decoration: underline;
						}
					}
				}
				
				* {
					opacity: 0;
					vertical-align: middle;
				}
			}
			
			&:active, &:focus, &active:focus {
				outline: none;
			}
		}
		
		& + .thumb-under {
			p {
				&:first-child {
					max-height: 42px;
					padding: 0 0 2px;
				}
			}
		}
	}
	
	.thumb-under p {
		margin: 0;
		overflow: hidden;
		text-align: left;
			
		&:first-child {
			font-size: 1.2em;
			line-height: 20px;
			max-height: 46px;
			padding: 4px 0 2px;
			white-space: normal;
			
			a {
				display: block;
				.line-clamp(2);
			}
		}
			
		&.metadata {
			color: @blue-theme-light-sub-title;
			text-overflow: ellipsis;
			font-size: 1.085em;
			white-space: nowrap;
			
			.suggest-options {
				float: left;
				font-size: 0.9em;
				margin: 0.15em 0.5em 2px 0;
				opacity: 0.8;
				text-shadow: 0 2px 2px rgba(0, 0, 0, 0.75);
				
				&.init-ok {
					cursor: pointer;
					opacity: 1;
					
					&:active {
						text-shadow: 0 0 1px #000;
						transform: translateY(1px);
					}
				}
			}
		}
			
		.right {
			float: right;
			padding-left: 3px;
			
			.icf-eye {
				margin-right: .3em;
				vertical-align: middle;
			}
		}
	}
	
	&.thumb-cat {
		//margin-bottom: 8px - (@thumb-block-padding / 2);
		
		.thumb-inside {
			overflow: hidden;
			
			.is-desktop &:hover {
				border-color: #fff;
			}
		}
		
		.title,
		.qtt {
			margin-bottom: 0;
			position: absolute;
			
			& + .title {
				max-width: 75%;
			}
			
			a {
				&:hover {
					color: @text-color;
					text-decoration: none;
				}
			}
		}
		
		.thumb {
			.is-desktop &:hover {
				& + .qtt + .title,
				& + .title {
					box-shadow: 1px -1px 2px #fff;
				}
			}
		}
		
		.title {
			background: @blue-theme;
			box-shadow: 1px -1px 2px @blue-theme-dark;
			bottom: 0;
			border-top-right-radius: 6px;
			font-size: 16px;
			font-weight: bold;
			height: 26px;
			line-height: 28px;
			left: -1px;
			overflow: hidden;
			padding: 0 8px 0 5px;
			text-align: left;
			text-overflow: ellipsis;
			white-space: nowrap;
			max-width: calc(~'100% + 8px');
			z-index: 3;
			
			.icon-f {
				margin-right: 3px;
			}
			
			.icf-search {
				font-size: 0.9em;
				margin-right: 1px;
			}
			
			.icf-suggest-square {
				font-size: 0.9em;
			}
		}
		
		.profile-sub {
			.user-subscribe,
			.user-reject {
				position: absolute;
				top: 3px;
				left: 3px;
				height: 21px;
				max-width: ~"calc(100% - 30px)";
				background: rgba(0, 0, 144, 0.4);
				cursor: pointer;
				.icon-f {
					display: inline-block;
					vertical-align: top;
					padding: 0 4px;
					font-size: 14px;
					line-height: 21px;
				}
				.sub-state-text {
					display: none;
					vertical-align: middle;
					max-width: ~"calc(100% - 22px)";
					padding-right: 4px;
					padding-top: 1px;
					font-size: 12px;
					line-height: 21px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				.loader {
					margin-right: 5px;
				}
				&.active {
					.sub-state-text {
						display: inline-block;
						font-weight: bold;
					}
				}
				&.no-active {
					display: none;
				}
				&:hover {
					color: @link-hover-color;
				}
			}
			.user-subscribe {
				left: auto;
				right: 3px;
			}
		}
		
		.qtt {
			background: @blue-theme-light;
			bottom: 3px;
			font-size: 13px;
			height: 20px;
			line-height: 20px;
			right: 2px;
			padding: 0 6px;
			z-index: 2;
		}
	}
	
	&.thumb-channel-premium {
		margin-bottom: 1em;
	}
	
	.video-debug {
		position: absolute;
		top: 0;
		left: 3px;
		background-color: #fff;
		border: 1px #000 solid;
		padding: 4px;
	}
	
	&.thumb-ad {
		p.metadata {
			.duration {
				background: @blue-theme-light;
				color: #fff;
				font-weight: bold;
				.video-hd-mark-mixin(18px);
			}
			
			a {
				color: #5c99fe;
				text-decoration: none;
				
				.is-desktop &:hover {
					text-decoration: underline;
				}
			}
		}
	}
	
	.actions {
		opacity: 0.75;
		position: absolute;
		top: 3px;
		left: 3px;
		transition: opacity 0.5s;
		
		.notouch & {
			opacity: 0;
		}
		
		.btn-action {
			background: fadeout(#000, 50%);
			clear: both;
			color: #fff;
			cursor: pointer;
			display: block;
			font-size: 16px;
			font-weight: bold;
			height: 24px;
			line-height: 24px;
			margin: 0 0 4px;
			width: 24px;
			
			.is-desktop &:hover {
				background: @blue-theme;
				color: @yellow-theme;
			}
			
			.icf-trash {
				float: left;
				line-height: 24px;
				width: 24px;
			}
		}
	}
	
	.is-desktop &:hover .actions {
		opacity: 1;
	}
	
	.gold-plate,
	.free-plate {
		display: inline-block;
		font-size: 11px;
		padding: 2px;
		vertical-align: middle;
	}
	
	.invert-if-premium {
		.premium-page & {
			display: inline-block;
			direction: rtl;
			
			& > * {
				direction: ltr;
			}
		}
		
		[dir="rtl"].premium-page & > * {
			direction: rtl;
		}
	}
	
	.thumbs-small & {
		.any-col-config({
			.thumb-block-size-mixin(8);
		});
		background: none;
		overflow: hidden;
		padding: 2px;
		
		.thumb-inside {
			padding-bottom: 100%;
		}
		.thumb-under {
			padding-bottom: 55px;
		}
		
		.thumb {
			a {
				border-color: transparent;
				height: 100%;
				img {
					height: 100%;
					object-fit: contain;
					width: 100%;
				}
			}
			img.video-pic {
				padding: 22% 0;
			}
		}
		.flag-small {
			top: auto;
			bottom: 4px;
		}
		.profile-counts {
			background: @blue-theme-dark;
			background: fadeout(@blue-theme-dark, 15%);
			bottom: -50%;
			color: #fff;
			left: 2px;
			position: absolute;
			right: 2px;
			padding: 3px;
			height: auto;
			transition-property: bottom;
			transition-duration: .25s;
			z-index: 2;
		}
		.is-desktop &:hover {
			.profile-counts {
				bottom: 0;
			}
		}
	}
	
	.profileslist & {
		padding-bottom: 10px;
		
		p {
			margin-bottom: 0;
			height: 18px;
			line-height: 18px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		
		.profile-name {
			a {
				color: @text-color;
				font-size: 13px;
				text-decoration: none;
			}
		}
		
		.profile-info {
			font-size: 12px;
		}
		
		.profile-days-ago {
			font-size: 12px;
			color: @gray;
		}
		
		.action-mobile-btn {
			margin: 4px;
			white-space: normal;
			background: @blue-theme-dark;
			border-color: @blue-theme-darker;
			color: @text-color;
			.is-desktop &:hover {
				background: @blue-theme-darker;
			}
		}
		
		.action-desktop-btn {
			background: @blue-theme-dark;
			border-color: @blue-theme-darker;
			border-radius: 0;
			color: @text-color;
			margin: 0;
			position: absolute;
			top: 2px;
			right: 2px;
			transition-property: top;
			transition-duration: .25s;
			.is-desktop &:hover {
				background: @blue-theme-darker;
			}
			
			&.read {
				top: -50%;
			}
			
			.icf-user {
				font-size: 20px;
			}
			
			.small-icf {
				font-size: 13px;
				left: 52%;
				position: absolute;
				top: 52%;
				.text-border-1px(@blue-theme-dark);
			}
		}
		.is-desktop &:hover {
			.action-desktop-btn {
				top: 2px;
			}
		}
		
		.friend-request-actions {
			overflow: hidden;
			
			label,
			button {
				.button-variant(@text-color, @btn-default-bg, red) ;
				border-width: 0;
				cursor: pointer;
				display: block;
				float: left;
				height: 30px;
				line-height: 34px;
				margin: 0;
				padding: 0;
				width: 33.33%;
				
				&:focus, &:active {
					outline: none;
				}
			}
			
			button.full {
				width: 66.66%;
			}
			
			button {
				&.accept:hover {
					color: @state-success-text;
				}
				
				&.refuse:hover {
					color: @state-danger-text;
				}
			}
			
			label {
				line-height: 30px;
				
				input {
					border-width: 2px;
					border-color: red;
					.inline-block;
					height: 12px;
					position: relative;
					vertical-align: middle;
					width: 12px;
					z-index: 1;
					
					&::before,
					&::after {
						.font-family-iconfont;
						.text-border-1px(red);
						background: red;
						border-radius: 4px;
						color: red;
						display: block;
						font-size: 14px;
						height: 14px;
						left: -1px;
						line-height: 14px;
						position: absolute;
						text-align: center;
						top: -1px;
						transition: 0.2s;
						width: 14px;
					}
					
					&::before {
						content: @icf-square;
						z-index: 2;
					}
					
					&::after {
						content: @icf-check-square;
						opacity: 0;
						transform: scale(3);
						z-index: 3;
					}
					
					&:checked {
						&::before {
							opacity: 0;
						}
						
						&::after {
							opacity: 1;
							transform: scale(1);
						}
					}
				}
			}
		}
	}
	
	&.thumb-block-big-button {
		.big-button-container {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
		}
		
		.thumb-inside {
			background: none;
			a {
				align-items: center;
				background: @blue-theme-dark;
				border: 1px solid @blue-theme-darker;
				color: @yellow-theme;
				display: flex;
				font-size: 18px;
				font-weight: bold;
				height: 100%;
				line-height: 30px;
				justify-content: center;
				text-align: center;
				text-decoration: none;
				
				.is-desktop &:hover {
					background: @blue-theme-darker;
					text-decoration: none;
				}
			}
		}
		
		&.thumb-block-big-button-arrow-right {
			.thumb-inside {
				a {
					border: 0;
					padding: 0 8px;
					margin-right: 20px;
					transition: none;
					
					&:after {
						background: @blue-theme-dark;
						bottom: 0;
						clip-path: polygon(0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
						content: "";
						display: block;
						position: absolute;
						right: 0;
						top: 0;
						transition: none;
						width: 21px;
					}
					&:hover {
						&:after {
							background: @blue-theme-darker;
						}
					}
				}
			}
			
			.count {
				font-size: 13px;
			}
		}
	}
}

.thumb-block .thumb,
#video-premium-alert .video {
	.videopv {
		&,
		& a {
			border: 0;
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
		
		video,
		canvas {
			border: 0;
			width: 100%;
			height: 100%;
		}
		
		.progress {
			position: absolute;
			height: 2px;
			width: 100%;
			left: 0;
			margin-bottom: 0;
			right: 0;
			bottom: 0;
			background: #000;
			
			.bar {
				background: @yellow-theme;
				height: 2px;
				width: 0;
				animation-delay: 100ms;
				animation-name: videopvprogress;
				animation-duration: 10s;
				animation-timing-function: ease-out;
			}
		}
	}
	
	img {
		&.hovered {
			display: block;
			box-sizing: content-box;
			position: absolute;
			width: 300%;
			height: 300%;
			top: 0;
			left: 0;
			margin-left: 0;
			margin-top: 0;
		}
	}
}

.x-popup {
	
	&.suggest-options {
		.x-popup-arrow {
			border-top-color: @blue-theme-darker;
		}
		
		.x-popup-content {
			background: @blue-theme-darker;
			padding: 10px 12px 8px;
			
			p {
				font-size: 15px;
			}
			
			ul {
				li {
					padding: 0 0 2px;
					
					.is-desktop &:hover {
						background: none;
					}
				}
			}
		}
	}
}

.simple-pagin-1 {
	padding: 0 @content-padding 0;
	margin: 0 0 8px;
	position: relative;
	line-height: 2;
	
	a {
		border: 1px solid @blue-theme;
		border-color: transparent;
		padding: 3px 7px;
		text-decoration: none;
		white-space: nowrap;
		
		&:hover,
		&.active {
			border-color: @blue-theme-light-border;
			color: #fff;
		}
		
		&.view-more {
			cursor: pointer;
			font-weight: bold;
		}
	}
}

#global-suggest-actions {
	padding: 10px 20px;
	
	.btn {
		margin: 0 7px 4px 0;
	}
}

.profiles-shortcuts {
	margin: 14px @content-padding 2px;
	padding: 0;
	
	li {
		display: inline-block;
		padding: 2px 4px;
		line-height: 1;
		vertical-align: text-bottom;
		
		a {
			color: @text-color;
			text-decoration: none;
			
			span {
				color: @blue-theme-light-sub-title;
				font-size: 0.9em;
			}
			
			&:hover {
				text-decoration: underline;
			}
		}
		
		.icon-f {
			&, &:before {
				display: inline-block;
				vertical-align: middle;
			}
		}
	}
}

.primary-premium-buttons {
	width: fit-content;
}

#user-videos-header {
	padding: 0 7px;
}

.simple-dropdown {
	display: inline-block;
	font-size: 14px;
	padding: 0;
	overflow: hidden;
	position: relative;
	vertical-align: top;
	z-index: 10;

	.switch-page-button {
		margin-bottom: 5px;
	}
	
	.home & {
		float: left;
		margin: 9px 0 0;
		
		& + .clear-infobar {
			text-align: right;
		}
	}
	
	.videos-by-profile & {
		float: left;
		
		& + .profiles-shortcuts {
			font-size: 14px;
			overflow: hidden;
			padding: 7px 0 10px @content-padding;
		}
	}
	
	.niv1 {
		float: left;
		padding: 0 3px;
		position: relative;
		
		& + .date-only {
			display: inline-block;
			padding-left: @content-padding;
		}
		
		&:first-child {
			padding: 0;
		}
		
		&.with-niv2 {	
			.show-niv2 {
				cursor: pointer;
				
				&.premium-switch-page {
					padding-right: 6px;
				}
			}
		}
		
		a.show-niv2 {
			&:hover {
				text-decoration: underline;
			}
		}
		
		.show-niv2,
		a {
			display: flex;
			float: left;
			line-height: 22px;
			margin: 0 2px 0;
			padding: 4px 2px 6px;
			text-decoration: none;
			white-space: nowrap;
			justify-content: space-between;
			align-items: center;
			flex-direction: row-reverse;
			
			.icf-x {
				color: @blue-theme-light-sub-title;
			}
		}
		
		.show-niv2 {
			display: flex;
			align-items: center;
			flex-direction: row;

			.icf-caret-down {
				opacity: 0.2;
				margin-right: -2px;
				padding-left: 2px;
			}
			
			&.init-ok {
				.icf-caret-down {
					opacity: 1;
					transition: 0.2s all;
				}
			}
			
			&.current {
				span:first-child {
					font-weight: bold;
					text-decoration: underline;
				}
			}
			
			&.premium-switch-page {
				border-radius: 3px;
				font-size: 13px;
				font-weight: bold;
				padding: 4px 10px;
				
				&.no-plate {
					background: @blue-theme-light;
					color: #fff;
				}
				
				.icon-f {
					&:first-child {
						float: left;
						line-height: 22px;
						margin-right: 4px;
					}
				}
			}
		}
		
		a {
			&.next,
			&.prev {
				border-radius: 3px;
				font-size: 18px;
				
				&:hover {
					background-color: fade(#fff, 10%);
				}
			}
		}
		
		.niv2 {
			display: none;
		}
	}
	
	.sep {
		float: left;
		line-height: 32px;
		padding: 0 4px;
	}
	
	.total-videos {
		float: right;
		line-height: 16px;
		padding: 7px 0 0;
	}
	
	&.default-dropdown {
		.niv1 {
			&.with-niv2 {
				.show-niv2,
				a {
					line-height: 1;
					padding: 7px 8px;
					margin: 0;
					border: 1px solid @blue-theme-light;
					
					&.popup-opened-btn {
						border-color: #ffffff;
					}
				}
			}
		}
		
		.pagination & {
			margin: 0 4px 0 0;
			.niv1 {
				&.with-niv2 {
					.show-niv2,
					a {
						padding: 5px 8px 6px 8px;
					}
				}
			}
		}
	}
}

#premium-listing-date-pagin {
	padding-right: @content-padding;
	width: 100%;
}

#content-thumbs {
	.infobar {
		.simple-dropdown {
			margin: -4px 0 0 0;
		}
	}
	.simple-dropdown {
		margin: 10px 0;
		/*& +	.simple-dropdown {
			padding-left: 0;
		}*/
	}
	.home & {
		.clear-infobar {
			.simple-dropdown {
				margin: 0;
				padding-left: 0;
				& + .pull-right,
				& + .pull-right + .pull-right {
					line-height: 30px;
				}
			}
		}
	}
}

.simple-dropdown-pop {
	box-shadow: 5px 5px 5px -5px rgba(0, 0, 0, 0.5);
	margin-left: 2%;
	padding-bottom: 0;
	
	.x-popup-content {
		padding: 8px 0;
		max-width: 100%;
	}
	
	.niv2 {
		.uls {
			overflow: auto;
			white-space: nowrap;
			max-width: 100%;
		}
		
		ul {
			margin: 0;
			padding: 3px 0 0;
			vertical-align: top;
			
			&.hidden-pagin {
				display: none;
				
				&.main-pagin {
					display: inline-block;
				}
			}
			
			li {
				padding: 0 4px 3px;
			}
			
			a {
				cursor: pointer;
				display: block;
				line-height: 15px;
				overflow: hidden;
				padding: 6px 14px;
				text-decoration: none;
				
				.icon-f {
					display: inline-block;
					line-height: 14px;
					margin-right: 6px;
					text-align: center;
					width: 18px;
					
					[dir="rtl"] & {
						margin-left: 6px;
						margin-right: 0;
					}
				}
				
				.icf-x {
					color: @blue-theme-light-sub-title;
				}
				
				&:hover,
				&.current {
					background: @tooltip-bg-lighten;
				}
				
				&.current {
					font-weight: bold;
				}
			}
		}
		
		&.see-all {
			ul {
				&.hidden-pagin {
					display: inline-block;
				}
			}
		}
		
		.see-all {
			display: block;
			margin: 8px auto 0;
			max-width: 300px;
			width: 90%;
		}
		
		.col {
			display: inline-block;
			ul,li,a {
				margin: 0;
				padding: 0;
			}
			.year {
				display: block;
				margin: 0 8px;
				.year-label,
				.month-label {
					position: relative;
					display: block;
					line-height: 15px;
					cursor: pointer;
					.icon-f {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						&.icf-angle-left {
							left: 0;
							margin-top: -6px;
							transform: none;
						}
					}
					
					&.is-current {
						font-weight: bold;
					}
				}
				.year-label,
				.month-label,
				.day-label {
					padding: 3px 14px;
					white-space: nowrap;
				}
				
				.year-label {
					background: @blue-theme-light;
				}
				&.is-close .year-label {
					background: none;
				}
				
				.year-months,
				.month-days {
					display: inline-block;
					padding: 0;
				}
				
			}
		}
	}
	
	&.premium-video-page-switch {
		a {
			white-space: nowrap;
		}
	}
}

#content {
	.videos-by-profile {
		padding-top: 10px;
		
		.simple-dropdown,
		.profiles-shortcuts {
			margin-bottom: 0;
			margin-top: 0;
		}
		
		.activity-event {
			display: inline-block;
			margin-top: 15px;
			margin-right: 15px;
			
			.clear-infobar {
				font-size: 22px;
				font-weight: normal;
				line-height: 22px;
				margin: 20px 0 0;
				
				.tag {
					color: @yellow-theme;
					font-weight: bold;
					padding: 0 2px;
				}
			}
		}
		
		&.video-suggest {
			.activity-event {
				.header {
					position: relative;
					
					* {
						position: relative;
						z-index: 2;
					}
				}
			}
		}
	}
}

.eventslist {
	
	.show-more-less-vids {
		.show-more-less-btns {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
			//display: flex;
			//flex-direction: column;
			//align-items: stretch;
			//justify-content: stretch;
		}
		
		a {
			background: @blue-theme-dark;
			border: 1px solid @blue-theme-darker;
			color: @yellow-theme;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: bold;
			font-size: 18px;
			text-align: center;
			
			&:hover,
			&:focus {
				background: @blue-theme-darker;
				text-decoration: none;
			}
		}
		
		.x-loader-inline {
			position: absolute;
			bottom: 10px;
			left: 0;
			width: 100%;
		}
		
		.count {
			font-weight: normal;
		}
		
		.no-more-to-load {
			display: none;
		}
	}
	
	.show-more-incremental {
		.show-more-less-btns {
			display: flex;
			flex-direction: column-reverse;
		}
		
		a {
			height: 50%;
			white-space: nowrap;
		}
		
		.show-more-less-short {
			display: none;
		}
		
		.no-more-to-load {
			&, & + .less {
				height: 100%;
			}
		}
	}
	
	.show-less {
		.more,
		.no-more-to-load {
			display: flex;
		}
		
		.less {
			display: none;
		}
		
		.thumb-block {
			&.extra-thumb-block {
				display: none;
			}
		}
		
		&.has-less-21 {
			.show-more-less-vids {
				display: none;
			}
		}
		
		
		.show-more-incremental {
			a {
				height: 100%;
			}
		}
	}
	
	/*&.has-less-21 {
		.more {
			display: none;
		}
	}*/
}

.tb-menu {
	z-index: @zindex-popup - 1;
	
	.x-popup-content {
		padding: 4px 6px;
		
		ul {
			margin: 0;
			padding: 0;
			
			li {
				margin: 0 0 4px;
				padding: 0;
				
				&:hover {
					background: none;
				}
				
				&:last-child {
					margin-bottom: 0;
				}
				
				a {
					border-radius: 3px;
					cursor: pointer;
					text-decoration: none;
					
					&:hover {
						background: @tooltip-bg-hover;
					}
				}
				
				a, p {
					display: block;
					font-size: 14px;
					padding: 4px 8px;
					text-transform: lowercase;
					
					.icon-f {
						display: inline-block;
						margin: 0 2px 0 0;
						text-align: center;
						vertical-align: middle;
						width: 24px;
					}
				}
				
				ul {
					margin-left: 8px;
				}
			}
		}
		
	}
	
	.vote-actions {
		margin-bottom: 8px;
		margin-top: 8px;
		overflow: hidden;
		
		&:last-child {
			margin-bottom: 4px;
		}
		
		a {
			background: @tooltip-bg-lighten;
			border: 0;
			float: left;
			margin: 0 5%;
			width: 40%;
			
			&.active {
				color: @yellow-theme;
			}
		}
	}
	
	.video-viewed {
		opacity: 0.6;
	}
}

// 767px max
@media (max-width: @screen-xs-max) {
	.simple-dropdown {
		.membership-button.niv1.with-niv2 {
			padding: 0px;
		}

		.primary-premium-buttons {
			width: max-content;
		}

		.library {
			float: inline-start;
			padding: 0px;
		}

		&.is-expand {
			.membership-dropdown.show-niv2 {
				width: stretch;
			}
		}
	}
}