#popup-opening-message {
	background: @blue-theme;
}

#popup-opening-content {
	.popup-opening-link {
		a {
			color: @blue-theme;
			background: @yellow-theme;
		}
	}
	
	.popup-opening-infos {
		color: @blue-theme-light-sub-title;
	}
	
	.icf-device-mobile {
		color: @blue-theme-lighter;
	}
}