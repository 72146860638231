
.mobile-show,
.mobile-portrait-show,
.mobile-landscape-show,
.mobile-show-inline,
.mobile-show-inline-block,
.mobile-show-list-item,

.device-xlg-show-inline ,

.mobile-only-show,
.mobile-only-show-inline,
.mobile-only-show-inline-block,
.mobile-only-show-list-item,

.xs-col-only-show,
.xs-col-only-show-inline,
.xs-col-only-show-inline-block,

.sm-col-only-show,
.sm-col-only-show-inline,
.sm-col-only-show-inline-block,

.md-col-only-show,
.md-col-only-show-inline,
.md-col-only-show-inline-block,

.lg-col-only-show,
.lg-col-only-show-inline,
.lg-col-only-show-inline-block,
//.xlg-col-only-show,
.xlg-col-only-show-inline/*,
.xlg-col-only-show-inline-block*/ {
	display: none;
}


/* ==== min 480px = max 767px = SM-COL ==== */
@media (min-width: @screen-xs-min) and (max-width: @screen-xs-max) {
	.sm-col-only-hide { display: none; }
	.sm-col-only-show { display: block; }
	.sm-col-only-show-inline { display: inline; }
	.sm-col-only-show-inline-block { display: inline-block; }
	.mozaique {
		.thumb-block {
			.thumb-block-size-mixin(3);
		}
		
		&.cust-nb-cols {
			.thumb-block {
				.nb-thumbs-cols-sm-2 & {
					.thumb-block-size-mixin(2);
				}
				
				.nb-thumbs-cols-sm-4 & {
					.thumb-block-size-mixin(4);
				}
				
				html:not(.nb-thumbs-cols-sm-2) & {
					.superfluous {
						display: none;
					}
					
					.thumb-under {
						p {
							&.metadata {
								font-size: 1em;
							}
						}
					}
				}
			}
		}
		&.thumbs-small {
			.thumb-block {
				.thumb-block-size-mixin(5);
			}
		}
	}
	#related-videos {
		.mozaique {
			.thumb-block {
				.thumb-block-size-mixin(3);
				
				.superfluous {
					display: inline;
				}
				
				.thumb-under {
					p {
						&.metadata {
							font-size: 1em;
						}
					}
				}
			}
		}
		.thumb-block {
			&.after-12,
			&.after-15 {
				display: none;
			}
		}
		&.with-ad {
			&.enlarged-player {
				.thumb-block {
					&.after-10 {
						display: none;
					}
				}
			}
			&.with-footer-ad {
				.thumb-block:not(.thumb-nat-exo-ad) {
					.thumb-under {
						min-height: 0;
						padding-bottom: 4px;
					}
				}
				.thumb-block {
					&.with-add-nth-child-3-plus-1{
						clear: left;
					}
					&.video-ad {
						width: 100% / 3 * 2;
					}
					&.after-12 {
						display: inline-block;
					}
					&.after-13 {
						display: none;
					}
				}
			}
		}
	}
	.profile-page {
		.pagination li.sort-filters-li {
			.sort-filters {
				display: inline-block;
				
				.x-dropdown {
					width: fit-content;
				}
			}
		}
	}
}

/* ==== min 768px = SM-COL ==== */
@media (min-width: @screen-sm-min) {
	.video-page {
		#full-video-link {
			display: inline-block;
			a {
				padding-left: 22px;
				padding-right: 6px;
				
				.icon-f {
					display: inline-block;
					font-size: 16px;
					left: 3px;
					position: absolute;
					top: calc(50% - 7px);
				}
			}
		}
	}
}

/* ==== min 768px = max 991px = MD-COL ==== */
@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
	.md-col-only-hide { display: none; }
	.md-col-only-show { display: block; }
	.md-col-only-show-inline { display: inline; }
	.md-col-only-show-inline-block { display: inline-block; }
	.mozaique {
		.thumb-block {
			.thumb-block-size-mixin(3);
		}
		&.cust-nb-cols {
			.thumb-block {
				.nb-thumbs-cols-md-2 & {
					.thumb-block-size-mixin(2);
				}
				
				.nb-thumbs-cols-md-4 & {
					.thumb-block-size-mixin(4);
				}
			}
		}
		&.thumbs-small {
			.thumb-block {
				.thumb-block-size-mixin(6);
			}
		}
	}
	
	#related-videos {
		.mozaique {
			.thumb-block {
				.thumb-block-size-mixin(4);
			}
		}
		
		.thumb-block {
			&.after-12,
			&.after-15 {
				display: none;
			}
		}
		
		&.with-ad {
			&.enlarged-player {
				.thumb-block {
					&.after-10 {
						display: none;
					}
				}
			}
			
			&.with-footer-ad {
				.thumb-block:not(.thumb-nat-exo-ad) {
					.thumb-under {
						min-height: 0;
						padding-bottom: 4px;
					}
				}
				.thumb-block {
					&.with-add-nth-child-4-plus-1 {
						clear: left;
					}
					
					&.after-12,
					&.after-13 {
						display: inline-block;
					}
					
					&.video-ad {
						width: 50%;
					}
					
					&.after-14 {
						display: none;
					}
				}
			}
		}
	}
}

/* ==== min 992px = MD screens === LG-COL ==== */
@media (min-width: @screen-md-min) {
	
	.lg-col-only-hide { display: none; }
	.lg-col-only-show { display: block; }
	.lg-col-only-show-inline { display: inline; }
	.lg-col-only-show-inline-block { display: inline-block; }
	.mozaique {
		.thumb-block {
			font-size: 1.05em;
			width: 330px;
			
			.uploader + .thumb-under p:first-child {
				max-height: 44px;
				padding: 0 0 4px;
			}
			.thumb-under {
				p:first-child {
					max-height: 46px;
					padding:  2px 0 4px;
				}
			}
		}
		&.cust-nb-cols {
			.thumb-block {
				.nb-thumbs-cols-lg-3 & {
					font-size: 1.13em;
					width: 422px;
				}
				.nb-thumbs-cols-lg-5 & {
					font-size: 1em;
					width: 270px;
				}
			}
		}
		&.thumbs-small {
			.thumb-block {
				.thumb-block-size-mixin(8);
			}
		}
	}
	
	#related-videos {
		.thumb-block {
			&.after-15 {
				display: none;
			}
			
			.thumb-block-size-mixin(5);
		}
		
		&.with-ad {
			&.enlarged-player {
				.thumb-block {
					&.after-12 {
						display: inline-block;
					}
					&.after-14,
					&.after-13 {
						display: none;
					}
					&.video-ad {
						padding-bottom: 73px + 24px + @thumb-block-padding/2;
					}
				}
			}
			&.with-footer-ad {
				.thumb-block:not(.thumb-nat-exo-ad) {
					.thumb-under {
						min-height: 0;
						padding-bottom: 4px;
					}
				}
				.thumb-block {
					&.with-add-nth-child-5-plus-1{
						clear: left;
					}
					&.after-10,
					&.after-12 {
						display: inline-block;
					}
					&.after-13,
					&.after-14 {
						display: none;
					}
					&.video-ad {
						width: 100% / 5 * 2;
					}
				}
			}
		}
	}
	
	#listing-page-filters-block {
		#filters-list {
			.filter {
				div {
					.ul {
						height: 0;
						left: -5px;
						opacity: 0;
						padding: 0;
						position: absolute;
						overflow: hidden;
						top: -4px;
						transition: top 0.4s 0.1s, opacity 0.4s 0.1s;
						width: 0;
					}
					ul {
						background: @blue-theme;
						border: 1px solid @yellow-theme;
						padding: 3px 0;
						position: relative;
						a {
							color: darken(@yellow-theme, 10%);
							display: block;
							padding: 4px 12px 4px 10px;
							text-decoration: none;
						}
						.selected a {
							font-weight: bold;
						}
						.selected a,
						a:hover {
							color: @yellow-theme;
						}
						&:before {
							border-style: solid;
							border-color: transparent transparent @yellow-theme transparent;
							border-width: 0 4px 4px 4px;
							content: " ";
							height: 0;
							left: 20px;
							position: absolute;
							top: -5px;
						}
					}
				}
			}
			
			.visible-filter-ul() {
				height: auto;
				opacity: 1;
				padding: 10px 0 0;
				top: 12px;
				width: auto;
			}
			& .filter.opened div {
				z-index: 2;
				.ul {
					.visible-filter-ul;
				}
				&:after {
					opacity: 0;
					top: 10px;
					//transition: all 1s;
				}
			}
			&.no-js .filter div {
				&:after {
					opacity: 0;
				}
				&:hover {
					z-index: 2;
					.ul {
						.visible-filter-ul;
					}
					&:after {
						visibility: hidden;
					}
				}
			}
		}
	}
	
	#content {
		//min-height: calc(~"100vh - 516px");
		.min-height-100vh(516px);
		.premium-page & {
			//min-height: calc(~"100vh - 260px");
			.min-height-100vh(260px);
		}
	}
	
	#video-content-metadata.disp-tabComments {
		#tabComments {
			float: left;
			width: ~"calc(40% - 7px)";
		}
		& #related-videos,
		& h3 {
			float: left;
			width: 60%;
		}
		& h3 {
			margin: 7px 0;
			padding: 0 14px;
		}
		& #related-videos {
			.mozaique {
				.thumb-block {
					.thumb-block-size-mixin(3);
				}
			}
		}
	}
	
	.old-ie #video-content-metadata.disp-tabComments,
	.legacy-ie #video-content-metadata.disp-tabComments,
	.ie #video-content-metadata.disp-tabComments,
	#content.player-enlarged #video-content-metadata.disp-tabComments,
	#video-content-metadata.disp-tabComments.nb-comments-0 {
		#tabComments,
		& h3,
		& #related-videos {
			float: none;
			width: 100%;
		}
		& #related-videos {
			.mozaique {
				.thumb-block {
					.thumb-block-size-mixin(5);
				}
			}
		}
	}
}

/* ==== min 1024px = Very large screens ==== */
@media (min-width: 1024px) {
	.mozaique {
		&.thumbs-5-cols {
			.thumb-block {
				.any-col-config({.thumb-block-size-mixin(5);});
			}
		}
	}
}

/* ==== min 1200px ==== */
@media(min-width: @screen-lg-min) {
	#tags li,
	.tags-list li {
		width: 20%;
		&:nth-child(5n+1) {
			clear: left;
		}
	}
}

/* ==== min 1280px = Video page with large screen ==== */
@media(min-width: @video-page-width) {
	.video-page .player-enlarged {
		#video-player-bg {
			#html5video.embed-responsive,
			#player {
				@media(min-height: 680px) {
					padding-bottom: 45%;
				}
				@media(min-height: 760px) {
					padding-bottom: 50%;
				}
				@media(min-height: 840px) {
					padding-bottom: 55%;
				}
			}
		}
	}
	
	#video-content-metadata.disp-tabComments {
		#tabComments,
		& #related-videos,
		& h3 {
			width: 50%
		}
	}
}

/* ==== min 1440px ==== */
@media (min-width: @screen-xlg-min) {
	.mozaique {
		&.thumbs-5-cols {
			.thumb-block {
				.any-col-config({.thumb-block-size-mixin(6);});
			}
		}
	}
	
	/*.xlg-col-only-show {
		display: block;
	}*/
	.device-xlg-show-inline {
		display: inline;
	}
	
	.xlg-col-only-show-inline {
		display: inline;
	}
	/*.xlg-col-only-show-inline-block {
		display: inline-block;
	}
	.xlg-col-only-hide {
		display: none;
	}*/
	
	#content-thumbs {
		padding: 0 20px;
	}
	
	#side-categories.side-cover + #content-thumbs {
		padding: 0 40px;
	}
	
	#page.premium_feed {
		.sub-channels {
			margin-left: -40px;
			margin-right: -40px;
			padding-left: 54px;
			padding-right: 54px;
		}
	}
}

/* ==== min 1900px ==== */
@media (min-width: 1900px) {
	.mozaique {
		&.thumbs-5-cols {
			.thumb-block {
				.any-col-config({.thumb-block-size-mixin(7);});
			}
		}
	}
}

/* ==== max 1350px ==== */
@media (max-width: 1350px) {
	#header {
		#site-nav {
			font-size: 13px;
		}
	}
}

/* ==== max 1270px ==== */
@media (max-width: 1270px) {
	#header {
		#site-nav {
			ul {
				li {
					margin-right: 8px;
					
					&.hamburger {
						& + .gold {
							margin-left: -8px;
						}
					}
				}
			}
		}
	}
}

/* ==== max 1230px ==== */
@media (max-width: 1230px) {
	#header {
		#site-nav {
			ul {
				li {
					margin-right: 6px;
					
					&.hamburger {
						& + .gold {
							margin-left: -6px;
						}
					}
				}
			}
		}
	}
}

/* ==== max 1100px ==== */
@media(max-width: 1100px) {
	.pagination + #listing-settings {
		.btn {
			.icon {
				display: inline-block;
			}
			.label {
				display: none;
			}
		}
	}
}

/* ==== max 991px = MAINSTREAM OF MOBILE WIDE SCREEN AND TABLETS ==== */
@media (max-width: @screen-all-mobile) {
	@import "responsive/resp_elements.less";
	@import "responsive/resp_page.less";
	@import "responsive/resp_nav.less";
	@import "responsive/resp_modules.less";
	@import "responsive/resp_search-tags";
	@import "responsive/resp_video.less";
	@import "responsive/resp_profiles.less";
	@import "responsive/resp_premium.less";
}

/* ==== max 767px ==== */
@media (max-width: @screen-xs-max) {
	#video-playlist #playlist-content .mozaique .thumb-block {
		.thumb-block-size-mixin(3);
	}
	.mozaique {
		&.thumbs-5-cols {
			.thumb-block {
				.any-col-config({.thumb-block-size-mixin(3);});
			}
		}
	}
	
	.video-page {
		#video-content-metadata {
			.video-metadata {
				[dir="rtl"] &, & {
					float: none;
					clear: both;
				}
			}
			.video-tags {
				clear: both;
			}
		}
		.video-user-suggest-overlay{
			.x-body {
				h1 {
					font-size: 18px;
					margin-bottom: 5px;
				}
				h2 {
					font-size: 18px;
					margin-bottom: 8px;
				}
				h3 {
					font-size: 14px;
					margin-bottom: 12px;
				}
			}
		}
		
		#full-video-link {
			margin: 0 @content-padding 10px;
			
			a {
				display: block;
				font-size: 14px;
				line-height: 18px;
				text-align: center;
				
				.icon-f {
					display: inline-block;
					font-size: 18px;
					line-height: 18px;
					vertical-align: top;
				}
			}
		}
	}
	.profile-page {
		.pagination li.sort-filters-li{
			display: block;
			padding: 0 5px;
			margin: 0 auto;
			text-align: center;
			
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}
		
		.loading-content {
			.pagination:first-child {
				height: @pagination-loading-height * 2  + 10px;//10px of '.pagination:first-child li:first-child' margin bottom
			}
			.pagination.empty-pagination:first-child {
				height: @pagination-loading-height + 10px;//10px of '.pagination:first-child li:first-child' margin bottom
			}
		}
	}
	
	#listing-page-filters-block {
		button.filters-opener {
			margin-bottom: 8px;
		}
	}
	#content {
		#content-thumbs {
			#results-top {
				flex-wrap: wrap;
				
				h1 {
					margin: 0 12px 0 0;
				}
			}
			.mobile-flex-space {
				flex-grow: 50;
				width: 100%;
			}
			
			.banner-game { margin : 0 8px; }
			.pagination { margin-top: 10px;}
			
			.tour-pushs {
				.inter {
					img {
						max-width: 80px;
					}
					h3 {
						font-size: 16px;
					}
				}
			}
		}
	}
	#listing-page-filters-block {
		width: 100%;
	}
	#results-free-or-premium {
		margin: 0;
	}
}

/* ==== max 640px ==== */
@media (max-width: 640px) {
	.mozaique {
		&.thumbs-5-cols {
			.thumb-block {
				.any-col-config({.thumb-block-size-mixin(2);});
			}
		}
	}
	body#red-page {
		#header {
			#header-logo {
				a {
					padding-left: 2px
				}
				
				img {
					padding: 12px 0;
				}
			}
		}
		.main-slider__item--img-1 {
			.main-slider__layer {
				h1 {
					span {
						font-size: 68px;
					}
					img {
						height: 65px;
					}
				}
			}
		}
	}
}

/* ==== max 560px ==== */
@media (max-width: 560px) {
	#video-right {
		&.ad-support-tablet {
			display: none;
		}
	}
}

/* ==== max 479px = XS-COL === MAINSTREAM OF MOBILE PHONES ==== */
@media (max-width: @screen-xxs-max) {
	.xs-col-only-hide { display: none; }
	.xs-col-only-show { display: block; }
	.xs-col-only-show-inline { display: inline; }
	.xs-col-only-show-inline-block { display: inline-block; }
	
	@import "responsive/mobile_only.less";
}

/* ==== max 414px ==== */
@media (max-width: (@ps-alpha-list-maxwidth + 2 * @content-padding)) {
	.profiles-page {
		#ps-alpha-list {
			margin: @content-padding;
		}
	}
}

/* ==== max 380px ==== */
@media (max-width: 380px) {
	#header .topbar .logo-xnxx img {
		height: 30px;
		margin-top: 4px;
	}
}

/* ==== max 359px ==== */
@media (max-width: 359px) {
	.home {
		.pagination {
			ul li a {
				padding: 0 5px;
			}
		}
	}
	#video-playlist #playlist-content .mozaique .thumb-block .thumb-overlay p {
		font-size: 18px;
	}
	body#red-page {
		#header {
			#header-logo {
				img {
					padding: 14px 5px;
				}
			}
		}
		.main-slider__item--img-1 {
			.main-slider__layer {
				h1 {
					span {
						font-size: 60px;
					}
					img {
						height: 65px;
						margin-bottom: 6px;
					}
				}
			}
		}
	}
	
	#page.premium_feed {
		.sub-channels {
			.thumb-block-premium-feed-account {
				.thumb-under {
					.account-actions {
						.btn {
							.label {
								.text {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
	
	#header-mobile-app-dl {
		display: none;
	}
	
	/*.profile-page {
		#profile-info {
			.user-actions {
				display: block;
			}
		}
	}*/
}


