.video-page {
	#header {
		padding-top: 0;
		
		.topbar {
			position: static;
		}
		#site-nav {
			margin-top: 0;
		}
	}
	
	#video-content-metadata {
		.clear-infobar {
			.metadata {
				float: none;
			}
		}
		
		.video-metadata {
			.metadata-btn {
				margin: 0 ceil(@content-padding / 2) @content-padding 0;
				text-align: center;
				
				.icon {
					margin: 0;
					vertical-align: baseline;
				}
				
				strong {
					display: inline-block;
					font-size: 14px;
					//height: 18.5px;
					vertical-align: baseline;
					
					.icf-resolution {
						font-size: 17px;
					}
				}
				
				.value {
					display: block;
					font-size: 13px;
					padding: 3px 0 0;
					vertical-align: baseline;
					white-space: nowrap;
				}
			}
			
			&,
			#video-votes,
			#video-votes .vote-actions,
			.tab-buttons {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
			}
			
			#video-votes,
			#video-votes .vote-actions,
			.tab-buttons,
			.metadata-btn {
				align-items: stretch;
				flex-grow: 3;
			}
			
			#video-votes > .metadata-btn {
				flex-grow: 1;
			}
			
			.uploader {
				display: block;
				margin-bottom: 3px;
				padding-right: 0;
			}
			
			#show-all-tags {
				text-decoration: none;
				display: block;
				float: right;
				background: @gray;
				color: #fff;
				font-size: 28px;
				line-height: 24px;
				font-weight: bold;
				margin-left: 2px;
				margin-top: -4px;
				padding: 0 4px;
				
				&:hover {
					background: @blue-theme;
				}
			}
			
			.video-tags {
				display: block;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			
			&.expanded {
				#show-all-tags {
					display: none;
				}
				
				.video-tags {
					white-space: normal;
				}
			}
		}
		
		.tabs {
			.tab {
				&#tabReport {
					button[type="submit"] {
						float: none;
						width: 100%;
					}
				}
			}
		}
	}
	#video-player-bg {
		#html5video.embed-responsive,
		#player {
			height: auto;
			padding-bottom: 81.5%;
			embed,
			.embed-responsive-item {
				position: absolute;
				height: 100%;
			}
		}
	}
	#video-sponsor-links {
		display: none;
	}
		
	.video-user-suggest-overlay{
		.x-body {
			h1 {
				font-size: 22px;
			}
			h2 {
				font-size: 22px;
			}
			h3 {
				font-size: 15px;
			}
		}
	}
}

&.old-ie .video-page #video-player-bg #player {
	height: 320px;
	padding-bottom: 0;
	embed,
	.embed-responsive-item {
		height: 320px;
	}
}

#video-premium-alert {
	max-width: 400px;
	
	.video-premium-alert-row {
		display: block;
		padding-bottom: 0;
	}
	
	.video {
		float: none;
		margin: 0 auto;
		padding: 10px;
		width: 100%;
	}
}