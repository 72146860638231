
body {
	overflow-y: scroll;
	background: @blue-theme-darker;
	margin: 0;
	min-width: @screen-legacy;
	.xv-responsive & {
		min-width: 0;
	}
	&.resize-stop-anim * {
		animation: none !important;
		transition: none !important;
	}
	#browser-too-old {
		margin-bottom: 0;
	}
}

@import "modules/header.less";
// @import "marketing/offers/valentine-23";
@import "marketing/offers/christmas-23";

.form-control {
	padding: 5px;
	background: @blue-theme-light;
	color: #fff;
	border: 1px solid #fff;
	font-size: 15px;
	height: auto;
	&:focus {
		border-color: #fff;
		box-shadow: 0 0 4px @yellow-theme;
	}
	&.visible-password {
		height: auto;
		padding: 0;
		position: relative;
		
		button {
			background: @blue-theme-light;
			border: 0;
			border-radius: 0;
			bottom: 0;
			color: lighten(@blue-theme-light, 40%);
			float: right;
			margin: 0;
			padding: 0;
			position: absolute;
			right: 0;
			top: 0;
			width: 40px;
			
			&:focus,
			&:active {
				outline: none;
			}
			
			.icon-f {
				font-size: 16px;
				vertical-align: middle;
			}
			
			.icf-eye-blocked {
				display: none;
			}
		}
		
		&.visible {
			button {
				.icf-eye-blocked {
					display: inline;
				}
				
				.icf-eye {
					display: none;
				}
			}
		}
		
		input {
			border: 0;
			height: 30px;
			margin: 0;
			padding-right: 40px;
			width: 100%;
		}
		
		.progress-pwd-check {
			height: 4px;
			margin: 0 -1px -4px;
		}
	}
}

.btn-clear {
	background: none;
	border: 0;
	color: @text-color;
	padding: 0;
	text-align: center;
	text-decoration: none;
	
	&:hover,
	&:active,
	&:focus,
	&:active:focus {
		color: @text-color;
		outline: 0;
	}
}

.x-overlay .x-body {
	background: @tooltip-bg;
}
#content {
	background: @blue-theme;
	overflow: hidden;
	
	h1, h2, h3, h4 {
		margin: 20px @content-padding;
	}
	
	.infobar,
	.clear-infobar {
		overflow: hidden;
		background: @blue-theme-light;
		font-size: 16px;
		padding: @content-padding;
		margin: @content-padding;
		border-bottom: 1px solid @blue-theme-light-border;
		border-top: 1px solid @blue-theme-lighter;
		
		.infobar-title,
		.infobar-list {
			font-size: 14px;
			line-height: 16px;
		}
		.infobar-title {
			float: left;
			padding: 0 20px 0 0;
			font-weight: bold;
			color: @yellow-theme;
		}
		
		.infobar-list {
			margin: 0;
			padding: 0;
			list-style: none;
			
			li {
				display: inline;
				padding: 0 14px 0 0;
			}
			
			&.no-spacing li {
				padding: 0 2px;
			}
		}
		
		.infobar-mobile-line {
			float: left;
		}
		
		.infobar-jumper {
			display: none;
		}
	}
	
	.clear-infobar {
		background: none;
		border-bottom: 0;
		border-top: 0;
		
		.infobar-title {
			font-size: 28px;
			line-height: 22px;
		}
		.infobar-list {
			padding-top: 4px;
		}
		
		/*.pull-right.mobile-hide {
			a {
				color: #fff;
				background-color: @blue-theme-light;
				border-color: @blue-theme-light;
				padding: 6px 12px;
				.transition;
				
				&:hover {
					text-decoration: none;
					background-color: @blue-theme-light-sub-title;
				}
			}
		}*/
	}
	
	.container-default {
		margin: 0 7px 7px;
	}
}

#history-link {
	margin:10px 0 5px; 
	font-weight: bold;

	&:after {
		content:''; 
		height: 10px;
		border: 0;
		-moz-box-shadow: 0 10px 10px -10px rgba(0,0,0,.6) inset;
		box-shadow: 0 10px 10px -10px rgba(0,0,0,.6) inset;
		display: block;
		margin-top: 15px;
	}
}

#global-suggest-actions {
	.icon-f {
		margin-right: 3px;
		&, &:before {
			vertical-align: middle;
		}
	}
}
@keyframes hideMenu {
	0% {
		height: initial;
		overflow: initial;
	}
	99% {
		height: initial;
		overflow: initial;
	}
	100% {
		height: 0;
		overflow: hidden;
	}
}
#side-categories,
.side-categories-block {
    float: left;
    font-size: 15px;
    padding: 10px;
    position: relative;
	width: 200px;
	ul li > strong {
		display: block;
		border-bottom: 1px solid @light-border;
		padding: 0 5px 5px 0;;
		margin-bottom: 5px;   
	} 
	&.side-cover {
		background: @blue-theme;
		box-shadow: 0 0 50px -10px #000;
		left: 0;
		position: absolute;
		transition: left 0.5s ease;
		z-index: 20;
		&.side-cover-hidden{
			height: 0;
			overflow: hidden;
			left: -210px;
			animation: hideMenu 0.55s ease;
		}
	}
	.side-cat-order-select {
		float: left;
		margin: 4px 0 8px;
		overflow: hidden;
		[dir="rtl"] & {
			float: right;
		}
		li{
			display: inline-block;
			text-align: left;
 			&:first-child {
 				padding-right: 16px;
			}
			a{
				display: inline-block;
				padding: 0 4px 4px;
				text-decoration: none;
				border-bottom:2px solid @blue-theme;
				&:hover {
					border-bottom:2px solid @blue-theme-pastel;
				}
				span{
					display: inline-block;
					width: 10px;
				}
				&.current_order{
					font-weight: bold;
					border-bottom:2px solid #fff;
					color:#fff;
				}
			}
		}
	}
	.side-cat-list {
		a{
			display: block;
			overflow: hidden;
			padding: 0 2px;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 100%;
			.transition;
			
			&:hover {
				color: #fff;
				background-color: @blue-theme-light;
				text-decoration: none;
			}
			
			span {
				float: left;
				margin-top: 1px;
				text-decoration: none;
				
				[dir="rtl"] & {
					float: right;
				}
			}
			
			strong {
				color: @yellow-theme;
			}
			
			.icon-f {
				font-size: 13px;
				margin: 2px 3px 0 1px;
				
				[dir="rtl"] & {
					margin: 2px 1px 0 3px;
				}
			}
			
			.icf-search {
				font-size: 11px;
				margin: 3px 2px 0 2px;
			}
			
			.icf-home {
				font-size: 15px;
				margin: 1px 3px 0 0;
				
				[dir="rtl"] & {
					margin: 1px 0 0 3px;
				}
			}
			
			.icf-suggest-square {
				font-size: 11px;
				margin: 3px 5px 0 2px;
				
				[dir="rtl"] & {
					margin: 3px 2px 0 5px;
				}
			}
			
			.icf-images {
				margin: 3px 4px 0 0;
				
				[dir="rtl"] & {
					margin: 3px 0 0 4px;
				}
			}
		}
	}
}


#side-categories{
	.list-unstyled {
		& > li {
			clear: both;
		}
	}
}

#content-thumbs {
	overflow: hidden;
	box-shadow: 0 0 50px -10px #000;
}

#warning-survey {
	font-size: 16px;
	line-height: 1.5;
	background: #98ceee;
	color: #333;
	padding: 5px 20px;
	margin: 15px 0 10px;
	font-weight: bold;
	text-align: left;
	a {
		color: #333;
		text-decoration: underline;
	}
	.question {
		font-weight: normal;
	}
}

#multi-footer-link {
	font-size: 24px;
	padding: 10px 0;
}

#footer {
	background: @blue-theme-darker;
	box-shadow: inset 0 50px 50px rgba(0, 0, 0, 0.7);
	overflow: hidden;
	padding: 15px @content-padding;
	text-align: center;
}

.language-switcher-popup,
.main-cat-switcher-popup {
	&.x-popup.below .x-popup-arrow {
		border-bottom-color: rgba(0, 0, 55, .95);
	}
	
	.x-popup-content {
		background: rgba(0, 0, 55, .95);
		box-shadow: 2px 3px 10px rgba(0, 0, 55, .5);
	}
}

.language-switcher-popup {
	li {
		a {
			.flag-small {
				margin-right: 2px;
			}
		}
	}
}

#reload-page-cont,
#subs-home-toggle-cont {
	clear: both;
	margin: 20px @content-padding 10px;
	
	.premium-page &.hide-if-premium{
		display: none;
	}
}

#reload-page,
#subs-home-toggle {
	.button-variant(#fff,@blue-theme-light,@blue-theme-dark);
	font-weight: normal;
	.init-ok-btn();
	margin: 0 2px 4px 0;
	white-space: normal;
}


.main-cat-switcher-popup {
	.x-popup-content {
		ul {
			li {
				&.current,
				&:hover {
					background: @blue-theme;
				}
				
				.btn {
					.icf-sexe-trans {
						font-size: 16px;
					}
				}
			}
		}
	}
}


.mac-models,
.mac-models-loading {
	margin: 20px;
}

.mac-models {
	h2 {
		color: @blue-theme-light-xcams;
		
		span {
			color: #fff;
		}
	}
	
	.models {
		.model {
			.live {
				background: @blue-theme;
			}
		}
	}
}

.logo-span {
	line-height: 1em;
	vertical-align: text-top;
	
	img {
		height: 1.15em;
		vertical-align: text-top;
	}
}

.premium-logo-span {
	line-height: 1em;
	vertical-align: text-top;
	
	img {
		height: 1em;
		vertical-align: text-top;
	}
	
	.gold-plate {
		vertical-align: text-top;
	}
}


.video-nb-icon {
	display: flex;
	gap: 3px;
	line-height: 1;
	
	.icon-f {
		line-height: 1.1;
	}
}