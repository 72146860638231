
@thread-font-size: 12px;
@thread-title-font-size: 15px;
@thread-color-gray: #fff;
@thread-color-gray-light: @gray-lighter;
@thread-color-gray-dark: @blue-theme;
@thread-color-active: @blue-theme-light-sub-title;
@thread-color-success: @yellow-theme;
@thread-node-children-count-color: #fff;
@thread-node-children-count-bg: @blue-theme-lighter;

.x-thread {
	.thread-node-btn {
		&,
		a {
			color: #fff;
			cursor: pointer;
		}
	}
	.thread-header {
		margin: 0 0 @content-padding;
		padding: @content-padding;
		background: @blue-theme;
		.thread-title {
			font-variant: small-caps;
		}
		.thread-node-children-count {
			line-height: @thread-title-font-size * 1.33333;
			font-size: @thread-title-font-size;
			vertical-align: bottom;
		}
		.thread-sort a {
			&:hover {
				text-decoration: underline;
				cursor: pointer;
				&.active {
					text-decoration: none;
					cursor: default;
				}
			}
		}
	}
	.thread-node {
		background: @blue-theme;
		border: 1px solid @blue-theme-light-border;
		margin: 14px 0;
		padding: ceil(@content-padding / 2) @content-padding;
		.thread-node-content {
			padding-left: 0;
			.thread-node-poster {
				.flag-small {
					vertical-align: middle;
				}
				.thread-node-poster-name {
					.verified,
					.poster-stats {
						display: none;
					}
				}
				.thread-node-date {
					float: right;
				}
			}
			.thread-node-message {
				font-size: 14px;
				margin: 10px 0;
				.verified {
					display: none;
				}
			}
			.thread-node-metadata {
				.thread-node-btn-post,
				.thread-node-btn-edit,
				.thread-node-btn-delete,
				.thread-node-btn-restore,
				.thread-node-btn-report {
					cursor: pointer;
				}
			}
			.thread-node-children {
				padding-top: 10px;
			}
		}
		.thread-node-children-nodes {
			padding-left: 20px;
			.thread-node {
				border-width: 0 0 0 1px;
			}
		}
	}
	form {
		.x-popup.below .x-popup-arrow {
			border-bottom-color: @blue-theme-light-border;
		}
		.x-popup-content {
			padding: 4px 10px;
			background: @blue-theme-light-border;
			box-shadow: 2px 3px 10px rgba(0,0,55,.5);
			color: #fff;
		}
	}
}

.thread-post-report-form-popup {
	.form-group {
		label {
			color: #fff;
		}
	}
	form {
		.x-popup.below .x-popup-arrow {
			border-bottom-color: @blue-theme-light-border;
		}
		.x-popup-content {
			padding: 4px 10px;
			background: @blue-theme-light-border;
			box-shadow: 2px 3px 10px rgba(0, 0, 55, .5);
			color: #fff;
		}
	}
}