body#red-page {
	#header {
		padding-top: 0;
		
		#header-logo {
			img {
				height: 50px;
				padding: 8px 0;
			}
		}
	}
	
	.main-slider__item--img-1 {
		.main-slider__layer {
			h1 {
				span {
					font-size: 88px;
				}
				img {
					height: 93px;
				}
			}
		}
	}
	
	.section-content--gradient-overlay-left-black {
		&:before {
			background-image: linear-gradient(to left, transparent 0%, #000010 90%);
		}
	}
	
	.section-content--gradient-overlay-right {
		&:before {
			background-image: linear-gradient(to right, transparent 0%, #000010 90%);
		}
	}
}

.premium-page {
	#header {
		#site-nav {
			ul {
				li {
					&.hide-if-not-premium {
						display: block;
					}
				}
			}
		}
	}
}

#page.premium_feed {
	.sub-suggests {
		.thumb-block-premium-feed-account {
			&.thumb-block-premium-feed-other-suggest {
				a {
					line-height: 20px;
				}
			}
		}
	}
}