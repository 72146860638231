.upload-simple-page {
	.wrapper {
		max-width: @video-page-width;
		margin: 0 auto;
		box-shadow: 0 0 100px -1px rgba(0, 0, 0, 0.9);
		overflow: hidden;

		.fatal-error {
			font-size: 26px;
			margin: 50px 0 40px;
			padding: 0 20px;
			text-align: center;

			.icon-f {
				float: none;
				font-size: 1.3em;
				vertical-align: sub;
			}
		}

		#processing-status {
			font-size: 18px;
			margin: 20px 0;
			text-align: center;
			.icon-f {
				margin: 0 20px 0 0;
			}
		}
		#async-processing-status {
			font-size: 18px;
			margin: 20px 0;
			.icon-f {
				margin: 0 10px 0 0;
			}

			.upload-completed {
				margin-top: 20px;
				&.alert-success,
				&.alert-success a {
					color: #2c7c29;
				}
			}

		}
		.form-horizontal {
			& > fieldset {
				padding-left: 30px;
				padding-right: 30px;
				legend {
					margin: 0 -15px 18px;
					width: calc(~"100% + 30px");
					.icon-f {
						vertical-align: middle;
					}
				}
			}
			.file-upload-inputs {
				font-size: 16px;
				margin-bottom: 64px;
				.file-upload-file,
				.file-upload-or,
				.file-upload-text {
					margin-bottom: 0;
					label.control-label {
						display: block;
						font-size: 16px;
						margin: 0 0 4px;
						text-align: center;
					}
					.form-group {
						margin: 0;
						.col-sm-12 {
							padding-left: 0;
							padding-right: 0;
						}
					}
					p {
						margin: 0;
					}
				}
				.file-upload-file {
					input,
					.content,
					.form-control-static {
						opacity: 0;
						height: 1px;
						margin: 0;
						min-height: 1px;
						width: 1px;
					}
					label.control-label {
						cursor: pointer;
						width: 100%;
						span {
							&:first-child {
								font-size: 16px;
								display: block;
								line-height: 24px;
								margin-bottom: 5px;
							}
							&.icf-upload {
								background: @red-color;
								border-radius: 3px;
								color: white;
								display: block;
								font-size: 40px;
								height: 60px;
								line-height: 60px;
								margin: 0;
								padding: 0;
								text-align: center;
								transition: all 0.2s;
							}
						}
						&:hover {
							span {
								&.icf-upload {
									background: darken(@red-color, 10%);
								}
							}
						}
						&:active {
							span {
								&.icf-upload {
									box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3) inset;
									line-height: 64px;
								}
							}
						}
					}
				}
				.file-upload-or {
					line-height: 60px;
					padding-top: 29px;
					text-align: center;
				}
				.file-upload-text {
					margin-bottom: 5px;
					label.control-label {
						line-height: 24px;
						margin-bottom: 5px;
					}
					input,
					select {
						border-right: 0;
						font-size: 18px;
						min-height: 60px;
						margin: 0;
						padding: 10px 12px;
					}
					.input-group-btn{
						vertical-align: top;
					}
					button {
						border-bottom-left-radius: 0;
						border-top-left-radius: 0;
						border-color: @input-border;
						min-height: 60px;
						&:active,
						&:focus {
							outline: none;
						}
					}
				}
				.file-upload-submit {
					display: none;
					.input-group {
						margin-bottom: 5px;
						.input-group-addon {
							height: 58px;
							padding: 0;
						}
						.type {
							border-right: 0;
							color: @red-color;
							padding: 6px 12px;
							* {
								display: none;
							}
							.is_file {
								font-size: 16px;
							}
							.is_url {
								font-size: 16px;
							}
						}
						.file-upload-recap-contener {
							border-left: 0;
							border-right: 0;
							cursor: default;
							height: 60px;
							overflow: hidden;
							padding: 0 10px;
							position: relative;
							.file-upload-recap,
							.return-file-select {
								font-size: 16px;
								height: 60px;
								transition: all 0.25s ease-in-out;
								width: 100%;
							}
							.file-upload-recap {
								display: table-cell;
								line-height: 20px;
								left: 0;
								overflow: auto;
								padding: 0;
								position: relative;
								vertical-align: middle;
								word-break: break-all;
							}
							.return-file-select {
								font-weight: bold;
								left: 10px;
								line-height: 60px;
								opacity: 0;
								overflow: hidden;
								position: absolute;
								text-align: center;
								top: 0;
								bottom: 0;
								white-space: nowrap;
							}
							&:hover {
								cursor: pointer;
								.file-upload-recap,
								.return-file-select {
									transition: all 0.25s 0.3s ease-in-out;
								}
								.file-upload-recap {
									left: -40px;
									opacity: 0;
								}
								.return-file-select {
									opacity: 1;
									left: 0;
								}
							}
						}
						.btn {
							background: @theme-color;
							border-left: 0;
							color: white;
							font-size: 16px;
							height: 58px;
							margin: 0;
							padding: 0 20px;
						}
					}
				}
				&.is_file .file-upload-submit .input-group .type .is_file,
				&.is_url .file-upload-submit .input-group .type .is_url {
					display: block;
				}
				&.is_url .file-upload-submit .help-block.is_file {
					display: none;
				}
			}
		}
	}
}