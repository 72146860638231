#page.videos_history {
	#page-videos-content {
		.big-message {
			color: @blue-theme-pastel;
			font-size: 22px;
			margin: 10px;
			text-align: center;
		}
	}
	
	#content-thumbs {
		padding-bottom: 20px;
	}
	
	.global-history-actions {
		margin: 10px;
		
		.btn {
			.button-variant(#fff, @blue-theme-light, @blue-theme-light);
			margin: 0 2px 4px 0;
		}
	}
}

.cat-history-legend {
	float: right;
	margin: 4px 5px 0 0;
	position: relative;
	
	[dir="rtl"] & {
		float: left;
	}
	
	span {
		background: #fff;
		border-radius: 50%;
		color: @blue-theme;
		cursor: pointer;
		display: inline-block;
		font-size: 12px;
		font-weight: bold;
		height: 16px;
		line-height: 16px;
		text-align: center;
		vertical-align: middle;
		width: 16px;
	}
	
	div {
		background: @blue-theme-light;
		box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
		position: absolute;
		left: 0;
		max-width: 90vw;
		padding: 8px 12px;
		top: 26px;
		width: 400px;
		z-index: 1;
		
		&:after {
			border-color: transparent transparent @blue-theme-light transparent;
			border-style: solid;
			border-width: 0 4px 4px 4px;
			bottom: 100%;
			content: " ";
			height: 0;
			left: 4px;
			position: absolute;
			width: 0;
		}
	}
	
	p {
		color: #fff;
		line-height: 24px;
		margin: 0;
		
		a {
			font-weight: bold;
		}
	}
}

#user-videos-header {
	margin: 0;
	position: relative;
	
	h2 {
		display: inline-block;
		vertical-align: middle;
		margin: 0;
	}
	
	.premium-switch-page-dropdown {
		display: flex;
		align-items: stretch;
		vertical-align: middle;
		& + h2 {
			display: none;
		}
	}
	
	.icons {
		position: relative;
		display: inline-block;
		vertical-align: middle;
		width: 20px;
		height: 22px;
		margin-left: -25px;
		cursor: pointer;
		.icf-history,
		.icf-cog {
			position: absolute;
			display: block;
		}
		.icf-history {
			top: 0;
			left: 0;
			font-size: 20px;
		}
		.icf-cog {
			top: 50%;
			left: 50%;
			width: 13px;
			height: 13px;
			font-size: 14px;
			.text-border-2px(@body-bg);
		}
	}
}
.premium-page {
	#user-videos-header {
		.icons {
			margin-left: 10px;
		}
	}
}

#user-videos-page-videos {
	clear: both;
	
	.loading-page {
		font-size: 14px;
		padding: 20px 20px 40px;
		text-align: center;
		
		strong {
			padding-right: 5px;
		}
		
		.icon-f {
			vertical-align: text-bottom;
		}
	}

	.error-message {
		p {
			font-size: 14px;
		}

		.btn-primary {
			margin-bottom: 16px;
    		margin-top: 6px;
		}
	}
}

#history-page-cat {
	margin-top: 20px;
	padding: 10px 0;
	
	.titre {
		float: left;
		margin: 0 7px;
		width: 50%;
		
		h3 {
			line-height: 26px;
			margin: 0;
		}
	}
	
	#history-page-cat-order-select {
		float: right;
		
		a {
			&.current_order {
				font-weight: bold;
			}
		}
	}
	
	#history-page-cat-list {
		clear: both;
		padding: 0 10px;
		
		a {
			background: @blue-theme-light;
			font-size: 18px;
			max-width: 100%;
			
			.icon-f {
				padding-right: 4px;
			}
		}
	}
	
	#history-page-cat-order-select,
	#history-page-cat-list {
		li {
			float: left;
			margin: 0 10px 8px 0;
			
			a {
				font-size: 14px;
				display: block;
				line-height: 26px;
				padding: 0 6px;
			}
		}
	}
}

#history-page-cat-history-legend {
	margin-top: 10px;
	font-size: 14px;
}

.contextual-popup {
	.disable-history-confirm {
		form {
			display: block;
			margin: 10px 0 4px;
			
			label {
				font-weight: normal;
			}
		}
	}
}

.x-popup.history-config-popup {
	.x-popup-content {
		padding: 8px;
		
		.btn {
			display: block;
			width: 100%;
			padding: 6px 20px;
			margin: 0;
			text-align: left;
			font-weight: normal;
			font-size: 16px;
			color: @text-color;
			background: none;
			border: none;
			border-radius: 0;
			transition: none;
			
			&:focus {
				outline: none;
			}
			
			&:hover {
				background: @blue-theme-dark;
			}
		}
	}
}