#header + #x-messages {
	.inner {
		border: 1px solid #fff;
		position: relative;
		margin: @content-padding;
	}
	.x-close {
		position: absolute;
		right: 6px;
		top: 6px;
		font-weight: bold;
		cursor: pointer;
		font-size: 28px;
		line-height: 18px;
		color: @yellow-theme;
		font-family: @font-family-monospace;
		&:hover {
			color: #fff;
		}
	}
	.x-message {
		overflow:hidden;
		padding: 6px 30px 6px 15px;
		font-size: 14px;
		background: @blue-theme-light;
		border-bottom: 1px solid @blue-theme-darker;
		color: #fff;
		&:last-child {
			border: 0;
		}
		a {
			color: #fff;
		}
        img {
			float: left;
			margin-right: 10px;
			max-height: 60px; 
			max-width: 60px;
		}
	}
}

// More legible messages
#x-home-messages {
	.x-message.x-message-info  {
		padding: 10px!important;
		border-color: #4d63c1!important;
	}
}