
.loading-content-pagination {
	height: @pagination-loading-height;
}
.pagination {
    margin: @pagination-margin-top @pagination-margin-side @pagination-margin-bottom;
    //overflow: hidden;
	clear: left;
	text-align: center;
	white-space: nowrap;
    ul {
		display: inline-block;
		margin: 0;
		width: 100%;
        padding: 0 0 0 1px;
        vertical-align: middle;
        li {
            display: inline-block;
            padding: 0;
			margin: 0 2px 0 0;
			vertical-align: middle;
            button,
            select,
            a {
				border: @pagination-border-width solid @pagination-border;
				background: @pagination-bg;
				color: #fff;
				cursor: pointer;
				display: inline-block;
				font-size: 140%;
				line-height: @pagination-line-height;
				margin: 0 3px 0 0;
				padding: @pagination-top-padding @pagination-side-padding;
				text-decoration: none;
			}
			a {
				&:hover {
					border-color: #fff;
					background-color: @pagination-active-bg;
				}
				&.no-page,
				&.active {
					border-color: #fff;
					background: @pagination-active-active;
				}
				&.next-page,
				&.prev-page,
				&.next,
				&.prev {
					border-color: @pagination-active-active;
					&:hover {
						border-color: #fff;
					}
				}
				.icon-f {
					vertical-align: middle;
					//text-decoration: none;
				}
			}
			&.no-page {
				a {
					display: inline-block;
					font-size: 14px;
					margin-bottom: 2px;
					padding: 5px 0;
					text-decoration: none;
					border: 0;
					background: none;
					color: @pagination-color;
					cursor: default;
				}
			}
			&:last-child {
				&, a {
					margin-right: 0;
				}
			}
        }
    }
	&.centered {
		text-align: center;
		ul {
			display: inline-block;
		}
	}
}
